@import url(https://use.fontawesome.com/releases/v5.15.3/css/all.css);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://use.fontawesome.com/releases/v5.15.3/css/all.css);
@import url(https://fonts.cdnfonts.com/css/apex-new);
@import url(https://use.fontawesome.com/releases/v5.15.3/css/all.css);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://use.fontawesome.com/releases/v5.15.3/css/all.css);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://use.fontawesome.com/releases/v5.15.3/css/all.css);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
.bg-dark-333{
    background-color:#333333;
    height:100vh;
    /* margin-top:-48px; */
}


/* fiat css  */

@font-face {
    font-family: "icomoon-form";
    src: url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.ttf?u9p37z");
    src: url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.eot?u9p37z#iefix") format("embedded-opentype"),
    url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.ttf?u9p37z") format("truetype"),
    url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.woff?u9p37z") format("woff"),
    url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.svg?u9p37z#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
}


*,
*::before,
*::after {
    box-sizing: border-box;
}

.Fiat_accordion__1N8qj {
    font-size: 17px;
    margin: 0;

}

.Fiat_accordion__button__1Fz2t {
    align-items: center;
    background: black;
    border: 0;
    color: white;
    cursor: pointer;
    display: flex;
    font-size: inherit;
    justify-content: space-between;
    margin: 0;
    padding: 25px 30px;
    width: 100%;
}

.Fiat_accordion__button__1Fz2t span {
    height: 30px;
    /*width: 60px;*/
    /* border-radius: 50%; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #000;
    font-size: 15px;
    white-space: nowrap;
}

.Fiat_accordion__button__1Fz2t:focus {
    outline: none;
}

.Fiat_accordion__content__3s4D6 {
    background: #fff;
    padding: 0 20px;
    opacity: 0;
    transition: all 0.5s;
    height: 0;
    margin: -10px 0 0;
    /*border: 1px solid #f1f1f1;*/
    border-top: 0;
    overflow: hidden;
}

.Fiat_accordion__content__3s4D6.Fiat_active__1eVfw {
    height: auto;
    opacity: 1;
    padding: 20px;
    display: block;
    background: #fff;
}

.Fiat_container__3Y8BS {
    min-height: 100vh;
    padding: 0 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.Fiat_main__euCat {
    padding: 5rem 0;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Fiat_footer__1lWx6 {
    width: 100%;
    height: 100px;
    border-top: 1px solid #eaeaea;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Fiat_footer__1lWx6 a {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.Fiat_title__3RB2z a {
    color: #0070f3;
    text-decoration: none;
}

.Fiat_title__3RB2z a:hover,
.Fiat_title__3RB2z a:focus,
.Fiat_title__3RB2z a:active {
    text-decoration: underline;
}

.Fiat_title__3RB2z {
    margin: 0;
    line-height: 1.15;
    font-size: 4rem;
}

.Fiat_title__3RB2z,
.Fiat_description__3LpYQ {
    text-align: center;
}

.Fiat_description__3LpYQ {
    line-height: 1.5;
    font-size: 1.5rem;
}

.Fiat_code__3tMKe {
    background: #fafafa;
    border-radius: 5px;
    padding: 0.75rem;
    font-size: 1.1rem;
    font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
}

.Fiat_grid__3nMDj {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 800px;
    margin-top: 3rem;
}

.Fiat_card__svsWv {
    margin: 1rem;
    padding: 1.5rem;
    text-align: left;
    color: inherit;
    text-decoration: none;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    transition: color 0.15s ease, border-color 0.15s ease;
    width: 45%;
}

.Fiat_card__svsWv:hover,
.Fiat_card__svsWv:focus,
.Fiat_card__svsWv:active {
    color: #0070f3;
    border-color: #0070f3;
}

.Fiat_card__svsWv h2 {
    margin: 0 0 1rem 0;
    font-size: 1.5rem;
}

.Fiat_card__svsWv p {
    margin: 0;
    font-size: 1.25rem;
    line-height: 1.5;
}

.Fiat_logo__I5qLW {
    height: 1em;
    margin-left: 0.5rem;
}

@media (max-width: 600px) {
    .Fiat_grid__3nMDj {
        width: 100%;
        flex-direction: column;
    }
}

.Fiat_new-form-vehicle__3erls {
    text-align: center;
    background: #F9F9F9 !important;
    padding: 6px;
    border-radius: 10px;
    margin: 10px;
    border: 3px solid #F9F9F9;
    cursor: pointer;
}

.Fiat_new-form-vehicle__3erls:hover {
    background: white;
    border: 4px solid #AD0C33;
}

.Fiat_new-form-vehicle-title__1hSkX {
    margin: 0 0 10px 0;
    font-weight: 700;
    cursor: pointer;
    font-family: "Open Sans", sans-serif !important;
    color: #ad0c33;
    font-size: 14px;
}

.Fiat_new-form-step-content-list-item__RcTsZ, .Fiat_wrap-car-model__2T7r4 {
    flex-basis: 25%;
    align-self: stretch;
    padding-bottom: 0px;
    margin-bottom: 0px;
    position: relative;
}

.Fiat_title-form__1RvKJ {
    font-family: "Open Sans", sans-serif !important;
    font-size: 16px;
}

.Fiat_new-form-img-title__3Qwc7 {
    font-family: "Open Sans", sans-serif !important;
    font-size: 25px;
    font-weight: bold;
    margin-top: 7px;
    margin-bottom: 20px;
    color: #484848 !important;
}


.Fiat_dropdownSelect__3RfPT {
    border: none !important;
    border-bottom: solid 1px #828282 !important;
    background: none;
    display: block;
    width: 100%;
    -webkit-appearance: none;
    outline: 0;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
    font-size: 16px;
    font-family: inherit;
    padding: 15px;
}

.Fiat_p-abs__lP2De {
    padding-bottom: 5px;
}

.Fiat_form-check-input__17jkp:checked {
    border-color: #AD0C33 !important;
    background-color: #AD0C33 !important;
}

.Fiat_new-form-submit__4YhMw.Fiat_submit__2UpHg {
    margin-top: 60px;
    text-align: right;
}

div.Fiat_col-xs-12__3xEC0 > div > button {
    background-color: #AD0C33;
    color: #fff;
    width: 180px;
    text-align: left;
    line-height: 1.6;
    font-family: "Open Sans", sans-serif !important;
    font-size: 10px;
    letter-spacing: 0px;
    font-weight: bold;
    border-radius: 5px;
}

div.Fiat_col-xs-12__3xEC0 > div > button svg {
    font-size: 16px;
    margin-left: 50px;
}

.Fiat_new-form-link__1bttr.Fiat_new-form-link-change__2DcTZ.Fiat_model__3vrbs > svg {
    color: #AD0C33;
}

.Fiat_new-form-link__1bttr {
    margin-top: 15px;
    color: #787878;
    display: inline-block;
    font-family: "Open Sans", sans-serif !important;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
}

.Fiat_new-form-link__1bttr:hover {
    text-decoration: none !important;
    color: #000000;
}

.Fiat_form-check-input__17jkp:checked {
    border-color: #AD0C33 !important;
    background-color: #AD0C33 !important;
}

.Fiat_new-select-form__i487G:after {
    right: 22px !important;
    top: 32px !important;
    top: 32px !important;
}

.Fiat_new-form-step-content-list-flex__2GNIp {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    justify-content: left;
}

.Fiat_img-fluid__9V8Ts, .Fiat_new-form-vehicle-image__2jfna {
    max-width: 100%;
    height: auto;
    margin-bottom: 30px;
    max-height: inherit;
    cursor: pointer;
    width: 100%;
    /*margin-top: 40px;*/
    margin-top: 0;
}

.Fiat_section-header__2OJlC {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
    margin: 30px 0 0 0;
}

.Fiat_section-header__2OJlC h1 {
    font-family: "Open Sans", sans-serif !important;
    font-size: 36px;
    color: #333333;
    font-weight: 400;
}

.Fiat_new-select-form__i487G {
    position: relative;
}

.Fiat_new-form-container__3icAe * input, .Fiat_new-select-form__i487G select {
    padding-left: 5px !important;
    padding-top: 20px !important;
}

.Fiat_input-container__3EXU8 {
    border: 1px solid #cccccc !important;
    margin-bottom: 20px;
    position: relative;
}


.Fiat_input-container__3EXU8 select, .Fiat_input-container__3EXU8 input {
    border: none !important;
}

label[for='gender'], label[for='city'], label[for='firstName'], label[for='lastName'], label[for='email'], label[for='telephone'] {
    position: absolute;

}

label[for='gender'], label[for='city'] {
    top: 15px;
}

label[for='firstName'], label[for='lastName'], label[for='email'], label[for='telephone'] {
    top: -5px !important;
}

.Fiat_is-not-empty__14dpk {
    padding: 0 !important;
    transform: translate(0, 0) !important;
    top: 4px !important;
    left: 5px;
    font-size: 11px !important;
}

.Fiat_new-select-form__i487G:after {
    /*content: "\f078";*/
    /*font-family: "FontAwesome" !important;*/
    content: "\e902";
    font-family: 'icomoon-form' !important;
    color: #828282;
    position: absolute;
    right: 15px !important;
    top: 22px !important;
    z-index: 1;
    width: 14px;
    height: 20px;
    pointer-events: none;
    font-size: 10px !important;
}

/*.new-form-container .new-select-form > .dropdownSelect {*/
/*    background: none;*/
/*    height: 38px;*/
/*    width: 100%;*/
/*    -webkit-appearance: none;*/
/*    outline: 0;*/
/*    border-radius: 0;*/
/*    box-shadow: none;*/
/*    cursor: pointer;*/
/*    font-size: 16px;*/
/*    font-family: inherit;*/
/*    line-height: 42px;*/
/*    position: absolute;*/
/*    top: 10px;*/
/*    padding: 0px 30px 0px 10px;*/
/*}*/

.Fiat_showroom-address__tv22g, .Fiat_showroom-phone__1TX5L {
    font-family: "Apex New Light", sans-serif !important;
    font-size: 13px;
}

.Fiat_showroom-card__2Kh92 {
    color: #333 !important;
    font-family: "Open Sans", sans-serif !important;
}

.Fiat_showroom-card__2Kh92 .Fiat_showroom-city__3cw5D {
    margin: 0 !important;
}

.Fiat_showroom-city__3cw5D {
    padding-bottom: 0 !important;
}

.Fiat_showroom-title__CuQFF {
    padding-bottom: 0 !important;
}

.Fiat_showroom-card__2Kh92 .Fiat_showroom-title__CuQFF {
    margin-top: 25px;
}

.Fiat_showroom-card__2Kh92 a {
    color: #000 !important;
    max-width: 150px;
    white-space: nowrap;
    width: 100%;
    margin: 0;
}

.Fiat_showroom-card__2Kh92 .Fiat_showroom-address__tv22g {
    padding-bottom: 10px !important;
}

.Fiat_showroom-card__2Kh92 a {
    color: #fff !important;
}

/*showroom*/
.Fiat_showroom-title__CuQFF {
    font-weight: 700 !important;
    min-height: 30px;
    font-size: 16px;
    font-family: "Open Sans", sans-serif !important;
    color: #484848 !important;
}

.Fiat_section-header__2OJlC {
    font-family: "Open Sans", sans-serif !important;
    line-height: 1.2;
}

.Fiat_showroom-address__tv22g, .Fiat_showroom-phone__1TX5L {
    font-size: 13px;
    text-transform: capitalize !important;

    line-height: 1.5;
}

.Fiat_formAnagrafic__1KzHC, .Fiat_formAnagrafic__1KzHC label, .Fiat_formAnagrafic__1KzHC input, .Fiat_formAnagrafic__1KzHC select {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-size: 16px;
}

.Fiat_formAnagrafic__1KzHC * input {
    padding-left: 5px !important;
}

.Fiat_formAnagrafic__1KzHC * div {
    font-family: "Open Sans", sans-serif !important;
}

.Fiat_new-form-text-content__24Fgq a {
    text-decoration: underline;
}

.Fiat_new-form-text-title__1HXjI {
    margin-bottom: 20px;
}


.Fiat_rect-btn-red__2ejOS > svg {
    position: absolute;
    right: 10px;
}

.Fiat_showroom-card__2Kh92 {
    font-family: "Open Sans", sans-serif !important;
}


.Fiat_showroom-address__tv22g, .Fiat_showroom-phone__1TX5L {
    font-weight: 600 !important;
    font-family: "Open Sans", serif;
}

.Fiat_form-title__2TtfM {
    display: block;
}

.Fiat_h1-form-type__3UKcK {
    font-weight: 700 !important;
    color: #ad0c32;
    font-size: 37px;
    font-family: "Open Sans", sans-serif !important;
    display: block;
    margin: 0 auto;
    text-align: left;
}

.Fiat_showroom-city__3cw5D {
    position: relative;
    padding: 0;
    margin: 10px 15px;
    font-family: "Open Sans";
}

.Fiat_showroom-city__3cw5D:after {
    content: "";
    width: 34px;
    height: 2px;
    background-color: #AD0C33;
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: -5px;
}

/*thank you*/

.Fiat_thx-title__2IBHc {
    color: #777777;
    font-size: 30px !important;
    text-transform: inherit;
    margin-bottom: 40px;
}

.Fiat_answerTitle__2xDcZ > h2 {
    text-align: center !important;
}

.Fiat_copy-box__1oZ5a h2 {
    text-align: center;
}

.Fiat_new-icon-separator-container__1uZe9 {
    height: auto;
    overflow: hidden;
    position: relative;
    padding-top: 10px;
}

.Fiat_new-icon-separator-container__1uZe9 .Fiat_separator__HvYgA {
    width: 60% !important;
    height: 2px;
    background: #AD0C33;
    /* margin: auto; */
    top: 35px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
}

.Fiat_thanks-title__1pLCB {
    color: #777777;
    font-size: 30px !important;
    text-transform: inherit;
    font-family: "Open Sans", sans-serif !important;
    font-weight: 700 !important;
    margin-top: 10px;
    text-align: center;
    margin-bottom: 0;
}

.Fiat_new-icon-jeep_icon_quote__1B3P0 {
    font-size: 47px;
    width: 93px;
    margin: auto;
    background: #fff;
    padding: 0px 20px;
    margin-top: 50px;
}

.Fiat_copy-box-ico__ogOGm.Fiat_ico1__ZXAP3.Fiat_new-icon-jeep_icon_quote__1B3P0:before {
    content: "\e923";
    color: #ad0c33;
    font-family: 'icomoon-form', sans-serif !important;
}

.Fiat_copy-box-ico__ogOGm.Fiat_ico1__ZXAP3, .Fiat_copy-box-ico__ogOGm.Fiat_ico2__1pxkt, .Fiat_copy-box-ico__ogOGm.Fiat_ico3__3UcV9 {
    padding-bottom: 20px;
}

.Fiat_new-icon-jeep_icon_calendar__1IsqF {
    font-size: 47px;
    width: 96px;
    background: #fff;
    margin: 50px auto auto;
    padding: 0px 20px;
}

.Fiat_new-icon-fiat_icon_testdrive__VvOc4:before {
    content: "\e924";
    color: #ad0c33;
}

.Fiat_copy-box-ico__ogOGm.Fiat_ico2__1pxkt.Fiat_new-icon-jeep_icon_calendar__1IsqF:before {
    content: "\e922";
    color: #ad0c33;
}

.Fiat_new-icon-jeep_icon_testdrive__3Pf29 {
    font-size: 47px;
    width: 96px;
    background: #fff;
    margin: 50px auto auto;
    padding: 0px 20px;
}

.Fiat_containerCTATYP__LFp5z {
    margin-bottom: 20px;
    margin-top: 30px;
}

.Fiat_rect-btn-red__2ejOS {
    background-color: #AD0C33;
    color: #fff;
}

.Fiat_rect-btn-grey__qvX55 {
    background: #888888 !important;
    color: #fff;
}

.Fiat_rect-btn-red__2ejOS, .Fiat_rect-btn-grey__qvX55 {
    font-size: 13px;
    font-family: "Open Sans", sans-serif !important;
    min-width: 220px;
    height: 40px;
    border-radius: 5px;
    text-align: left;
    padding: 13px 35px;
    margin-top: 40px;
    text-transform: uppercase;
    cursor: pointer;
    max-width: 250px;
    position: relative;

}

.Fiat_rect-btn-red__2ejOS:hover, .Fiat_rect-btn-grey__qvX55:hover {
    text-decoration: none !important;
    color: #FFFFFF !important;
}

.Fiat_copy-box-ico__ogOGm {
    font-family: 'icomoon-form' !important;
    font-size: 47px;
    margin-top: 50px;
}

.Fiat_copy-box-ico__ogOGm.Fiat_ico3__3UcV9.Fiat_new-icon-jeep_icon_testdrive__3Pf29:before {
    content: "\e91f";
    color: #ad0c33;
}

.Fiat_new-icon-fiat_icon_brochure__1iOWZ:before {
    content: "\e91d";
    color: #ad0c33;
}

.Fiat_new-icon-fiat_icon_discovermodels__U6MPc:before {
    content: "\e91f";
    color: #ad0c33;
}

.Fiat_new-icon-fiat_icon_discoverpromotions__1rAj1:before {
    content: "\e920";
    color: #ad0c33;
}

.Fiat_answerTitle__2xDcZ > h2 {
    padding-top: 50px;
    padding-bottom: 20px;
    color: #484848 !important;
}

.Fiat_copy-box__1oZ5a {
    text-align: center;
    /*padding-top: 40px;*/
    position: relative;
    font-family: "Open Sans", sans-serif !important;
    font-size: 16px;
    color: #000000;
    height: auto;
}

.Fiat_fiat-red-color__EQotP {
    color: #AD0C33 !important;
}

/*FIAT PRO STYLE*/
.Fiat_new-form-submit__4YhMw.Fiat_submit__2UpHg.Fiat_btn-red-shadow__1-yWb > button {
    background-color: #9C1019;
    box-shadow: 0 2px 17px -5px #333333;
    color: #fff;
    outline: none;
    border-radius: 30px;
    padding: 12px 20px;
    font-weight: 600;
    font-family: "Open Sans", sans-serif !important;
    position: relative;
    width: 100%;
    max-width: 200px !important;
    text-align: left;
}

.Fiat_btn-red-shadow__1-yWb button:after {
    content: "\e948";
    font-family: 'icomoon-form' !important;
    position: absolute;
    right: 20px;
}

.Fiat_btn-red-shadow__1-yWb button:hover:before {
    content: "\e949";
    font-family: 'icomoon-form' !important;
    position: absolute;
    right: 20px;
}

.Fiat_new-form-submit__4YhMw.Fiat_submit__2UpHg.Fiat_btn-red-shadow__1-yWb > button:hover {
    background-color: #fff !important;
    color: #9C1019;
    box-shadow: 0 2px 20px -5px #333333;
    border-radius: 30px;
}

/*.rect-btn-red.fiatpro-btn.btn-red-shadow {*/
/*    background-color: #9C1019;*/
/*    box-shadow: 0 2px 17px -5px #333333;*/
/*    color: #fff;*/
/*    outline: none;*/
/*    border-radius: 30px;*/
/*    padding: 12px 20px;*/
/*    font-weight: 600;*/
/*    font-family: Roboto, serif;*/
/*}*/

.Fiat_form-wizard__1V1nv > h1, .Fiat_page-owner__3RRQV h1 {

    font-weight: bold !important;
    color: #ad0c32;
    font-size: 37px;
    font-family: "Open Sans", sans-serif !important;
    display: block;
    margin: 0 auto;
    text-align: left;

}


/*wizard form*/
.Fiat_bs-wizard__cT1iq {
    padding: 0;
    position: relative;
}

li:first-child {
    border-color: #AD0C32 !important;
    color: #AD0C32 !important;
}

/*.step-indicator li:nth-child(1) {*/
/* border: 1px solid #AD0C33;*/
/*}*/
#Fiat_step1__XIcet .Fiat_step__2rUGY {
    border: 4px solid #AD0C33;

    font-family: "Open Sans", sans-serif !important;

    text-align: center;

    font-weight: 800;
    font-size: 18px;

}

.Fiat_new-form-container__3icAe .Fiat_bs-wizard__cT1iq > .Fiat_bs-wizard-step__3U6Xd:first-child.Fiat_active__1eVfw > .Fiat_progress__2yblv {
    width: 0%;
}

.Fiat_new-form-container__3icAe .Fiat_bs-wizard__cT1iq > .Fiat_bs-wizard-step__3U6Xd:first-child > .Fiat_progress__2yblv {
    left: 50%;
    width: 50%;
}

.Fiat_new-form-container__3icAe .Fiat_bs-wizard__cT1iq > .Fiat_bs-wizard-step__3U6Xd > .Fiat_progress__2yblv {
    position: relative;
    border-radius: 0px;
    height: 4px;
    box-shadow: none;
    margin: 26px 0;
}

.Fiat_new-form-container__3icAe .Fiat_progress__2yblv {
    overflow: hidden;
    height: 20px;
    margin-bottom: 20px;
    background: none;
    /* background-color: #D8D8D8; */
    border-radius: 4px;
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
}

.Fiat_progress__2yblv {
    height: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    background-color: #f5f5f5;
    border-radius: 4px;
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
}

.Fiat_new-form-container__3icAe .Fiat_bs-wizard__cT1iq > .Fiat_bs-wizard-step__3U6Xd.Fiat_active__1eVfw > .Fiat_progress__2yblv > .Fiat_progress-bar__3xUi3 {
    width: 50%;
    transition: none;
}

.Fiat_new-form-container__3icAe .Fiat_bs-wizard__cT1iq > .Fiat_bs-wizard-step__3U6Xd > .Fiat_progress__2yblv > .Fiat_progress-bar__3xUi3 {
    width: 0px;
    box-shadow: none;
    background: #AD0C32;
    transition: none;
}

.Fiat_progress-bar__3xUi3 {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
    transition: width .6s ease;
}

/*font */
.Fiat_fs-small__an35f {
    font-size: 11px !important;
}

/*WIZARD FORM*/
.Fiat_w-30__1Sgrh {
    width: 100%;
    max-width: 768px;
}

@media (max-width: 768px) {
    .Fiat_w-30__1Sgrh {
        max-width: 100% !important;
    }
}

.Fiat_step-indicator__14pNU {
    border-collapse: separate;
    display: table;
    margin-left: 0px;
    position: relative;
    table-layout: fixed;
    text-align: center;
    vertical-align: middle;
    padding-left: 0;
    padding-top: 20px;
}

.Fiat_step-indicator__14pNU li {
    display: table-cell;
    position: relative;
    float: none;
    padding: 0;
    width: 1%;
}


li.Fiat_complete__lGsB5:after {
    background-color: #ccc;
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    width: 100%;
    top: 22px;
}

.Fiat_step-indicator__14pNU li:after {
    left: 55%;
}

.Fiat_step-indicator__14pNU li:last-child:after {
    display: none;
}

.Fiat_step-indicator__14pNU li.Fiat_active__1eVfw .Fiat_step__2rUGY {
    border-color: #AD0C32;
    color: #AD0C32;
}

.Fiat_step-indicator__14pNU li.Fiat_active__1eVfw .Fiat_caption__trLwz {
    color: #AD0C32;
}

.Fiat_step-indicator__14pNU li.Fiat_complete__lGsB5:after {
    background-color: #AD0C32;
    height: 3px;
}

.Fiat_step-indicator__14pNU li.Fiat_complete__lGsB5 .Fiat_step__2rUGY {
    border-color: #AD0C32;
    color: #AD0C32;
    background: #AD0C33 !important;
}


.Fiat_step-indicator__14pNU li.Fiat_complete__lGsB5 .Fiat_caption__trLwz {
    color: #AD0C32;
}

.Fiat_step-indicator__14pNU .Fiat_step__2rUGY {

    border-radius: 50%;
    border: 3px solid #D8D8D8;
    color: #ccc;
    font-size: 20px;
    height: 40px;
    line-height: 40px;
    margin: 0 auto;
    position: relative;
    width: 40px;
    z-index: 1;
    background-color: #D8D8D8;
    color: #fff;
}

.Fiat_step-indicator__14pNU .Fiat_step__2rUGY:hover {
    cursor: pointer;
}

.Fiat_step-indicator__14pNU .Fiat_caption__trLwz {
    color: #ccc;
    padding: 11px 16px;
}


.Fiat_complete__lGsB5 > .Fiat_step__2rUGY {
    background-color: #AD0C32;

}


.Fiat_complete__lGsB5 > .Fiat_step__2rUGY > span {
    color: #fff;
}

.Fiat_form-wizard__1V1nv * span {
    font-family: "Open Sans", sans-serif !important;
    font-size: 12px;
    /*color: #0a0a0a;*/
    font-weight: bold;
    text-transform: uppercase;
}

.Fiat_step__2rUGY > span {
    top: -3px;
    position: absolute;
    left: 0;
    right: 0;
    /*font-weight: normal;*/
    font-size: 18px;
    font-weight: 700;
    font-family: "Open Sans", sans-serif !important;
}


/*.complete + li > .step , .complete + li:nth-child(2) .step {*/
/*    background-color: #AD0C33 !important;*/
/*}*/

.Fiat_complete__lGsB5 {
    font-weight: bold;
}

.Fiat_complete__lGsB5 + li > .Fiat_step__2rUGY {
    border: 4px solid #AD0C33;
    color: #AD0C33;


}

.Fiat_active__1eVfw {
    background: #fff !important;
}

/*#step1 > div.step {*/
/*    border:4px solid #AD0C33 !important;*/
/*    background-color: #FFFFFF;*/
/*    color: #AD0C33;*/
/*    font-weight: bold;*/

/*}*/
@media (max-width: 768px) {
    .Fiat_new-form-step-content-list-flex__2GNIp {
        flex-direction: column !important;
    }

    .Fiat_formAnagrafic__1KzHC {
        margin-top: 5rem;
    }

    .Fiat_new-form-submit__4YhMw.Fiat_submit__2UpHg {
        text-align: center;
    }

    .Fiat_step-indicator__14pNU {
        font-size: .7rem !important;
    }
}

@media (max-width: 999px) {
    .Fiat_new-form-step-content-list-item__RcTsZ, .Fiat_wrap-car-model__2T7r4 {
        flex-basis: 32% !important;
    }
}

.Fiat_active-step__2f65P .Fiat_step__2rUGY {
    background: #fff !important;
    border: 3px solid #AD0C33;
    color: #AD0C33;
    font-weight: bold !important;
}

.Fiat_ctive-step__8fs5j .Fiat_step__2rUGY span {
    color: #000 !important;
}

.Fiat_submit-fiat__2I2ZQ button, .Fiat_submit-fiat__2I2ZQ a {
    width: 180px;
    text-align: left;
    line-height: 1.6;
    font-family: "Open Sans", sans-serif;
    font-size: 10px;
    letter-spacing: 0;
    font-weight: bold;
    border-radius: 5px;
    background-color: #AD0C33;
    color: #fff;
    text-transform: uppercase;
    position: relative;
    padding: 12px 20px;
    border: 0;
}

.Fiat_submit-fiat__2I2ZQ button:hover {
    color: #fff !important;
}

.Fiat_submit-fiat__2I2ZQ button:after, .Fiat_submit-fiat__2I2ZQ a:after {
    content: "\e91b";
    font-family: 'icomoon-form' !important;
    font-size: 6px;
    position: absolute;
    right: 20px;
    top: 15px;
    /*padding: 12px;*/
}

input[type="checkbox"] {
    cursor: pointer !important;
}

.Fiat_section-models__3N1iD, .Fiat_section-header__2OJlC h2 {
    font-family: "Open Sans", sans-serif !important;
    font-size: 22px !important;
    color: #737373;
    letter-spacing: 0;
    text-align: left;
    padding-right: 15px;
    /*padding-left: 15px;*/
    font-weight: 700;
    padding-left: 0;
    outline: 0 !important;
}

/*.section-models, .section-header h2:hover {*/
/*    color: #fff !important;*/
/*}*/

@media (max-width: 760px) {
    .Fiat_form-wizard__1V1nv {
        width: 100% !important;
        display: block !important;
        margin: 0 auto;
    }
}
/* jeep css  */

/*@import url("https://www.jeep.fr/etc.clientlibs/jeep/clientlibs/clientlib-base.min.css");*/


@font-face {
    font-family: "icomoon-form";
    src: url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.ttf?u9p37z");
    src: url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.eot?u9p37z#iefix") format("embedded-opentype"),
    url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.ttf?u9p37z") format("truetype"),
    url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.woff?u9p37z") format("woff"),
    url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.svg?u9p37z#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'proxima_nova_semibold';
    src: url("https://www.jeep.fr/etc.clientlibs/jeep/clientlibs/clientlib-base/resources/assets/fonts/ProximaNova-Semibold.eot");
    src: url("https://www.jeep.fr/etc.clientlibs/jeep/clientlibs/clientlib-base/resources/assets/fonts/ProximaNova-Semibold.eot?#iefix") format("embedded-opentype"), url("https://www.jeep.fr/etc.clientlibs/jeep/clientlibs/clientlib-base/resources/assets/fonts/ProximaNova-Semibold.woff") format("woff"), url("https://www.jeep.fr/etc.clientlibs/jeep/clientlibs/clientlib-base/resources/assets/fonts/ProximaNova-Semibold.ttf") format("truetype"), url("https://www.jeep.fr/etc.clientlibs/jeep/clientlibs/clientlib-base/resources/assets/fonts/ProximaNova-Semibold.svg#proxima_novasemibold") format("svg");
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'proxima_nova_rgbold';
    src: url("https://www.jeep.fr/etc.clientlibs/jeep/clientlibs/clientlib-base/resources/assets/fonts/ProximaNova-Bold.eot");
    src: url("https://www.jeep.fr/etc.clientlibs/jeep/clientlibs/clientlib-base/resources/assets/fonts/ProximaNova-Bold.eot?#iefix") format("embedded-opentype"), url("https://www.jeep.fr/etc.clientlibs/jeep/clientlibs/clientlib-base/resources/assets/fonts/ProximaNova-Bold.woff") format("woff"), url("https://www.jeep.fr/etc.clientlibs/jeep/clientlibs/clientlib-base/resources/assets/fonts/ProximaNova-Bold.ttf") format("truetype"), url("https://www.jeep.fr/etc.clientlibs/jeep/clientlibs/clientlib-base/resources/assets/fonts/ProximaNova-Bold.svg#robotoregular") format("svg");
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'proxima_nova_rgregular';
    src: url("https://www.jeep.fr/etc.clientlibs/jeep/clientlibs/clientlib-base/resources/assets/fonts/ProximaNova-Reg.eot");
    src: url("https://www.jeep.fr/etc.clientlibs/jeep/clientlibs/clientlib-base/resources/assets/fonts/ProximaNova-Reg.eot?#iefix") format("embedded-opentype"), url("https://www.jeep.fr/etc.clientlibs/jeep/clientlibs/clientlib-base/resources/assets/fonts/ProximaNova-Reg.woff") format("woff"), url("https://www.jeep.fr/etc.clientlibs/jeep/clientlibs/clientlib-base/resources/assets/fonts/ProximaNova-Reg.ttf") format("truetype"), url("https://www.jeep.fr/etc.clientlibs/jeep/clientlibs/clientlib-base/resources/assets/fonts/ProximaNova-Reg.svg#robotoregular") format("svg");
    font-weight: normal;
    font-style: normal
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

.Jeep_accordion__3Ftck {
    font-size: 17px;
    margin: 0;
}

.Jeep_accordion__button__33fxQ {
    align-items: center;
    background: black;
    border: 0;
    color: white;
    cursor: pointer;
    display: flex;
    font-size: inherit;
    justify-content: space-between;
    margin: 0;
    padding: 25px 30px;
    width: 100%;
    font-family: 'Roboto', sans-serif !important;
}

.Jeep_accordion__button__33fxQ span {
    height: 30px;
    /*width: 60px;*/
    /* border-radius: 50%; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #000;
    font-size: 15px;
    white-space: nowrap;
}

.Jeep_accordion__button__33fxQ:focus {
    outline: none;
}

.Jeep_accordion__content__kg-XD {
    background: #fff;
    padding: 0 20px;
    opacity: 0;
    transition: all 0.5s;
    height: 0;
    margin: 0;
    margin-top: -10px;
    border: 1px solid #f1f1f1;
    border-top: 0;
    overflow: hidden;
}

.Jeep_accordion__content__kg-XD.Jeep_active__1NiUG {
    height: auto;
    opacity: 1;
    padding: 20px;
    display: block;
    background: #fff;
}

.Jeep_section-header__1Wnqz {
    color: #222222;
    padding-bottom: 40px;
    padding-top: 40px;
    position: relative;
    text-align: center;
    font-family: "proxima_nova_rgbold", sans-serif !important;
}

/* .new-form-header-title {
  border-bottom: 4px solid #ffba00;
} */
.Jeep_new-form-header-title__2fio1 {
    font-size: 14px;
    font-weight: 700;
    position: relative;
    top: -7px;
    border-bottom: 4px solid #bdbdbd;
    padding-bottom: 2px;
}

#Jeep_essai-bg__3PXV4 {
    background: url("https://www.jeep.fr/content/dam/jeep/crossmarket/hero/jeep-Ecommerce-1600X505-V4.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    max-height: 600px;
    min-height: 600px;
}

.Jeep_subtitle__3z22Y {
    font-size: 35px !important;
    font-family: "proxima_nova_rgbold", sans-serif !important;
    font-weight: bold;
    line-height: 1.1;
    margin-bottom: 10px;
    text-align: center;
    color: #fff;
    padding-top: 5rem;
}

.Jeep_new-form-vehicle-image__2DC05 {
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    color: #222;
    text-align: center;
    box-sizing: border-box;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline !important;
    border: 0 none !important;
    margin: 0 0 30px !important;
    max-height: 130px !important;
    cursor: pointer;
}

.Jeep_card__2_HAl {
    border: 0;
}

.Jeep_color-yellow__3uY-Y {
    margin-left: 10px;
    color: #ffba00;
    position: relative;
    font-size: 12px;
}

.Jeep_new-form-vehicle-link__34PBa,
.Jeep_step-cars__KJYr0 {
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    text-align: center;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    background-color: transparent;
    color: black;
    font-weight: 700;
    font-size: 16px;
    font-family: 'Roboto', sans-serif !important;
    text-decoration: none;
    cursor: pointer;
}

.Jeep_new-form-vehicle-link__34PBa,
.Jeep_step-cars__KJYr0:hover {
    padding: 0 !important;
    text-decoration: none !important;
    color: #000 !important;
}


.Jeep_accordion__button__33fxQ svg, .Jeep_new-icon-jeep_arrow_cta__13aX2 {
    color: #ffba00 !important;
}

.Jeep_accordion__content__kg-XD .Jeep_active__1NiUG + .Jeep_accordion__button__33fxQ {
    color: #ffba00 !important;
}


.Jeep_new-icon-jeep_arrow_cta__13aX2 {
    margin-left: 10px;
    color: #ffba00 !important;
    position: relative;
    font-size: 12px;
}

.Jeep_new-form-step-content-list-flex__3VTqJ {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    justify-content: left;
}

.Jeep_new-form-step-content-list-item__3EBLV .Jeep_wrap-car-model__3Bdl9 {
    flex-basis: 25%;
    align-self: stretch;
    padding-bottom: 40px;
    margin-bottom: 50px;
    position: relative;
}

.Jeep_new-form-vehicle__3QHWl {
    padding: 0 10px;
    text-align: center;
}

.Jeep_new-form-vehicle-title__26ujP {
    margin: 0 0 10px 0;
    font-weight: 700;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Roboto', sans-serif !important;
}

.Jeep_new-form-vehicle-subtitle__2D8kX {
    font-family: 'Roboto', sans-serif !important;
    font-size: 12px;
    margin-bottom: 25px;
    cursor: pointer;
}

.Jeep_new-form-step-content-list-item__3EBLV .Jeep_wrap-car-model__3Bdl9:after {
    content: " ";
    border-bottom: 1px solid #e7e7e7;
    width: calc(100% - 20px);
    position: absolute;
    height: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.Jeep_new-form-step-content-list-flex__3VTqJ {
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    color: #222;
    box-sizing: border-box;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    justify-content: left;
}

.Jeep_new-form-step-content-list-item__3EBLV,
.Jeep_wrap-car-model__3Bdl9 {
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    color: #222;
    box-sizing: border-box;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    flex-basis: 25%;
    align-self: stretch;
    padding: 0 0 40px;
    margin: 0 0 50px;
    position: relative;
}

.Jeep_new-form-vehicle__3QHWl {
    padding: 0 10px;
    text-align: center;
}

.Jeep_wrap-car-model__3Bdl9::after {
    width: calc(100% - 50px);
    content: " ";
    border-bottom: 1px solid #e7e7e7;
    position: absolute;
    height: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.Jeep_new-form-step-content-list__2BzvP {
    margin-top: 40px;
}

.Jeep_new-form-vehicle-title__26ujP {
    margin: 0 0 10px 0;
    font-weight: 700;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Roboto', sans-serif !important;
}

.Jeep_req-fields__6ECJ- {
    font-family: 'Roboto', sans-serif !important;
    font-size: 13px !important;
}

.Jeep_new-form-text-title__18f4Y, .Jeep_new-form-text-content__15zBl {
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    color: #222;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    font-family: 'Roboto', sans-serif !important;
}

.Jeep_new-form-text-content__15zBl {
    font-family: 'Roboto', sans-serif !important;
}

.Jeep_title-form__2uJ8a {
    font-family: 'Roboto', sans-serif !important;
    font-size: 16px;
}

.Jeep_formAnagrafic__PCVjB * input, .Jeep_formAnagrafic__PCVjB * label, .Jeep_formAnagrafic__PCVjB * select {
    font-family: Roboto, serif !important;
    font-style: normal;
    font-size: 16px;
}

.Jeep_formAnagrafic__PCVjB * div {
    font-family: 'Roboto', sans-serif !important;
}

.Jeep_fm-jeep__1LCIA {
    font-family: Roboto, serif !important;
    font-size: 14px !important;
}

.Jeep_acceptedMarketingActivity__2MuZD + label {
    font-family: Roboto, serif !important;
}

.Jeep_p-abs__2S8om {
    font-family: Roboto, sans-serif;
    padding-left: 10px;
    color: #828282;
    overflow-x: visible;
    font-size: 14px !important;
    font-weight: 400;
}

.Jeep_contFormAnagraphic__WA80k {
    height: auto;
    overflow: hidden;
    padding-bottom: 50px;
    padding-top: 15px;
}

.Jeep_dropdownSelect__3nIig {
    border: none;
    border-bottom: solid 1px #828282;
    background: none;
    height: 45px;
    display: block;
    width: 100%;
    -webkit-appearance: none;
    outline: 0;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
    font-size: 16px;
    font-family: inherit;
    line-height: 37px;
    padding: 0 25px 0 10px;
}

option:hover {
    background-color: #ffba00 !important;
}

@media only screen and (max-width: 999px) {
    .Jeep_wrap-car-model__3Bdl9 {
        flex-basis: 50%;
    }
}

@media only screen and (max-width: 767px) {
    .Jeep_wrap-car-model__3Bdl9 {
        flex-basis: 100%;
    }
}

/*FORM  */
.Jeep_section-anagraphic__2Ymce,
.Jeep_title-form__2uJ8a {
    font-size: 16px;
    font-family: Roboto;
}

.Jeep_section-anagraphic__2Ymce,
.Jeep_new-form-img-title__3I_Fd {
    font-family: 'Roboto', sans-serif !important;
    font-size: 25px;
    font-weight: bold;
    margin-top: 7px;
    margin-bottom: 20px;
}

.Jeep_dropdownSelect__3nIig > option:first-child {
    height: 0 !important;
}

/*.p-abs {*/
/*    position: absolute;*/
/*    bottom: 30px;*/
/*}*/

/*option:checked, .p-abs {*/
/*    bottom: 50px;*/
/*}*/
.Jeep_new-select-form__2h3eJ {
    position: relative;
}

.Jeep_new-select-form__2h3eJ * label[for='gender'], .Jeep_new-select-form__2h3eJ * label[for='city'] {
    top: -2px !important;
}

.Jeep_new-select-form__2h3eJ:after {
    /*content: "\f078";*/
    /*font-family: "FontAwesome" !important;*/
    content: "\e902";
    font-family: icomoon-form !important;
    color: #828282;
    position: absolute;
    right: 7px;
    top: 22px;
    z-index: 1;
    width: 14px;
    height: 20px;
    pointer-events: none;
    font-size: 9px;
}

.Jeep_new-form-link__3ZQgF,
.Jeep_new-form-link-change__36CDz,
.Jeep_model__YiecG {
    margin-top: 15px;
    color: black;
    display: inline-block;
    font-family: 'Roboto', sans-serif !important;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
}

.Jeep_new-icon-jeep_arrow_changemodel__3AgCu {
    font-size: 13px;
    margin-right: 4px;
    color: #ffba01;
}

.Jeep_new-icon-jeep_icon_brochure__nVK2x:before {
    content: "\e903";
    color: #ffba00;

}

.Jeep_copy-box-ico__1BZpF {
    font-family: 'icomoon-form' !important;
    font-size: 47px;
}

.Jeep_new-icon-jeep_icon_discoverpromotions__5GMTt:before {
    content: "\e907";
    color: #ffb800;
}

.Jeep_new-icon-jeep_icon_discovermodels__2HRF8:before {
    content: "\e906";
    color: #ffb900;
}

.Jeep_formAnagrafic__PCVjB,
.Jeep_mui-form__eT99Q {
    font-size: 14px;
}

.Jeep_mui-textfield__1Frga > input {
    padding-left: 10px;
    padding-right: 10px;
    height: 37px !important;
    margin-bottom: 0 !important;
}

.Jeep_mui-textfield__1Frga > input:focus + label,
.Jeep_mui-textfield__1Frga > select:focus + label,
.Jeep_mui-textfield__1Frga > input[value]:not([value=""]) + label {
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transform: translate(0, 0);
}

.Jeep_mui-textfield__1Frga > input:focus {
    border-color: #828282;
    border-width: 1px;
}

.Jeep_mui-textfield--float-label__5L0QK > label {
    transition: all 0.15s ease-out;
}

.Jeep_mui-radio__WwO10 .Jeep_mui-radio-inline__2thZc .Jeep_field__iHxW3 label {
    display: inline-block;
    height: 20px;
    position: relative;
    padding: 0 30px;
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;
    white-space: nowrap;
    width: 140px;
}


.Jeep_mui-radio__WwO10 label {
    padding: 0 15px !important;
}

.Jeep_form-check-input__VwCJk {
    border: 1px solid #828282 !important;
    box-shadow: 0 0 0 1px #828282;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: #fff;
    transition: all ease-in 0.2s;
    cursor: pointer;
}

.Jeep_form-check-input__VwCJk:checked {
    border-color: #ffba00 !important;
    background-color: #ffba00 !important;
}

.Jeep_form-check-input__VwCJk:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: #828282;
}

.Jeep_form-check__NiHY0,
.Jeep_form-check-inline__3a_KK,
.Jeep_form-check-label__2JLQH {
    margin-left: 10px;
}

.Jeep_section-anagraphic__2Ymce,
.Jeep_new-form-text__cHaev {
    margin: 40px 0 10px 0;
    line-height: 1.42857;
}

.Jeep_new-form-text__cHaev a {
    color: black;
    text-decoration: underline;
    font-size: 14px;
}

.Jeep_jeep-btn__2fsJw button {
    background-color: #ffba00;
    color: black;
    font-family: Roboto, serif !important;
    width: 144px;
    cursor: pointer;
    padding: 12px 20px;
    border-radius: 2px;
    font-weight: bold;
    font-size: 15px;
    display: inline-block;
    margin-right: 10px;
    text-transform: uppercase;
    border: 0;
    -webkit-appearance: button;
    white-space: pre-wrap;
}

.Jeep_jeep-btn__2fsJw button svg {
    margin-left: 10px;
}

.Jeep_accordion__3Ftck > .Jeep_accordion__button__33fxQ > .Jeep_accordion__content__kg-XD > .Jeep_active__1NiUG {
    display: none;
}

.Jeep_new-form-linktext__2OmXX {
    margin-left: 5px;
    text-decoration: underline !important;
    cursor: pointer !important;
}

.Jeep_new-form-link__3ZQgF,
.Jeep_new-form-link-change__36CDz,
.Jeep_model__YiecG,
.Jeep_new-form-link__3ZQgF,
.Jeep_new-form-link-change__36CDz,
.Jeep_model__YiecG:hover {
    text-decoration: none !important;
    color: black;
}

.Jeep_bb-yellow__38c_z {
    border-bottom: 4px solid #ffba00;
}

.Jeep_new-form-header-number__2H-NP {
    display: inline-block;
    height: 35px !important;
    width: 35px !important;
    background: #bdbdbd !important;
    font-size: 25px !important;
    font-weight: 700;
    color: #636363 !important;
    border-radius: 50% !important;
    padding-left: -3.5px !important;
    padding-top: 2px !important;
    margin-right: 15px !important;
    position: relative !important;
}

.Jeep_new-form-header-number__2H-NP svg {
    color: #fff !important;
}

.Jeep_new-form-header-number__2H-NP.Jeep_bg-yellow__5Fr8f {
    background: #ffba00 !important;
}

.Jeep_bg-yellow__5Fr8f {
    color: #000 !important;
}

.Jeep_rect-btn-yellow__3S17c {
    background-color: #ffba00;
    color: black;
    text-decoration: none !important;
    padding: 8px 10px;
    margin-right: 5px;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 2px;
    font-weight: bold;
    font-size: 14px;
    display: inline-block;
    text-transform: uppercase;
    border: 0;
    white-space: nowrap;
}

/* .rect-btn-yellow::after {
    content: "\f054";
    font-family:"FontAwesome" ;
    margin-right: 20px;
    
} */
.Jeep_showroom-card__3ZBfo {
    color: #333 !important;
    font-family: 'Roboto', sans-serif !important;
}

.Jeep_showroom-card__3ZBfo .Jeep_showroom-city__3zIH0 {
    margin: 0 !important;
}

.Jeep_showroom-card__3ZBfo .Jeep_showroom-title__1lXRC {
    margin-top: 25px;
}

.Jeep_showroom-card__3ZBfo a {
    color: #000 !important;
    max-width: 150px;
    white-space: nowrap;
    width: 100%;
    margin: 0;
}

.Jeep_showroom-card__3ZBfo .Jeep_showroom-address__2BueB {
    padding-bottom: 10px !important;
}

.Jeep_showroom-card__3ZBfo a {
    margin-top: 20px;
}

/*.showroom-title:after {*/
/*    content: "";*/
/*    width: 34px;*/
/*    height: 2px;*/
/*    background-color: #ad0c33;*/
/*    display: inline-block;*/
/*    position: absolute;*/
/*    left: 0;*/
/*    bottom: -5px;*/
/*}*/


.Jeep_rect-btn-yellow__3S17c svg {
    margin-left: 20px;
}

.Jeep_rect-btn-yellow__3S17c:hover {
    color: #000 !important;
}

.Jeep_showroom-title__1lXRC {
    font-weight: 800;
    min-height: 30px;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.Jeep_section-header__1Wnqz h2, .Jeep_section-header__1Wnqz h1 {
    font-size: 35px;
    text-transform: uppercase;
    max-width: 80%;
    margin: 0 auto 40px;
    font-family: "proxima_nova_rgbold", sans-serif;
    font-weight: bold;
}

.Jeep_form-title__19PmE {
    background: #000 !important;
    /*margin-bottom: 30px;*/
}

.Jeep_form-title__19PmE h1 {
    font-size: 34px;
    font-weight: bold;
    padding: 2rem 0;
    font-family: "proxima_nova_rgbold", sans-serif !important;
    color: #fff;
    text-transform: uppercase;
    margin: 0 auto;
    text-align: center;
}

.Jeep_section-header__1Wnqz h4 {
    line-height: 1.2em;
    width: 100%;
    padding: 0 20px;
    max-width: 672px;
    font-size: 14px;
    margin: 20px auto 0;
    font-weight: 400;
    font-family: 'Roboto', sans-serif !important;
}

.Jeep_copy-box__CMdqG, .Jeep_answerTitle__j6P5o {
    padding-top: 0;
    padding-bottom: 20px;
    text-transform: capitalize;
}

.Jeep_copy-box__CMdqG, .Jeep_answerTitle__j6P5o h2 {
    font-size: 25px;
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-family: "proxima_nova_rgbold", sans-serif !important;
}

.Jeep_copy-box-ico__1BZpF .Jeep_ico1__1fhhB .Jeep_new-icon-jeep_icon_quote__38LZF {
    font-size: 47px;
    width: 93px;
    margin: auto;
    background: #fff;
    padding: 0px 20px;
}

[class^="new-icon-"], [class*=" new-icon-"] {
    font-family: 'icomoon-form' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Jeep_new-icon-jeep_icon_quote__38LZF:before {
    content: "\e90a";
    color: #ffb900;
}

.Jeep_new-icon-separator-container__8MUrY {
    height: auto;
    overflow: hidden;
    position: relative;
    padding-top: 10px;
    max-width: inherit !important;
}

.Jeep_new-icon-jeep_icon_quote__38LZF, .Jeep_new-icon-jeep_icon_calendar__2GtHE, .Jeep_new-icon-jeep_icon_testdrive__2KcdZ {
    font-size: 47px !important;
    width: 93px !important;
    margin: auto !important;
    background: #fff !important;
    padding: 0px 20px !important;
}

.Jeep_new-icon-separator-container__8MUrY > .Jeep_separator__3y4gs {
    width: 60%;
    height: 2px;
    background: #ffba00;
    /* margin: auto; */
    top: 35px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 768px) {
    .Jeep_col-sm-4__35NC7 {
        width: 33.33333333% !important;
    }
}

.Jeep_new-icon-jeep_icon_calendar__2GtHE:before {
    content: "\e904";
    color: #ffba00;
}

.Jeep_new-icon-jeep_icon_testdrive__2KcdZ:before {
    content: "\e90b";
    color: #fbba00;
}

.Jeep_copy-box__CMdqG {
    text-align: center !important;
    padding-top: 40px;
    position: relative !important;
    font-family: 'Roboto', sans-serif !important;
    font-size: 16px;
    color: #000000 !important;
    height: auto !important;
    min-height: 130px !important;
    line-height: 16px;
    font-weight: 400 !important;

}

.Jeep_containerCTATYP__3LOTw > a:hover {
    color: inherit !important;
    cursor: pointer;
}

.Jeep_rect-btn-yellow__3S17c, .Jeep_rect-btn-grey__gDVAk {
    position: relative;
    font-size: 16px;
    text-align: left;
    font-family: "proxima_nova_rgbold", sans-serif !important;
    width: auto !important;
    max-width: 230px !important;
    min-width: 200px !important;
    padding: 12px 15px;
    margin-top: 30px;
    border: 0 !important;
    text-transform: uppercase;
    cursor: pointer;
}

.Jeep_rect-btn-yellow__3S17c:hover, .Jeep_rect-btn-grey__gDVAk:hover {
    text-decoration: none !important;
    color: inherit !important;
}

.Jeep_rect-btn-yellow__3S17c {
    background-color: #ffba00;
    color: black;
}

.Jeep_rect-btn-grey__gDVAk {
    background-color: #888888 !important;
    color: #fff !important;
}

.Jeep_rect-btn-yellow__3S17c * svg {
    position: absolute;
    right: 10px;
}


.Jeep_containerCTATYP__3LOTw > a > svg {
    margin-left: 65px;
}

.Jeep_new-form-submit__dwi2N .Jeep_submit-jeep__1XSFS {
    margin-top: 30px;
}

.Jeep_submit-jeep__1XSFS {
    position: relative;
    /*max-width: 280px;*/
    width: 100% !important;
}

.Jeep_submit-jeep__1XSFS > button, .Jeep_submit-jeep__1XSFS a {
    color: black;
    font-family: Roboto, serif !important;
    font-weight: 700;
    max-width: 200px !important;
    min-width: 200px !important;
    width: 100%;
    background-color: #edad01;
    cursor: pointer;
    padding: 12px 20px;
    border-radius: 2px;
    font-size: 15px;
    display: inline-block;
    margin-right: 10px;
    text-transform: uppercase;
    border: 0;
    -webkit-appearance: button;
    white-space: pre-line;
    position: relative;
    text-align: left;

}

.Jeep_submit-jeep__1XSFS button:after, .Jeep_submit-jeep__1XSFS a:after {
    content: "\e901";
    font-family: 'icomoon-form' !important;
    position: absolute;
    right: 15px;
}

.Jeep_submit-jeep__1XSFS > button:focus, .Jeep_submit-jeep__1XSFS > a:focus {
    outline: 0 !important;
    box-shadow: none !important;
}

.Jeep_bg-yellow__5Fr8f span::before {
    border-radius: 50%;
    position: absolute;
    height: 35px;
    width: 35px;
    top: 0;
    left: 0;
    background: #ffba00;
    color: white;
}

.Jeep_new-form-vehicle__3QHWl {
    margin-top: 40px !important;
}


.Jeep_page-inner__27Ouf {
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    width: 1450px;
}

@media only screen and (max-width: 1599px) {
    .Jeep_page-inner__27Ouf {
        width: 100%;
        max-width: 1450px;
    }
}

.Jeep_copy-box-ico__1BZpF .Jeep_ico1__1fhhB .Jeep_new-icon-jeep_icon_quote__38LZF {
    font-size: 47px;
    width: 93px;
    margin: auto;
    background: #fff;
    padding: 0px 20px;
}

.Jeep_new-form-container__hKsV9 .Jeep_thank-you-page__1cSV5 .Jeep_thank-you-page-buttons__2jM1J, .Jeep_new-form-container__hKsV9 .Jeep_thank-you-section__3he61 .Jeep_thank-you-page-buttons__2jM1J {
    max-width: 1100px;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 40px;
    height: auto;
    overflow: hidden;
    position: relative;
    padding-top: 10px;
}

.Jeep_thank-you-section__3he61 .Jeep_new-icon-jeep_icon_quote__38LZF {
    font-size: 47px;
    width: 93px;
    margin: auto;
    background: #fff;
    padding: 0px 20px;

}

.Jeep_new-icon-jeep_icon_quote__38LZF, .Jeep_new-icon-jeep_icon_calendar__2GtHE, .Jeep_new-icon-jeep_icon_testdrive__2KcdZ {
    font-family: 'icomoon-form' !important;
    speak: none !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-feature-settings: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    line-height: 1 !important;
    -webkit-font-smoothing: antialiased !important;
}

.Jeep_copy-box__CMdqG {
    text-transform: inherit;
}

.Jeep_showroom-city__3zIH0 {
    position: relative;
    padding: 0 !important;
    margin: 1rem;
    font-family: 'Roboto', sans-serif;

}

.Jeep_showroom-city__3zIH0:after {
    content: "";
    width: 34px;
    height: 2px;
    background-color: #ffba00;
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: -3px;
}

.Jeep_showroom-address__2BueB {
    font-size: 14px;
    font-weight: 400 !important;
    text-transform: lowercase !important;
}


@media (max-width: 768px) {
    .Jeep_new-form-step-content-list-flex__3VTqJ {
        flex-direction: column !important;
    }

    .Jeep_formAnagrafic__PCVjB {
        margin-top: 5rem;
    }

    .Jeep_new-form-submit__dwi2N.Jeep_submit__31MGQ {
        text-align: center;
    }

    .Jeep_step-indicator__3N0BI {
        font-size: .7rem !important;
    }

    .Jeep_section-header__1Wnqz h2, .Jeep_section-header__1Wnqz h1 {
        font-size: 25px;
        max-width: 100%;
    }
}

/* PRIVACY */

.boxMessage {
    color: #000;
    font-family: 'Roboto';
    font-size: 14px;
    line-height: 1.2em;
    margin-top: 3px;
    padding-bottom: 10px;
}

 .privacy-title {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: normal;
}

 .form-radios-group-infos-content p {
     margin-bottom: 1rem !important;
 }

 .form-radios-group-infos-content {
     width: 100%;
     max-height: 60px;
     height: 100px;
     overflow-y: scroll;
 }
 .privacyMask {
     /*background: linear-gradient(to bottom, rgba(255,255,255,0) 71%,rgba(255,255,255,0.14) 75%,rgba(255,255,255,1) 100%);*/
     width: 100%;
     height: 140px;
     position: absolute;
     pointer-events: none;
     bottom: 0;
 }
/* alfaromeo css  */


@font-face {
    font-family: "icomoon-form";
    src: url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.ttf?u9p37z");
    src: url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.eot?u9p37z#iefix") format("embedded-opentype"),
    url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.ttf?u9p37z") format("truetype"),
    url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.woff?u9p37z") format("woff"),
    url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.svg?u9p37z#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    font-family: 'Apex New', sans-serif !important;
}


::-webkit-input-placeholder {
    font-family: 'Apex New', sans-serif !important;
}


:-ms-input-placeholder {
    font-family: 'Apex New', sans-serif !important;
}


::placeholder {
    font-family: 'Apex New', sans-serif !important;
}

.AlfaRomeo_accordion__2EHfW {
    font-size: 17px;
    margin: 0;
}

.AlfaRomeo_mui-textfield__32_fP > label {
    padding-left: 10px;
    color: #909090;
    overflow-x: visible;
    font-size: 16px;
    font-family: 'Apex New Book', sans-serif !important;
    font-style: italic;
    color: #909090 !important;
    line-height: 24px;
    top: -3px;
}

.AlfaRomeo_accordion__button__2o2t4 {
    align-items: center;
    background: black;
    border: 0;
    color: white;
    cursor: pointer;
    display: flex;
    font-size: inherit;
    justify-content: space-between;
    margin: 0;
    padding: 25px 30px;
    width: 100%;
}

.AlfaRomeo_accordion__button__2o2t4 span {
    height: 30px;
    /*width: 60px;*/
    /* border-radius: 50%; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #000;
    font-size: 15px;
    white-space: nowrap;
}

.AlfaRomeo_accordion__button__2o2t4:focus {
    outline: none;
}

.AlfaRomeo_accordion__content__202ty {
    background: #fff;
    padding: 0 20px;
    opacity: 0;
    transition: all 0.5s;
    height: 0;
    margin: 0;
    margin-top: -10px;
    border: 1px solid #f1f1f1;
    border-top: 0;
    overflow: hidden;
}

.AlfaRomeo_accordion__content__202ty.AlfaRomeo_active__2ctFT {
    height: auto;
    opacity: 1;
    padding: 20px;
    display: block;
    background: #f1f1f1;
}

.AlfaRomeo_section-header__38wFH {
    color: #000;
    padding-bottom: 40px;
    padding-top: 40px;
    position: relative;
    text-align: center;
    font-family: "proxima_nova_rgbold", sans-serif !important;
}

.AlfaRomeo_section-header__38wFH.AlfaRomeo_title-form__aAoXz h2 {
    font-family: "Apex New Medium", sans-serif !important;
    font-weight: 800;
    font-size: 16px;
    text-transform: inherit;
    text-align: left;
    margin: 0;
    color: #000000 !important;
}

/* .new-form-header-title {
  border-bottom: 4px solid #ffba00;
} */
.AlfaRomeo_new-form-header-title__2iKxM {
    font-size: 14px;
    font-weight: 700;
    position: relative;
    top: -7px;
    border-bottom: 4px solid #bdbdbd;
    padding-bottom: 2px;
}

#AlfaRomeo_essai-bg__Fuw-1 {
    background: url("https://www.jeep.fr/content/dam/jeep/crossmarket/hero/jeep-Ecommerce-1600X505-V4.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    max-height: 600px;
    min-height: 600px;
}

.AlfaRomeo_subtitle__2q5S4 {
    font-size: 35px !important;
    font-family: "proxima_nova_rgbold", sans-serif !important;
    font-weight: bold;
    line-height: 1.1;
    margin-bottom: 10px;
    text-align: center;
    color: #fff;
    padding-top: 5rem;
}

.AlfaRomeo_new-form-vehicle-image__rtxbk {
    margin-bottom: 20px;
    max-height: inherit;
    cursor: pointer;
    width: 100%;
}

.AlfaRomeo_new-form-vehicle__1OSoh {
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    margin: 10px;
}

.AlfaRomeo_new-form-vehicle-title__1Qq2F {
    text-align: left;
    margin-left: 20px;
}

.AlfaRomeo_card__8IEh2 {
    border: 0;
}

.AlfaRomeo_color-yellow__1NZtg {
    margin-left: 10px;
    color: #ffba00;
    position: relative;
    font-size: 12px;
}

.AlfaRomeo_new-form-vehicle-link__2WZVT,
.AlfaRomeo_step-cars__3YnXu {
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    text-align: center;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    background-color: transparent;
    color: black;
    font-weight: 700;
    font-size: 16px;
    font-family: 'Apex New', sans-serif !important;
    text-decoration: none;
    cursor: pointer;
}

.AlfaRomeo_new-form-vehicle-link__2WZVT,
.AlfaRomeo_step-cars__3YnXu:hover {
    padding: 0 !important;
    text-decoration: none !important;
    color: #000;
}

.AlfaRomeo_accordion__button__2o2t4 svg {
    color: #ffba00 !important;
}

.AlfaRomeo_accordion__content__202ty .AlfaRomeo_active__2ctFT + .AlfaRomeo_accordion__button__2o2t4 {
    color: #ffba00 !important;
}

.AlfaRomeo_new-icon-jeep_arrow_cta__2n2D2 {
    margin-left: 10px;
    color: #ffba00;
    position: relative;
    font-size: 12px;
}

.AlfaRomeo_new-form-step-content-list-flex__2GlNj {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    justify-content: left;
}

.AlfaRomeo_new-form-step-content-list-item__1yr7t .AlfaRomeo_wrap-car-model__2ZqfW {
    flex-basis: 25%;
    align-self: stretch;
    padding-bottom: 40px;
    margin-bottom: 50px;
    position: relative;
}

.AlfaRomeo_new-form-vehicle__1OSoh {
    padding: 0 10px;
    text-align: center;
}

.AlfaRomeo_new-form-vehicle-title__1Qq2F {
    margin: 0 0 10px 0;
    font-weight: 700;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Apex New', sans-serif !important;
}

.AlfaRomeo_new-form-step-content-list-item__1yr7t .AlfaRomeo_wrap-car-model__2ZqfW:after {
    content: " ";
    border-bottom: 1px solid #e7e7e7;
    width: calc(100% - 20px);
    position: absolute;
    height: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.AlfaRomeo_new-form-step-content-list-flex__2GlNj {
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    color: #222;
    box-sizing: border-box;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    justify-content: left;
}

.AlfaRomeo_new-form-step-content-list-item__1yr7t,
.AlfaRomeo_wrap-car-model__2ZqfW {
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    color: #222;
    box-sizing: border-box;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    flex-basis: 25%;
    align-self: stretch;
    padding: 0 0 40px;
    margin: 0 0 50px;
    position: relative;
}

.AlfaRomeo_new-form-vehicle__1OSoh {
    padding: 0 10px;
    text-align: center;
}

.AlfaRomeo_wrap-car-model__2ZqfW::after {
    width: calc(100% - 50px);
    content: " ";
    border-bottom: 1px solid #e7e7e7;
    position: absolute;
    height: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.AlfaRomeo_new-form-step-content-list__tFp_p {
    margin-top: 40px;
}

.AlfaRomeo_new-form-vehicle-title__1Qq2F {
    margin: 0 0 10px 0;
    font-weight: 700;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Apex New', sans-serif !important;
}

.AlfaRomeo_contFormAnagraphic__DxGfg {
    height: auto;
    overflow: hidden;
    padding-bottom: 50px;
    padding-top: 15px;
}

.AlfaRomeo_dropdownSelect__2xmgG {
    border: none;
    border-bottom: solid 1px #828282;
    background: none;
    height: 55px;
    display: block;
    width: 100%;
    -webkit-appearance: none;
    outline: 0;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
    font-size: 16px;
    font-family: inherit;
    line-height: 37px;
    padding: 0px 25px 0px 10px;
}

.AlfaRomeo_fm-alfaromeo__2c9i_ * {
    font-family: "Apex New Book" !important;
    font-size: 16px !important;
    font-weight: 500;
}

option:hover {
    background-color: #ffba00 !important;
}

@media only screen and (max-width: 999px) {
    .AlfaRomeo_wrap-car-model__2ZqfW {
        flex-basis: 50%;
    }
}

@media only screen and (max-width: 767px) {
    .AlfaRomeo_wrap-car-model__2ZqfW {
        flex-basis: 100%;
    }
}

/*FORM  */
.AlfaRomeo_section-anagraphic__1tJhc,
.AlfaRomeo_title-form__aAoXz {
    font-size: 16px;
    font-family: 'Apex New Book', sans-serif !important;
}

.AlfaRomeo_section-anagraphic__1tJhc,
.AlfaRomeo_new-form-img-title__3f8cS {
    font-family: 'Apex New Bold', sans-serif !important;
    font-size: 25px;
    font-weight: bold;
    margin-top: 7px;
    margin-bottom: 20px;
    text-transform: uppercase;
}

dropdownSelect > option:first-child {
    height: 0 !important;
}

/*.p-abs {*/
/*    position: absolute;*/
/*    bottom: 30px;*/
/*}*/

/*option:checked, .p-abs {*/
/*    bottom: 50px;*/
/*}*/
.AlfaRomeo_new-select-form__25zb9 {
    position: relative;
}

/*CUSTOM INPUT STYLE*/
/*.new-select-form > div {*/
/*    background: #FFFFFF;*/
/*}*/

.AlfaRomeo_new-select-form__25zb9:after, .AlfaRomeo_dropdownSelect__2xmgG:after {
    /*content: "\f078";*/
    /*font-family: "FontAwesome" !important;*/
    content: "\e902";
    font-family: icomoon-form !important;
    color: #828282;
    position: absolute;
    right: 20px !important;
    top: 22px;
    z-index: 1;
    width: 14px;
    height: 20px;
    pointer-events: none;
    font-size: 12px !important;
}

.AlfaRomeo_new-form-link__1nL3P,
.AlfaRomeo_new-form-link-change__383xg,
.AlfaRomeo_model__3jnJy {
    margin-top: 15px;
    color: black;
    display: inline-block;
    font-family: 'Apex New', sans-serif !important;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
}

.AlfaRomeo_new-form-link__1nL3P {
    display: block;
    border: 1px solid rgb(156, 16, 25);
    padding: 15px;
    width: 100%;
    max-width: 250px;
    font-family: "Apex New Medium", sans-serif !important;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    position: relative;
}

.AlfaRomeo_new-form-link__1nL3P:before {
    content: "\e965";
    transform: rotate(180deg);
    display: block;
    font-family: 'icomoon-form' !important;
    position: absolute;
    left: 10px;
    color: rgb(156, 16, 25);
}

.AlfaRomeo_new-icon-jeep_arrow_changemodel__H7IXY {
    font-size: 13px;
    margin-right: 4px;
    color: #ffba01;
}

/*icons */
.AlfaRomeo_copy-box-ico__2OJFK {
    font-family: 'icomoon-form' !important;
    font-size: 47px;
    margin-top: 50px;
}

.AlfaRomeo_copy-box-ico__2OJFK.AlfaRomeo_ico3__1gZXT.AlfaRomeo_new-icon-jeep_icon_testdrive__1eiRR:before {
    content: "\e91f";
    color: #8F0C25;
}

.AlfaRomeo_new-icon-fiat_icon_brochure__T3wTp:before {
    content: "\e91d";
    color: #8F0C25;
}

.AlfaRomeo_new-icon-fiat_icon_discovermodels__3Sp1b:before {
    content: "\e91f";
    color: #8F0C25;
}

.AlfaRomeo_new-icon-fiat_icon_discoverpromotions__Y8imC:before {
    content: "\e920";
    color: #8F0C25;
}

.AlfaRomeo_new-icon-alfa-romeo_icon_quote__3FJRo:before {
    content: "\e96f";
    color: #8f0c25;
}

.AlfaRomeo_new-icon-alfa-romeo_icon_calendar__3-OBE:before {
    content: "\e968";
    color: #8f0c25;
}

.AlfaRomeo_new-icon-alfa-romeo_icon_testdrive__2n20k:before {
    content: "\e970";
    color: #8f0c25;
}

/*.formAnagrafic * div {*/
/*    font-family: 'Apex New', sans-serif !important;*/
/*}*/

.AlfaRomeo_anagraphic-btn__HMwq5 {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
    font: inherit;
    overflow: visible;
    line-height: normal;
    -webkit-appearance: button;
    cursor: pointer;
    font-weight: bold;
    display: inline-block;
    text-transform: uppercase;
    border: 0;
    background-color: #8F0C25;
    color: #fff;
    font-family: 'Apex New-Bold', sans-serif !important;
    font-size: 14px;
    width: 210px;
    border-radius: 0;
    text-align: center;
    padding: 13px 15px;
    margin: 40px 0 0;
    outline: none;
}


.AlfaRomeo_formAnagrafic__rd2z9,
.AlfaRomeo_mui-form__1mfaG {
    font-size: 14px;
}

.AlfaRomeo_mui-textfield__32_fP > input {
    padding-left: 10px;
    padding-right: 10px;
    height: 37px !important;
    margin-bottom: 0 !important;
    font-family: "Apex New Medium", sans-serif !important;
    font-size: 16px !important;
}


.AlfaRomeo_mui-textfield__32_fP > input:focus + label,
.AlfaRomeo_mui-textfield__32_fP > select:focus + label,
.AlfaRomeo_mui-textfield__32_fP > input[value]:not([value=""]) + label {
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transform: translate(0, 0);
}

.AlfaRomeo_mui-textfield__32_fP > input:focus {
    border-color: #828282;
    border-width: 1px;
}

.AlfaRomeo_mui-textfield--float-label__1XESv > label, label {
    transition: all 0.15s ease-out;
    font-family: 'Apex New Book', sans-serif !important;
    font-style: italic;
    font-size: 16px !important;
    color: #909090 !important;
    padding-left: 10px;
}

.AlfaRomeo_mui-radio__UiqF- .AlfaRomeo_mui-radio-inline__nzKI_ .AlfaRomeo_field__2wkhN label {
    display: inline-block;
    height: 20px;
    position: relative;
    padding: 0 30px;
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;
    white-space: nowrap;
    width: 140px;
}

.AlfaRomeo_mui-radio__UiqF- label {
    padding: 0 15px !important;
}

.AlfaRomeo_form-check-input__15Wwn {
    border: 1px solid #828282 !important;
    box-shadow: 0 0 0 1px #828282;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: #fff;
    transition: all ease-in 0.2s;
    cursor: pointer;
}

.AlfaRomeo_form-check-input__15Wwn:checked {
    border-color: #ffba00 !important;
    background-color: #ffba00 !important;
}

.AlfaRomeo_form-check-input__15Wwn:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: #828282;
}

.AlfaRomeo_form-check__1j3WH,
.AlfaRomeo_form-check-inline__19fpx,
.AlfaRomeo_form-check-label__BprlI {
    margin-left: 10px;
}

.AlfaRomeo_section-anagraphic__1tJhc,
.AlfaRomeo_new-form-text__2POum {
    margin: 40px 0 10px 0;
    line-height: 1.42857;
}

.AlfaRomeo_new-form-text__2POum a {
    color: black;
    text-decoration: underline;
    font-size: 14px;
}

.AlfaRomeo_jeep-btn__24w6k button {
    background-color: #ffba00;
    color: black;
    font-family: 'Apex New', sans-serif !important;
    width: 144px;
    cursor: pointer;
    padding: 12px 20px;
    border-radius: 2px;
    font-weight: bold;
    font-size: 15px;
    display: inline-block;
    margin-right: 10px;
    text-transform: uppercase;
    border: 0;
    -webkit-appearance: button;
    white-space: pre-wrap;
}

.AlfaRomeo_jeep-btn__24w6k button svg {
    margin-left: 10px;
}

.AlfaRomeo_accordion__2EHfW > .AlfaRomeo_accordion__button__2o2t4 > .AlfaRomeo_accordion__content__202ty > .AlfaRomeo_active__2ctFT {
    display: none;
}

.AlfaRomeo_new-form-linktext__1io8- {
    margin-left: 10px;
}

.AlfaRomeo_new-form-link__1nL3P,
.AlfaRomeo_new-form-link-change__383xg,
.AlfaRomeo_model__3jnJy,
.AlfaRomeo_new-form-link__1nL3P,
.AlfaRomeo_new-form-link-change__383xg,
.AlfaRomeo_model__3jnJy:hover {
    text-decoration: none !important;
    color: black;
}

.AlfaRomeo_bb-yellow__3I37Z {
    border-bottom: 4px solid #ffba00;
}

.AlfaRomeo_new-form-header-number__KSYF_ {
    display: inline-block;
    height: 35px !important;
    width: 35px !important;
    background: #bdbdbd !important;
    font-size: 25px !important;
    font-weight: 700;
    color: #636363 !important;
    border-radius: 50% !important;
    padding-left: -3.5px !important;
    padding-top: 2px !important;
    margin-right: 15px !important;
    position: relative !important;
}


.AlfaRomeo_rect-btn-yellow__Pi8z9 {
    background-color: #ffba00;
    color: black;
    text-decoration: none !important;
    padding: 8px 10px;
    margin-right: 5px;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 2px;
    font-weight: bold;
    font-size: 14px;
    display: inline-block;
    text-transform: uppercase;
    border: 0;
    white-space: nowrap;
}

/* .rect-btn-yellow::after {
    content: "\f054";
    font-family:"FontAwesome" ;
    margin-right: 20px;
    
} */
.AlfaRomeo_showroom-card__kAAQX {
    max-width: 100%;
    min-width: 400px;
    position: relative;
    font-family: 'Apex New', sans-serif !important;
}

.AlfaRomeo_rect-btn-yellow__Pi8z9 svg {
    margin-left: 20px;
}

.AlfaRomeo_rect-btn-yellow__Pi8z9:hover {
    color: #000 !important;
}

.AlfaRomeo_showroom-title__2Hmjy {
    font-weight: 800;
    min-height: 30px;
    font-size: 16px;
    text-transform: uppercase;
    padding-top: 15px;
}

.AlfaRomeo_showroom-address__QVkYU, .AlfaRomeo_showroom-phone__Ajipd {
    font-family: "Apex New Book", sans-serif !important;
    font-size: 13px;
    text-transform: capitalize !important;
}

.AlfaRomeo_showroom-card__kAAQX {
    color: #333 !important;
    font-family: Roboto, sans-serif !important;
}

.AlfaRomeo_showroom-card__kAAQX .AlfaRomeo_showroom-city__2FVXo {
    margin: 0 !important;
}

.AlfaRomeo_showroom-card__kAAQX .AlfaRomeo_showroom-title__2Hmjy {
    margin-top: 25px;
}

.AlfaRomeo_rect-btn-red__3xKSp {
    color: #FFFFFF !important;
    max-width: 150px;
    white-space: nowrap;
    width: 100%;
    margin: 0;
}


.AlfaRomeo_showroom-card__kAAQX .AlfaRomeo_showroom-address__QVkYU {
    padding-bottom: 10px !important;
}

.AlfaRomeo_showroom-card__kAAQX a {
    margin-top: 20px;
}

/*.showroom-card a {*/
/*    color: #000;*/
/*    border: 1px solid #8F0C25;*/
/*    max-width: 150px;*/
/*    border-radius: 5px;*/
/*    padding: 15px 20px;*/
/*    position: relative;*/

/*}*/

.AlfaRomeo_showroom-card__kAAQX a svg {
    position: absolute;
    right: 10px;
}

.AlfaRomeo_showroom-city__2FVXo {
    position: relative;
    padding: 0 !important;
    margin: 0 15px;
}

.AlfaRomeo_showroom-city__2FVXo::after {
    content: "";
    width: 34px;
    height: 2px;
    background-color: #8F0C25;
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: -3px;

}


/*thank you*/
.AlfaRomeo_section-header__38wFH h2 {
    color: #777777;
    font-size: 30px;
    text-transform: inherit;
    margin-bottom: 40px;
    text-align: center;
}

.AlfaRomeo_new-icon-separator-container__3pEHI {
    height: auto;
    overflow: hidden;
    position: relative;
    padding-top: 10px;
}

.AlfaRomeo_new-icon-separator-container__3pEHI .AlfaRomeo_separator__v67Td {
    width: 67%;
    height: 2px;
    background: #8F0C25;
    /* margin: auto; */
    top: 35px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
}

.AlfaRomeo_new-icon-jeep_icon_quote__1jA9l {
    font-size: 47px;
    width: 93px;
    margin: auto;
    background: #fff;
    padding: 0px 20px;
    margin-top: 50px;
}

.AlfaRomeo_copy-box-ico__2OJFK.AlfaRomeo_ico1__2wTAs.AlfaRomeo_new-icon-jeep_icon_quote__1jA9l:before {
    content: "\e923";
    color: #8F0C25;
}

.AlfaRomeo_new-icon-alfa-romeo_icon_discoverdealers__1ud9x:before {
    content: "\e96a";
    color: #8f0c25;
}

.AlfaRomeo_new-icon-jeep_icon_calendar__KWxty {
    font-size: 47px;
    width: 96px;
    background: #fff;
    margin: 50px auto auto;
    padding: 0px 20px;
}

.AlfaRomeo_copy-box-ico__2OJFK.AlfaRomeo_ico2__11NyD.AlfaRomeo_new-icon-jeep_icon_calendar__KWxty:before {
    content: "\e922";
    color: #8F0C25;
}

.AlfaRomeo_new-icon-alfa-romeo_icon_brochure__LSnPv:before {
    content: "\e967";
    color: #8f0c25;
}

.AlfaRomeo_new-icon-alfa-romeo_icon_discovermodels__cAqMQ:before {
    content: "\e96b";
    color: #8f0c25;
}

.AlfaRomeo_new-icon-alfa-romeo_icon_discoverpromotions__2eMuk:before {
    content: "\e96c";
    color: #8f0c25;
}

.AlfaRomeo_new-icon-jeep_icon_testdrive__1eiRR {
    font-size: 47px;
    width: 96px;
    background: #fff;
    margin: 50px auto auto;
    padding: 0px 20px;
}

.AlfaRomeo_containerCTATYP__3kPhq {
    margin-bottom: 20px;
    margin-top: 30px;
}

.AlfaRomeo_containerCTATYP__3kPhq a {
    background-color: #8F0C25;
    color: #fff;
    font-size: 13px;
    font-family: "Open Sans", sans-serif !important;
    width: 200px;
    height: 40px;
    border-radius: 5px;
    text-align: left;
    padding: 13px 15px;
    margin-top: 40px;
}

.AlfaRomeo_copy-box-ico__2OJFK.AlfaRomeo_ico3__1gZXT.AlfaRomeo_new-icon-jeep_icon_testdrive__1eiRR:before {
    content: "\e91f";
    color: #8F0C25;
}

.AlfaRomeo_answerTitle__2-7UG > h2 {
    padding-top: 50px;
    padding-bottom: 20px;
    color: #484848 !important;
}

.AlfaRomeo_copy-box__nmfMd {
    text-align: center;
    padding-top: 40px;
    position: relative;

    font-family: "Apex Book ", sans-serif !important;
    line-height: 22px;

    font-size: 16px;
    color: #000000;
    height: auto;

}

.AlfaRomeo_rect-btn-red__3xKSp {
    background-color: #8F0C25;
    color: #fff;
}

.AlfaRomeo_rect-btn-red-sec__7wjbd {
    background-color: #FFFFFF !important;
    color: #000000 !important;
    border: 1px solid #8F0C25;
}

.AlfaRomeo_rect-btn-red__3xKSp, .AlfaRomeo_rect-btn-red-sec__7wjbd {

    font-family: 'Apex New Bold', sans-serif !important;
    font-size: 14px;
    width: 210px;
    /* height: 40px; */
    border-radius: 0;
    text-align: center;
    padding: 13px 15px;
    margin-top: 40px;
    outline: none;
    text-transform: uppercase;
    cursor: pointer;
}

.AlfaRomeo_rect-btn-red__3xKSp:hover, .AlfaRomeo_rect-btn-red-sec__7wjbd:hover {
    text-decoration: none !important;
    color: inherit !important;
}

.AlfaRomeo_fiat-red-color__GxmZS {
    color: #8F0C25 !important;
}

.AlfaRomeo_radio-checked-red__Kua4m:checked {
    border-color: #8F0C25 !important;
    background-color: #8F0C25 !important;
}

/*WIZARD FORM*/
.AlfaRomeo_step-indicator__2zvpK {
    border-collapse: separate;
    display: table;
    margin-left: 0px;
    position: relative;
    table-layout: fixed;
    text-align: center;
    vertical-align: middle;
    padding-left: 0;
    padding-top: 20px;
}

.AlfaRomeo_step-indicator__2zvpK li {
    display: table-cell;
    position: relative;
    float: none;
    padding: 0;
    width: 1%;
}


li.AlfaRomeo_complete__XI55A:after {
    background-color: #ccc;
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    width: 100%;
    top: 32px;
}

.AlfaRomeo_step-indicator__2zvpK li:after {
    left: 50%;
}

.AlfaRomeo_step-indicator__2zvpK li:last-child:after {
    display: none;
}

.AlfaRomeo_step-indicator__2zvpK li.AlfaRomeo_active__2ctFT .AlfaRomeo_step__3W6PC {
    border-color: #AD0C32;
    color: #AD0C32;
}

.AlfaRomeo_step-indicator__2zvpK li.AlfaRomeo_active__2ctFT .AlfaRomeo_caption__cxrPq {
    color: #AD0C32;
}

.AlfaRomeo_step-indicator__2zvpK li.AlfaRomeo_complete__XI55A:after {
    background-color: #AD0C32;
    height: 3px;
}

.AlfaRomeo_step-indicator__2zvpK li.AlfaRomeo_complete__XI55A .AlfaRomeo_step__3W6PC {
    border-color: #AD0C32;
    color: #AD0C32;
}

.AlfaRomeo_step-indicator__2zvpK li.AlfaRomeo_complete__XI55A .AlfaRomeo_caption__cxrPq {
    color: #AD0C32;
}

.AlfaRomeo_step-indicator__2zvpK .AlfaRomeo_step__3W6PC {

    border-radius: 50%;
    border: 3px solid #D8D8D8;
    color: #ccc;
    font-size: 20px;
    height: 64px;
    line-height: 64px;
    margin: 0 auto;
    position: relative;
    width: 64px;
    z-index: 1;
    background-color: #D8D8D8;
    color: #fff;
}

.AlfaRomeo_step-indicator__2zvpK .AlfaRomeo_step__3W6PC:hover {
    cursor: pointer;
}

.AlfaRomeo_step-indicator__2zvpK .AlfaRomeo_caption__cxrPq {
    color: #ccc;
    padding: 11px 16px;
}


.AlfaRomeo_complete__XI55A > .AlfaRomeo_step__3W6PC {
    background-color: #AD0C32;

}


.AlfaRomeo_complete__XI55A > .AlfaRomeo_step__3W6PC > span {
    color: #fff;
}

.AlfaRomeo_form-wizard__1Ms2A * span {
    font-family: 'Apex New Bold', sans-serif !important;
    font-size: 12px;
}

/*.complete + li > .step , .complete + li:nth-child(2) .step {*/
/*    background-color: #8F0C25 !important;*/
/*}*/

.AlfaRomeo_complete__XI55A {
    font-weight: bold;
}

.AlfaRomeo_complete__XI55A + li > .AlfaRomeo_step__3W6PC {
    border: 3px solid #8F0C25;
    color: #8F0C25;

}

/*#step1 > div.step {*/
/*    border:3px solid #8F0C25 !important;*/
/*    background-color: #FFFFFF;*/
/*    color: #8F0C25;*/
/*    font-weight: bold;*/

/*}*/


@media (max-width: 768px) {
    .AlfaRomeo_new-form-step-content-list-flex__2GlNj {
        flex-direction: column !important;
    }

    .AlfaRomeo_formAnagrafic__rd2z9 {
        margin-top: 5rem;
    }

    .AlfaRomeo_new-form-submit__3z005.AlfaRomeo_submit__8tm51 {
        text-align: center;
    }

    .AlfaRomeo_step-indicator__2zvpK {
        font-size: .7rem !important;
    }
}

/*form type tabs*/
.AlfaRomeo_active-tab__37fXh {
    background: #8F0C25 !important;
    color: #fff !important;
    text-decoration: none !important;


}

.AlfaRomeo_btn-tabs__369zH {
    background: none;
    color: #000000;
    text-transform: uppercase;
    font-family: "Apex New Medium", Helvetica, Arial, sans-serif;
    font-weight: 400;
    margin: 2rem 0;
    height: 45px;
    padding: 15px !important;
    border: 0;
    width: 100%;
    max-width: 130px;
    text-align: center;
}

.AlfaRomeo_btn-tabs__369zH:hover {

    color: black;
    text-decoration: none;
}

.AlfaRomeo_btn-tabs__369zH:after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #8F0C25;
    transition: width .3s;
}

.AlfaRomeo_btn-tabs__369zH:hover::after {
    width: 100%;
}

.AlfaRomeo_submit-alfaromeo__1EF0x button {
    background-color: rgb(143, 12, 37);
    color: rgb(255, 255, 255);
    font-family: "Apex New Bold", sans-serif !important;
    border-radius: 0;
    padding: 12px 20px;
    position: relative;
    width: 100%;
    max-width: 200px;
    min-width: 200px;
}

.AlfaRomeo_submit-alfaromeo__1EF0x button:hover {
    color: #fff !important;
}

.AlfaRomeo_submit-alfaromeo__1EF0x button:before {
    content: "\e965";
    font-family: 'icomoon-form' !important;
    position: absolute;
    right: 10px;
}


@media (max-width: 768px) {
    .AlfaRomeo_form-wizard__1Ms2A {
        width: 100% !important;
        display: block !important;
        margin: 0 auto;
    }

    .AlfaRomeo_flex-direction-column__We_bs {
        flex-direction: row !important;
    }

    .AlfaRomeo_new-icon-separator-container__3pEHI {
        flex-direction: column !important;
    }

}

.AlfaRomeo_input__styles__Yb1ww {
    margin-bottom: 17px;
    background: #fff;
    color: #000000;
    font-family: 'Apex New Medium', sans-serif;
    font-style: italic;
    font-size: 16px;
    border-bottom: 1px solid #ccc !important;
    border: 0;
    width: 100%;
    padding: 20px 10px;
    position: relative;
}

.AlfaRomeo_input__styles__Yb1ww:focus {
    outline: none;
    border-bottom: 1px solid #828282 !important;
    transition: border ease-in-out 2ms;
}
.AlfaRomeo_input__styles__Yb1ww option {
    background: transparent;
    color: #000000;
}

.AlfaRomeo_animate-label__2DW0R {
    position: absolute;
    left: 0;
    right: 0;
    padding: 30px 0;
}

.AlfaRomeo_radio-btn__3d0_6 {
    color: #333333;
}
.AlfaRomeo_radio-label__1L5PM {
    font-family: 'Apex New Medium', sans-serif !important;
    color: #000000 !important;
    font-weight: bold;
    margin: 0 15px;
}

.AlfaRomeo_all-in-dark-gray__1_Wr0 * {
    color: #333333;
}

.AlfaRomeo_radio-btn__3d0_6:before {
    content: "";
    background: #f4f4f4;
    border-radius: 100%;
    border: 1px solid #b4b4b4;
    display: inline-block;
    width: 1.4em;
    height: 1.4em;
    position: relative;
    top: -0.2em;
    margin-right: 1em;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    transition: all 250ms ease;
}
.AlfaRomeo_radio-btn__3d0_6:checked:before {
    background-color: #8F0C25;
    box-shadow: inset 0 0 0 4px #f4f4f4;
}

.AlfaRomeo_flex-radio-btn__2bdFD {
    display: flex;
    align-items: center;
    grid-gap: 2rem;
    gap: 2rem;
}

.AlfaRomeo_error__1GIhn {
    color:red;
    position: absolute;
    right: 0;
    top: 0;
    padding: 15px;
}

.AlfaRomeo_correct__1h5N0 {
    color:green;
    border-bottom:green;
}

.AlfaRomeo_all-in-black__5r7Cb * {
    color: #333333 !important;
}

.AlfaRomeo_new-form-linktext__1io8- {
    cursor: pointer !important;
}

.AlfaRomeo_align-card-img__2MwH6 {
    text-align: left;
}


/* fiatpro css  */

@font-face {
    font-family: "icomoon-form";
    src: url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.ttf?u9p37z");
    src: url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.eot?u9p37z#iefix") format("embedded-opentype"),
        url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.ttf?u9p37z") format("truetype"),
        url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.woff?u9p37z") format("woff"),
        url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.svg?u9p37z#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
}


*,
*::before,
*::after {
    box-sizing: border-box;
}

.FiatPro_accordion__11LFE {
    font-size: 17px;
    margin: 0;
}

.FiatPro_accordion__button__2u0HG {
    align-items: center;
    background: black;
    border: 0;
    color: white;
    cursor: pointer;
    display: flex;
    font-size: inherit;
    justify-content: space-between;
    margin: 0;
    padding: 25px 30px;
    width: 100%;
}

.FiatPro_accordion__button__2u0HG span {
    height: 30px;
    /*width: 60px;*/
    /* border-radius: 50%; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #000;
    font-size: 15px;
    white-space: nowrap;
}

.FiatPro_accordion__button__2u0HG:focus {
    outline: none;
}

.FiatPro_accordion__content__10acI {
    background: #fff;
    padding: 0 20px;
    opacity: 0;
    transition: all 0.5s;
    height: 0;
    margin: 0;
    margin-top: -10px;
    border: 1px solid #f1f1f1;
    border-top: 0;
    overflow: hidden;
}

.FiatPro_accordion__content__10acI.FiatPro_active__2GrK2 {
    height: auto;
    opacity: 1;
    padding: 20px;
    display: block;
    background: #fff;
}

.FiatPro_section-header__3oyeJ.FiatPro_form-models-list__3MK9E h2 {
    font-family: "Roboto", sans-serif !important;
    font-size: 18px;
    color: #000;
    letter-spacing: 0;
    text-align: center;
    padding-right: 15px;
    padding-left: 15px;
    font-weight: 700;
    margin: 30px 0;
}

/* .new-form-header-title {
  border-bottom: 4px solid #ffba00;
} */
.FiatPro_new-form-header-title__3uANX {
    font-size: 14px;
    font-weight: 700;
    position: relative;
    top: -7px;
    border-bottom: 4px solid #bdbdbd;
    padding-bottom: 2px;
}

#FiatPro_essai-bg__3Dinr {
    background: url("https://www.jeep.fr/content/dam/jeep/crossmarket/hero/jeep-Ecommerce-1600X505-V4.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    max-height: 600px;
    min-height: 600px;
}

.FiatPro_subtitle__20zPQ {
    font-size: 35px !important;
    font-family: "proxima_nova_rgbold", sans-serif !important;
    font-weight: bold;
    line-height: 1.1;
    margin-bottom: 10px;
    text-align: center;
    color: #fff;
    padding-top: 5rem;
}

.FiatPro_new-form-vehicle__3aLQZ {
    text-align: center;
    background: transparent !important;
    margin: 10px;
    cursor: pointer;
}

[class^="new-icon-"],
[class*=" new-icon-"] {
    font-family: 'icomoon-form' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.FiatPro_new-icon-fiat-professional_icon_quote__qGF1l:before {
    content: "\e953";
}

.FiatPro_new-icon-fiat-professional_icon_calendar__3dULn:before {
    content: "\e94c";
}

.FiatPro_new-icon-fiat-professional_icon_testdrive__2RmwJ:before {
    content: "\e954";
}

.FiatPro_new-icon-fiat-professional_icon_discoverdealer__1jPrD:before {
    content: "\e94e";
}

.FiatPro_new-icon-fiat-professional_icon_brochure__YYqq7:before {
    content: "\e94b";
}

.FiatPro_new-icon-fiat-professional_icon_discovermodels__35ABO:before {
    content: "\e94f";
}

.FiatPro_new-icon-fiat-professional_icon_discoverpromotions__20wv5:before {
    content: "\e950";
}

.FiatPro_rect-btn-red-sec__1mxJ5,
.FiatPro_rect-btn-red__2T6Ay {
    font-size: 14px !important;
    font-family: "Roboto", sans-serif !important;
    max-width: 250px !important;
    height: 40px !important;
    border-radius: 30px !important;
    text-align: left !important;
    padding: 11px 15px !important;
    margin-top: 40px;
    outline: none;
    box-shadow: 0 2px 25px -5px #333333;
    font-weight: 600 !important;
}

.FiatPro_rect-btn-red__2T6Ay {
    background-color: #9C1019;
    color: #fff;
}

.FiatPro_rect-btn-red-sec__1mxJ5 {
    background-color: #fff !important;
    color: #333333 !important;
}

.FiatPro_rect-btn-red-sec__1mxJ5:hover {
    background-color: #333333 !important;
    color: #FFFFFF !important;
    text-decoration: none !important;
}

.FiatPro_rect-btn-red__2T6Ay:hover {
    background: #FFFFFF;
    color: #AD0C33;
    text-decoration: none !important;
}

.FiatPro_new-form-vehicle__3aLQZ:hover {
    background: white;
    border: none;
}

.FiatPro_new-form-vehicle-image__waG69 {
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    color: #222;
    text-align: center;
    box-sizing: border-box;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline !important;
    border: 0 none !important;
    margin: 0 0 30px !important;
    max-height: 130px !important;
    cursor: pointer;
}

.FiatPro_card__1NWAA {
    border: 0;
}

.FiatPro_color-yellow__zqcW1 {
    margin-left: 10px;
    color: #ffba00;
    position: relative;
    font-size: 12px;
}

.FiatPro_new-form-vehicle-link__3niZH,
.FiatPro_step-cars__12_sy {
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    text-align: center;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    background-color: transparent;
    color: black;
    font-weight: 700;
    font-size: 16px;
    font-family: Roboto, serif;
    text-decoration: none;
    cursor: pointer;
}

.FiatPro_new-form-vehicle-link__3niZH,
.FiatPro_step-cars__12_sy:hover {
    padding: 0 !important;
    text-decoration: none !important;
    color: #000 !important;
}

.FiatPro_accordion__button__2u0HG svg {
    color: #ffba00 !important;
}

.FiatPro_accordion__content__10acI .FiatPro_active__2GrK2+.FiatPro_accordion__button__2u0HG {
    color: #ffba00 !important;
}

.FiatPro_new-icon-jeep_arrow_cta__3VyHH {
    margin-left: 10px;
    color: #ffba00;
    position: relative;
    font-size: 12px;
}

.FiatPro_new-form-step-content-list-flex__1Q3Ra {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    justify-content: left;
}

.FiatPro_new-form-step-content-list-item__2gXX1 .FiatPro_wrap-car-model__2vePs {
    flex-basis: 25%;
    align-self: stretch;
    padding-bottom: 40px;
    margin-bottom: 50px;
    position: relative;
}

.FiatPro_new-form-vehicle__3aLQZ {
    padding: 0 10px;
    text-align: center;
}

.FiatPro_new-form-vehicle-title__3DaVz {
    /*margin: 0 0 10px 0;*/
    /*font-weight: 700;*/
    /*cursor: pointer;*/
    /*font-size: 16px;*/
    /*font-family: Roboto;*/
    position: absolute;
    top: 35px;
    left: 0;
    right: 0;
    color: #fff;
    font-family: 'Roboto', sans-serif !important;
    margin: 0 0 10px 0;
}

.FiatPro_new-form-step-content-list-item__2gXX1 .FiatPro_wrap-car-model__2vePs:after {
    content: " ";
    border-bottom: 1px solid #e7e7e7;
    width: calc(100% - 20px);
    position: absolute;
    height: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.FiatPro_new-form-step-content-list-flex__1Q3Ra {
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    color: #222;
    box-sizing: border-box;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    justify-content: left;
}

.FiatPro_new-form-step-content-list-item__2gXX1,
.FiatPro_wrap-car-model__2vePs {
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    color: #222;
    box-sizing: border-box;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    flex-basis: 25%;
    align-self: stretch;
    padding: 0 0 40px;
    margin: 0 0 50px;
    position: relative;
}

.FiatPro_new-form-vehicle__3aLQZ {
    padding: 0 10px;
    text-align: center;
}

.FiatPro_wrap-car-model__2vePs::after {
    width: calc(100% - 50px);
    content: " ";
    border-bottom: 1px solid #e7e7e7;
    position: absolute;
    height: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.FiatPro_new-form-step-content-list__3yoT- {
    margin-top: 40px;
}

.FiatPro_new-form-vehicle-title__3DaVz {
    margin: 0 0 10px 0;
    font-weight: 800;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Roboto', sans-serif !important;
}

.FiatPro_contFormAnagraphic__bk1fV {
    height: auto;
    overflow: hidden;
    padding-bottom: 50px;
    padding-top: 15px;
}

.FiatPro_dropdownSelect__1QljI {
    border: none;
    border-bottom: solid 1px #828282;
    background: none;
    height: 37px;
    display: block;
    width: 100%;
    -webkit-appearance: none;
    outline: 0;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
    font-size: 16px;
    font-family: inherit;
    line-height: 37px;
    padding: 0px 25px 0px 10px;
}

option:hover {
    background-color: #ffba00 !important;
}

@media only screen and (max-width: 999px) {
    .FiatPro_wrap-car-model__2vePs {
        flex-basis: 50%;
    }
}

@media only screen and (max-width: 767px) {
    .FiatPro_wrap-car-model__2vePs {
        flex-basis: 100%;
    }
}

/*FORM  */
.FiatPro_section-anagraphic__2Yqxr,
.FiatPro_title-form__3-nhn {
    font-size: 16px;
    font-family: 'Roboto', sans-serif !important;
}

.FiatPro_section-anagraphic__2Yqxr,
.FiatPro_new-form-img-title__C2Rkp {
    font-family: 'Roboto', sans-serif !important;
    font-size: 25px;
    font-weight: bold;
    margin-top: 7px;
    margin-bottom: 20px;
    color: #000;
}

dropdownSelect>option:first-child {
    height: 0 !important;
}

/*.p-abs {*/
/*    position: absolute;*/
/*    bottom: 30px;*/
/*}*/

/*option:checked, .p-abs {*/
/*    bottom: 50px;*/
/*}*/
.FiatPro_new-select-form__3DJ5G {
    position: relative;
}

.FiatPro_new-select-form__3DJ5G:after {
    content: "\f078";
    font-family: "FontAwesome" !important;
    /*content: "\e902";*/
    /*font-family: icomoon-form;*/
    color: #828282;
    position: absolute;
    right: 7px;
    top: 22px;
    z-index: 1;
    width: 14px;
    height: 20px;
    pointer-events: none;
    font-size: 9px;
}

.FiatPro_new-form-link__Hvmi0,
.FiatPro_new-form-link-change__3t7oI,
.FiatPro_model__319e4 {
    margin-top: 15px;
    color: black;
    display: inline-block;
    font-family: Roboto, serif;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
}

.FiatPro_new-icon-jeep_arrow_changemodel__2iGvf {
    font-size: 13px;
    margin-right: 4px;
    color: #ffba01;
}

.FiatPro_formAnagrafic__eJoHG,
.FiatPro_mui-form__3_BNY {
    font-size: 14px;
}

.FiatPro_mui-textfield__2t1ds>input {
    padding-left: 10px;
    padding-right: 10px;
    height: 37px !important;
    margin-bottom: 0 !important;
}

.FiatPro_mui-textfield__2t1ds>input:focus+label,
.FiatPro_mui-textfield__2t1ds>select:focus+label,
.FiatPro_mui-textfield__2t1ds>input[value]:not([value=""])+label {
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transform: translate(0, 0);
}

.FiatPro_mui-textfield__2t1ds>input:focus {
    border-color: #828282;
    border-width: 1px;
}

.FiatPro_mui-textfield--float-label__1m7wE>label {
    transition: all 0.15s ease-out;
}

.FiatPro_mui-radio__4sBhC .FiatPro_mui-radio-inline__3LPp6 .FiatPro_field__3jP6B label {
    display: inline-block;
    height: 20px;
    position: relative;
    padding: 0 30px;
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;
    white-space: nowrap;
    width: 140px;
}

.FiatPro_mui-radio__4sBhC label {
    padding: 0 15px !important;
}

.FiatPro_form-check-input__1M4zC {
    border: 1px solid #828282 !important;
    box-shadow: 0 0 0 1px #828282;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: #fff;
    transition: all ease-in 0.2s;
    cursor: pointer;
}

.FiatPro_form-check-input__1M4zC:checked {
    border-color: #ffba00 !important;
    background-color: #ffba00 !important;
}

.FiatPro_form-check-input__1M4zC:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: #828282;
}

.FiatPro_form-check__1VzEq,
.FiatPro_form-check-inline__3Zi7a,
.FiatPro_form-check-label__K8WmK {
    margin-left: 10px;
}

.FiatPro_section-anagraphic__2Yqxr,
.FiatPro_new-form-text__2_xoL {
    margin: 40px 0 10px 0;
    line-height: 1.42857;
}

.FiatPro_new-form-text__2_xoL a {
    color: black;
    text-decoration: underline;
    font-size: 14px;
}

.FiatPro_jeep-btn__3PTnX button {
    background-color: #ffba00;
    color: black;
    font-family: "Roboto", sans-serif;
    width: 144px;
    cursor: pointer;
    padding: 12px 20px;
    border-radius: 2px;
    font-weight: bold;
    font-size: 15px;
    display: inline-block;
    margin-right: 10px;
    text-transform: uppercase;
    border: 0;
    -webkit-appearance: button;
    white-space: pre-wrap;
}

.FiatPro_jeep-btn__3PTnX button svg {
    margin-left: 10px;
}

.FiatPro_accordion__11LFE>.FiatPro_accordion__button__2u0HG>.FiatPro_accordion__content__10acI>.FiatPro_active__2GrK2 {
    display: none;
}

.FiatPro_new-form-linktext__2-3sz {
    margin-left: 10px;
}

.FiatPro_new-form-link__Hvmi0,
.FiatPro_new-form-link-change__3t7oI,
.FiatPro_model__319e4,
.FiatPro_new-form-link__Hvmi0,
.FiatPro_new-form-link-change__3t7oI,
.FiatPro_model__319e4:hover {
    text-decoration: none !important;
    color: black;
}

.FiatPro_bb-yellow__2LXCD {
    border-bottom: 4px solid #ffba00;
}

.FiatPro_new-form-header-number__1emJp {
    display: inline-block;
    height: 35px !important;
    width: 35px !important;
    background: #bdbdbd !important;
    font-size: 25px !important;
    font-weight: 700;
    color: #636363 !important;
    border-radius: 50% !important;
    padding-left: -3.5px !important;
    padding-top: 2px !important;
    margin-right: 15px !important;
    position: relative !important;
}


.FiatPro_rect-btn-yellow__2odU_ {
    background-color: #ffba00;
    color: black;
    text-decoration: none !important;
    padding: 8px 10px;
    margin-right: 5px;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 2px;
    font-weight: bold;
    font-size: 14px;
    display: inline-block;
    text-transform: uppercase;
    border: 0;
    white-space: nowrap;
}

/* .rect-btn-yellow::after {
    content: "\f054";
    font-family:"FontAwesome" ;
    margin-right: 20px;
    
} */
.FiatPro_showroom-card__1ZXWR {
    max-width: 200px;
    min-width: 200px;
}

.FiatPro_rect-btn-yellow__2odU_ svg {
    margin-left: 20px;
}

.FiatPro_rect-btn-yellow__2odU_:hover {
    color: #000 !important;
}

.FiatPro_showroom-title__2Y11P {
    font-weight: 800;
    min-height: 30px;
    font-size: 16px;
    text-transform: uppercase;
}


/*WIZARD FORM*/
.FiatPro_step-indicator__1LwNJ {
    border-collapse: separate;
    display: table;
    margin-left: 0px;
    position: relative;
    table-layout: fixed;
    text-align: center;
    vertical-align: middle;
    padding-left: 0;
    padding-top: 20px;
}

.FiatPro_step-indicator__1LwNJ li {
    display: table-cell;
    position: relative;
    float: none;
    padding: 0;
    width: 1%;
}


li.FiatPro_complete__1sn1d:after {
    background-color: #ccc;
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    width: 100%;
    top: 32px;
}

.FiatPro_step-indicator__1LwNJ li:after {
    left: 50% !important;
}

.FiatPro_step-indicator__1LwNJ li:last-child:after {
    display: none;
}

.FiatPro_step-indicator__1LwNJ li.FiatPro_active__2GrK2 .FiatPro_step__2T9jt {
    border-color: #AD0C32;
    color: #AD0C32;
}

.FiatPro_step-indicator__1LwNJ li.FiatPro_active__2GrK2 .FiatPro_caption__35X6a {
    color: #AD0C32;
}

.FiatPro_step-indicator__1LwNJ li.FiatPro_complete__1sn1d:after {
    background-color: #AD0C32;
    height: 3px;
}

.FiatPro_step-indicator__1LwNJ li.FiatPro_complete__1sn1d .FiatPro_step__2T9jt {
    border-color: #AD0C32;
    color: #AD0C32;
}

.FiatPro_step-indicator__1LwNJ li.FiatPro_complete__1sn1d .FiatPro_caption__35X6a {
    color: #AD0C32;
}

.FiatPro_step-indicator__1LwNJ .FiatPro_step__2T9jt {

    border-radius: 50%;
    border: 3px solid #D8D8D8;
    color: #ccc;
    font-size: 20px;
    height: 64px;
    line-height: 64px;
    margin: 0 auto;
    position: relative;
    width: 64px;
    z-index: 1;
    background-color: #D8D8D8;
    color: #fff;
}

.FiatPro_step-indicator__1LwNJ .FiatPro_step__2T9jt:hover {
    cursor: pointer;
}

.FiatPro_step-indicator__1LwNJ .FiatPro_caption__35X6a {
    color: #ccc;
    padding: 11px 16px;
}


.FiatPro_complete__1sn1d>.FiatPro_step__2T9jt {
    background-color: #AD0C32;

}

#FiatPro_step1__1ihjV {
    border: 4px solid #AD0C33;
}

.FiatPro_complete__1sn1d>.FiatPro_step__2T9jt>span {
    color: #fff;
}

.FiatPro_form-wizard__1eu43 * span {
    font-family: "Open Sans", sans-serif;
}

/*.complete + li > .step , .complete + li:nth-child(2) .step {*/
/*    background-color: #AD0C33 !important;*/
/*}*/

.FiatPro_complete__1sn1d {
    font-weight: bold;
}

.FiatPro_complete__1sn1d+li>.FiatPro_step__2T9jt {
    border: 3px solid #AD0C33;
    color: #AD0C33;

}

@media (max-width: 768px) {
    .FiatPro_new-form-step-content-list-flex__1Q3Ra {
        flex-direction: column !important;
    }
}

.FiatPro_fw-fiatpro__16bGa {
    font-family: "Roboto", sans-serif !important;
}
/*reset all */
*, *:before, *:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/*body {*/
/*    background-color: #212121 !important;*/
/*}*/

/*font familly */

@font-face {
    font-family: "icomoon-form";
    src: url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.ttf?u9p37z");
    src: url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.eot?u9p37z#iefix") format("embedded-opentype"),
    url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.ttf?u9p37z") format("truetype"),
    url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.woff?u9p37z") format("woff"),
    url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.svg?u9p37z#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
}

/*form */
.formAnagrafic {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
}

/*INPUTS */
.mui-textfield {
    position: relative;
}

.mui-textfield, .form-group {
    margin-bottom: 25px !important;
}

.mui-radio .mui-radio-inline .field label {
    display: inline-block;
    height: 20px;
    position: relative;
    padding: 0 30px;
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;
    white-space: nowrap;
    width: 140px;
}

.mui-radio label {
    padding: 0 15px !important;
}

.mui-textfield--float-label > label {
    font-weight: 400;
    font-family: Roboto, serif;
    font-size: 13px !important;
    color: gray !important;
}

.form-control:focus {
    border-color: #828282 !important;
    border-width: 1px !important;
}

.form-check .form-check-input {
    height: 20px;
    width: 20px;
    margin-right: 10px;
    cursor: pointer;
}

.form-check:checked {
    transform: scale(1);
}

.form-check.form-check-inline > div {
    display: flex;
    align-items: center;
}

.form-check {
    padding: 0 20px;
    transform: scale(1);
}

.form-check-input:checked {
    border-color: #ffba00;
    background-color: #ffba00;
}

.form-check-input:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: #828282;
}


/*select input*/
.dropdownSelect {
    border: none;
    border-bottom: solid 1px #828282;
    background: none;
    height: 37px;
    display: block;
    width: 100%;
    -webkit-appearance: none;
    outline: 0;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
    font-size: 16px;
    font-family: inherit;
    line-height: 37px;
    padding: 0px 25px 0px 10px;
}

/*select options */
option {
    top: calc(100% + 2px) !important;
    height: auto !important;
    min-height: 84px;
    max-height: 150px;
    background: #fff;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    position: absolute;
    z-index: 2;
    width: 100%;
    overflow-y: auto;
    font-size: 16px;
    padding: 0 22px;
    line-height: 42px;
    cursor: pointer;
    white-space: nowrap;
    border-radius: 0;
}


.new-select-form:after {
    content: "\e902" !important;
    font-family: icomoon-form !important;
    color: #828282;
    position: absolute;
    right: 7px;
    top: 22px;
    z-index: 1;
    width: 14px;
    height: 20px;
    pointer-events: none;
    font-size: 9px;
}

/*input text container*/
.mui-textfield {
    margin-bottom: 25px;
    display: block;
    padding-top: 20px;
    position: relative;
}

.mui-textfield > input {
    padding-left: 10px;
    padding-right: 10px;
    height: 37px !important;
    margin-bottom: 0 !important;
}

.mui-textfield > input, .new-form-container .mui-textfield > textarea {
    border-color: #828282;
}

.mui-textfield > input, .mui-textfield > textarea {
    box-sizing: border-box;
    display: block;
    background-color: transparent;
    color: rgba(0, 0, 0, .87);
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, .26);
    outline: 0;
    width: 100%;
    padding: 0;
    box-shadow: none;
    border-radius: 0;
    font-size: 16px;
    font-family: inherit;
    line-height: inherit;
    background-image: none;
    padding-left: 5px;
}

/*label input style*/
.mui-textfield--float-label > label {
    padding-left: 10px;
    color: #828282;
    overflow-x: visible;
    font-size: 14px;
}

.mui-textfield--float-label > label {
    position: absolute;
    transform: translate(0, 15px);
    font-size: 16px;
    line-height: 30px;
    color: rgba(0, 0, 0, .26);
    text-overflow: clip;
    cursor: text;
    pointer-events: none;
}

.mui-textfield > label {
    top: 0;
    display: block;
    width: 100%;
    white-space: nowrap;
    font-size: 16px;
}

.is-not-empty {
    padding: 0 !important;
    transform: translate(0, 0) !important;
    top: 4px !important;
    left: 5px;
    font-size: 12px !important;
}

/*.mui-textfield > input:focus + label,*/
/*.mui-textfield > select:focus + label,*/
/*.mui-textfield > input[value]:not([value=""]) + label {*/
/*    position: absolute;*/
/*    top: 0;*/
/*    display: block;*/
/*    width: 100%;*/
/*    color: rgba(0, 0, 0, 0.54);*/
/*    font-size: 12px;*/
/*    font-weight: 400;*/
/*    line-height: 1.5;*/
/*    overflow-x: hidden;*/
/*    text-overflow: ellipsis;*/
/*    white-space: nowrap;*/
/*    transform: translate(0, 0);*/
/*}*/

.mui-textfield--float-label > label {
    transition: all 0.15s ease-out;
    top: 1px !important;
}

/*input focus style*/
.mui-textfield:focus > input, .new-form-container .mui-textfield > input:focus {
    border-color: #828282;
    border-width: 1px;
    height: 37px;
}

.form-control:focus {
    box-shadow: none;
}

.mui-textfield--float-label > input:focus ~ label, .mui-textfield--float-label > textarea:focus ~ label {
    transform: translate(0, 0);
    font-size: 12px;
    line-height: 1.5;
    text-overflow: ellipsis;
}

.new-form-text a {
    color: #000 !important;
    font-family: "Apex New Book", sans-serif;
}

.privacyMask {



    /*background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 71%, rgba(255, 255, 255, 0.14) 75%, rgba(255, 255, 255, 1) 100%);*/
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
    width: 100%;
    height: 140px;
    position: absolute;
    pointer-events: none;
    bottom: 0;
}

.new-form-vehicle-subtitle {
    font-size: 12px;
    margin-bottom: 25px;
    cursor: pointer;
}

.section-header h2 {
    font-size: 35px;
    font-weight: bolder;
    text-transform: uppercase;
    font-family: "proxima_nova_rgbold", sans-serif;
    line-height: 35px;
    width: 80%;
    text-align: center;
    margin: 20px auto;
}

.section-header.title-form {
    text-align: center;
    padding-top: 20px;
    /*padding-bottom: 50px;*/
    position: relative;
}

.page-inner {
    width: 1450px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
}

/*accordiam*/
.new-form-header-number {
    padding-top: 0 !important;
}


@media (max-width: 768px) {
    .w-75 {
        width: 100% !important;
    }

    /*.d-flex {*/
    /*    flex-direction: column !important;*/
    /*    display: grid !important;*/
    /*}*/
}


/*form validation*/
.bb-red {
    border-bottom: 2px solid #FF0000 !important;
}

.border-red {
    border-color: #FF0000;
}

.border-green {
    border-color: #1EB100;
}

.bb-green {
    border-bottom: 2px solid #1EB100 !important;
}

.bb-green > input, .bb-red > input {
    border: 0 !important;
}

.alert {
    background: transparent !important;
    border: none !important;
}

.alert-danger, .alert-success {
    position: absolute;
    right: -15px;
    top: -10px;
    padding-right: 50px;
    font-size: 11px;
}

.alert-success {
    color: #1EB100 !important;
}

.alert-danger {
    color: #FF0000;
}

/*.alert {*/
/*    font-family: Roboto , sans-serif !important;*/
/*}*/
/* abarth css  */
/*@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');*/

@font-face {
    font-family: "icomoon-form";
    src: url("https://staticpromo.abarth.com/form-rationalization/fonts/new-form-icomoon2.ttf?u9p37z");
    src: url("https://staticpromo.abarth.com/form-rationalization/fonts/new-form-icomoon2.eot?u9p37z#iefix") format("embedded-opentype"),
    url("https://staticpromo.abarth.com/form-rationalization/fonts/new-form-icomoon2.ttf?u9p37z") format("truetype"),
    url("https://staticpromo.abarth.com/form-rationalization/fonts/new-form-icomoon2.woff?u9p37z") format("woff"),
    url("https://staticpromo.abarth.com/form-rationalization/fonts/new-form-icomoon2.svg?u9p37z#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Abarth";
    src: url("https://www.abarth.fr/etc.clientlibs/abarth/clientlibs/clientlib-base/resources/fonts/abarth_medium-webfont.woff2") format("woff2");
    src: url("https://www.abarth.fr/etc.clientlibs/abarth/clientlibs/clientlib-base/resources/fonts/abarth_light-webfont.woff2") format("woff2");
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

.Abarth_accordion__1vvPC {
    font-size: 17px;
    margin: 0;

}

.Abarth_accordion__button__3jMGD {
    align-items: center;
    background: black;
    border: 0;
    color: white;
    cursor: pointer;
    display: flex;
    font-size: inherit;
    justify-content: space-between;
    margin: 0;
    padding: 25px 30px;
    width: 100%;
}

.Abarth_accordion__button__3jMGD span {
    height: 30px;
    /*width: 60px;*/
    /* border-radius: 50%; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #000;
    font-size: 15px;
    white-space: nowrap;
}

.Abarth_accordion__button__3jMGD:focus {
    outline: none;
}

.Abarth_accordion__content__3xF9l {
    background: #fff;
    padding: 0 20px;
    opacity: 0;
    transition: all 0.5s;
    height: 0;
    margin: -10px 0 0;
    /*border: 1px solid #f1f1f1;*/
    border-top: 0;
    overflow: hidden;
}

.Abarth_accordion__content__3xF9l.Abarth_active__l6oYw {
    height: auto;
    opacity: 1;
    padding: 20px;
    display: block;
    background: #fff;
}

.Abarth_container__2lX_m {
    min-height: 100vh;
    padding: 0 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.Abarth_main__1LqpD {
    padding: 5rem 0;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Abarth_footer__3v7Qs {
    width: 100%;
    height: 100px;
    border-top: 1px solid #eaeaea;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Abarth_footer__3v7Qs a {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.Abarth_title__1fQxT a {
    color: #0070f3;
    text-decoration: none;
}

.Abarth_title__1fQxT a:hover,
.Abarth_title__1fQxT a:focus,
.Abarth_title__1fQxT a:active {
    text-decoration: underline;
}

.Abarth_title__1fQxT {
    margin: 0;
    line-height: 1.15;
    font-size: 4rem;
}

.Abarth_title__1fQxT,
.Abarth_description__3ZKHm {
    text-align: center;
}

.Abarth_description__3ZKHm {
    line-height: 1.5;
    font-size: 1.5rem;
}

.Abarth_code__mCQcM {
    background: #fafafa;
    border-radius: 5px;
    padding: 0.75rem;
    font-size: 1.1rem;
    font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
}

.Abarth_grid__c8oH6 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 800px;
    margin-top: 3rem;
}

.Abarth_card__1Fc74 {
    margin: 1rem;
    padding: 1.5rem;
    text-align: left;
    color: inherit;
    text-decoration: none;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    transition: color 0.15s ease, border-color 0.15s ease;
    width: 45%;
}

.Abarth_card__1Fc74:hover,
.Abarth_card__1Fc74:focus,
.Abarth_card__1Fc74:active {
    color: #0070f3;
    border-color: #0070f3;
}

.Abarth_card__1Fc74 h2 {
    margin: 0 0 1rem 0;
    font-size: 1.5rem;
}

.Abarth_card__1Fc74 p {
    margin: 0;
    font-size: 1.25rem;
    line-height: 1.5;
}

.Abarth_logo__1wTQy {
    height: 1em;
    margin-left: 0.5rem;
}

@media (max-width: 600px) {
    .Abarth_grid__c8oH6 {
        width: 100%;
        flex-direction: column;
    }
}

.Abarth_new-form-vehicle__2mVT9 {
    text-align: center;
    background: #333333 !important;
    padding: 6px;
    border-radius: 10px;
    margin: 10px;
    cursor: pointer;
}

.Abarth_new-form-vehicle-title__omBVz {
    margin: 0 0 10px 0;
    font-weight: 700;
    cursor: pointer;
    font-family: "Abarth", sans-serif !important;
    color: #ad0c33;
    font-size: 14px;
}

.Abarth_model-name__3df7z {
    font-family: "Abarth", sans-serif !important;
    color: #828282;
}

.Abarth_new-form-step-content-list-item__2gMb-, .Abarth_wrap-car-model__Jjw68 {
    flex-basis: 25%;
    align-self: stretch;
    padding-bottom: 0px;
    margin-bottom: 0px;
    position: relative;
}

.Abarth_title-form__14_mw {
    font-family: "Abarth", sans-serif !important;
    font-size: 16px;
}

.Abarth_radio-check__QaeWz::before {
    font-family: 'icomoon-form';
    content: "\e97d";
    color: #979797;
    font-style: normal;
    background: #333;
    font-weight: 100;
    margin-right: 10px;
    font-size: 20px;
    border-radius: 50%;
}

.Abarth_new-form-img-title__tAs8p {
    font-family: "Abarth", sans-serif !important;
    font-size: 25px;
    font-weight: bold;
    margin-top: 7px;
    margin-bottom: 20px;
    color: #FFFFFF !important;
}


.Abarth_dropdownSelect__289gp {
    border-bottom: solid 2px #828282;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    background: none;
    display: block;
    width: 100%;
    -webkit-appearance: none;
    outline: 0;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
    font-size: 16px;
    font-family: inherit;
    padding: 15px;
}

.Abarth_p-abs__jUB2l {
    padding-bottom: 5px;
}

.Abarth_form-check-input__DufcA:checked {
    border-color: #ff031c !important;
    background-color: #ff031c !important;
}

.Abarth_new-form-submit__1pXKf.Abarth_submit__2FUwm {
    margin-top: 60px;
    text-align: right;
}

div.Abarth_col-xs-12__3ssXy > div > button {
    background-color: #ff031c;
    color: #fff;
    width: 180px;
    text-align: left;
    line-height: 1.6;
    font-family: "Abarth", sans-serif !important;
    font-size: 10px;
    letter-spacing: 0px;
    font-weight: bold;
    border-radius: 5px;
}

div.Abarth_col-xs-12__3ssXy > div > button svg {
    font-size: 16px;
    margin-left: 50px;
}

.Abarth_new-form-link__2Zux0.Abarth_new-form-link-change__1hqRD.Abarth_model__13oN0 > svg {
    color: #ff031c;
}

.Abarth_new-form-link__2Zux0 {
    margin-top: 15px;
    color: #787878;
    display: inline-block;
    font-family: "Abarth", sans-serif !important;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
}

.Abarth_new-form-link__2Zux0:hover {
    text-decoration: none !important;
    color: #000000;
}

.Abarth_form-check-input__DufcA:checked {
    border-color: #ff031c !important;
    background-color: #ff031c !important;
}

.Abarth_new-select-form__2VGR4:after {
    right: 22px !important;
    top: 32px !important;
    top: 32px !important;
}

.Abarth_new-form-step-content-list-flex__2wSsT {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    justify-content: left;
}

.Abarth_img-fluid__23OhM, .Abarth_new-form-vehicle-image__br47X {
    max-width: 100%;
    height: auto;
    margin-bottom: 30px;
    max-height: inherit;
    cursor: pointer;
    width: 100%;
    /*margin-top: 40px;*/
    margin-top: 0;
}

.Abarth_section-header__3UQ-1 {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
    margin: 30px 0 0 0;
}

.Abarth_section-header__3UQ-1 h1 {
    font-family: "Abarth", sans-serif !important;
    font-size: 36px;
    color: #333333;
    font-weight: 400;
}

.Abarth_new-select-form__2VGR4 {
    position: relative;
}

.Abarth_new-form-container__20ohQ * input, .Abarth_new-select-form__2VGR4 select {
    padding-left: 5px !important;
    padding-top: 20px !important;
}

.Abarth_input-container__2Ptox {
    border-bottom: 1px solid #cccccc;
    margin-bottom: 20px;
    position: relative;
}


label[for='gender'], label[for='city'], label[for='firstName'], label[for='lastName'], label[for='email'], label[for='telephone'] {
    position: absolute;

}

label[for='gender'], label[for='city'] {
    top: 15px;
}

label[for='firstName'], label[for='lastName'], label[for='email'], label[for='telephone'] {
    top: -5px !important;
}

.Abarth_is-not-empty__3g48i {
    padding: 0 !important;
    transform: translate(0, 0) !important;
    top: 4px !important;
    left: 5px;
    font-size: 11px !important;
}

.Abarth_new-select-form__2VGR4:after {
    /*content: "\f078";*/
    /*font-family: "FontAwesome" !important;*/
    content: "\e902";
    font-family: 'icomoon-form' !important;
    color: #828282;
    position: absolute;
    right: 15px !important;
    top: 22px !important;
    z-index: 1;
    width: 14px;
    height: 20px;
    pointer-events: none;
    font-size: 10px !important;
}

/*.new-form-container .new-select-form > .dropdownSelect {*/
/*    background: none;*/
/*    height: 38px;*/
/*    width: 100%;*/
/*    -webkit-appearance: none;*/
/*    outline: 0;*/
/*    border-radius: 0;*/
/*    box-shadow: none;*/
/*    cursor: pointer;*/
/*    font-size: 16px;*/
/*    font-family: inherit;*/
/*    line-height: 42px;*/
/*    position: absolute;*/
/*    top: 10px;*/
/*    padding: 0px 30px 0px 10px;*/
/*}*/

.Abarth_showroom-address__1xkhu, .Abarth_showroom-phone__2P_Qq {
    font-family: "Apex New Light", sans-serif !important;
    font-size: 13px;
}

.Abarth_showroom-card__3kDxx {
    color: #333 !important;
    font-family: "Abarth", sans-serif !important;
}

.Abarth_showroom-card__3kDxx .Abarth_showroom-city__eL4zg {
    margin: 0 !important;
}

.Abarth_showroom-city__eL4zg {
    padding-bottom: 0 !important;
}

.Abarth_showroom-title__lkXvC {
    padding-bottom: 0 !important;
}

.Abarth_showroom-card__3kDxx .Abarth_showroom-title__lkXvC {
    margin-top: 25px;
}

.Abarth_showroom-card__3kDxx a {
    color: #000 !important;
    max-width: 150px;
    white-space: nowrap;
    width: 100%;
    margin: 0;
}

.Abarth_showroom-card__3kDxx .Abarth_showroom-address__1xkhu {
    padding-bottom: 10px !important;
}

.Abarth_showroom-card__3kDxx a {
    color: #fff !important;
}

/*showroom*/
.Abarth_showroom-title__lkXvC {
    font-weight: 700 !important;
    min-height: 30px;
    font-size: 16px;
    font-family: "Abarth", sans-serif !important;
    color: #484848 !important;
}

.Abarth_section-header__3UQ-1 {
    font-family: "Abarth", sans-serif !important;
    line-height: 1.2;
}

.Abarth_showroom-address__1xkhu, .Abarth_showroom-phone__2P_Qq {
    font-size: 13px;
    text-transform: capitalize !important;

    line-height: 1.5;
}

.Abarth_formAnagrafic__1RpFn, .Abarth_formAnagrafic__1RpFn label, .Abarth_formAnagrafic__1RpFn input, .Abarth_formAnagrafic__1RpFn select {
    font-family: "Abarth", sans-serif !important;
    font-style: normal;
    font-size: 16px;
    color: #fff;
}

.Abarth_formAnagrafic__1RpFn * input {
    padding-left: 5px !important;
}

.Abarth_formAnagrafic__1RpFn * div {
    font-family: "Abarth", sans-serif !important;
    color: #999999;
}

.Abarth_new-form-text-content__10__o a {
    text-decoration: underline;
    color: #fff !important;
}

.Abarth_new-form-text-title__3ReuD {
    margin-bottom: 20px;
}


.Abarth_rect-btn-red__sPUlk > svg {
    position: absolute;
    right: 10px;
}

.Abarth_showroom-card__3kDxx {
    font-family: "Abarth", sans-serif !important;
}


.Abarth_showroom-address__1xkhu, .Abarth_showroom-phone__2P_Qq {
    font-weight: 600 !important;
    font-family: "Abarth", serif;
}

.Abarth_form-title__AosDH {
    display: block;
}

.Abarth_h1-form-type__wXoC6 {
    font-weight: 700 !important;
    color: #ad0c32;
    font-size: 37px;
    font-family: "Abarth", sans-serif !important;
    display: block;
    margin: 0 auto;
    text-align: left;
}

.Abarth_showroom-city__eL4zg {
    position: relative;
    padding: 0;
    margin: 10px 15px;
    font-family: "Abarth";
}

.Abarth_showroom-city__eL4zg:after {
    content: "";
    width: 34px;
    height: 2px;
    background-color: #ff031c;
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: -5px;
}

/*thank you*/

.Abarth_thx-title__1LIiD {
    color: #777777;
    font-size: 30px !important;
    text-transform: inherit;
    margin-bottom: 40px;
}

.Abarth_answerTitle__3PR84 > h2 {
    text-align: center !important;
}

.Abarth_copy-box__1oCDx h2 {
    text-align: center;
}

.Abarth_new-icon-separator-container__3zPFh {
    height: auto;
    overflow: hidden;
    position: relative;
    padding-top: 10px;
}

.Abarth_new-icon-separator-container__3zPFh .Abarth_separator__2P8Dj {
    width: 60% !important;
    height: 2px;
    background: #ff031c;
    /* margin: auto; */
    top: 35px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
}

.Abarth_thanks-title__1qt8Q {
    color: #777777;
    font-size: 30px !important;
    text-transform: inherit;
    font-family: "Abarth", sans-serif !important;
    font-weight: 700 !important;
    margin-top: 10px;
    text-align: center;
    margin-bottom: 0;
}

.Abarth_new-icon-jeep_icon_quote__1KeP6 {
    font-size: 47px;
    width: 93px;
    margin: auto;
    background: #fff;
    padding: 0px 20px;
    margin-top: 50px;
}

.Abarth_copy-box-ico__2xx9e.Abarth_ico1__OvsDs.Abarth_new-icon-jeep_icon_quote__1KeP6:before {
    content: "\e923";
    color: #ad0c33;
    font-family: 'icomoon-form', sans-serif !important;
}

.Abarth_copy-box-ico__2xx9e.Abarth_ico1__OvsDs, .Abarth_copy-box-ico__2xx9e.Abarth_ico2__3nARV, .Abarth_copy-box-ico__2xx9e.Abarth_ico3__1SEjq {
    padding-bottom: 20px;
}

.Abarth_new-icon-jeep_icon_calendar__3onj- {
    font-size: 47px;
    width: 96px;
    background: #fff;
    margin: 50px auto auto;
    padding: 0px 20px;
}

.Abarth_new-icon-abarth_icon_testdrive__2q8lr:before {
    content: "\e924";
    color: #ad0c33;
}

.Abarth_copy-box-ico__2xx9e.Abarth_ico2__3nARV.Abarth_new-icon-jeep_icon_calendar__3onj-:before {
    content: "\e922";
    color: #ad0c33;
}

.Abarth_new-icon-jeep_icon_testdrive__2QZVk {
    font-size: 47px;
    width: 96px;
    background: #fff;
    margin: 50px auto auto;
    padding: 0px 20px;
}

.Abarth_containerCTATYP__HtKVZ {
    margin-bottom: 20px;
    margin-top: 30px;
}

.Abarth_rect-btn-red__sPUlk {
    background-color: #ff031c;
    color: #fff;
}

.Abarth_rect-btn-grey__1zQZb {
    background: #888888 !important;
    color: #fff;
}

.Abarth_rect-btn-red__sPUlk, .Abarth_rect-btn-grey__1zQZb {
    font-size: 13px;
    font-family: "Abarth", sans-serif !important;
    min-width: 220px;
    height: 40px;
    border-radius: 5px;
    text-align: left;
    padding: 13px 35px;
    margin-top: 40px;
    text-transform: uppercase;
    cursor: pointer;
    max-width: 250px;
    position: relative;

}

.Abarth_rect-btn-red__sPUlk:hover, .Abarth_rect-btn-grey__1zQZb:hover {
    text-decoration: none !important;
    color: #FFFFFF !important;
}

.Abarth_copy-box-ico__2xx9e {
    font-family: 'icomoon-form' !important;
    font-size: 47px;
    margin-top: 50px;
}

.Abarth_copy-box-ico__2xx9e.Abarth_ico3__1SEjq.Abarth_new-icon-jeep_icon_testdrive__2QZVk:before {
    content: "\e91f";
    color: #ad0c33;
}

.Abarth_new-icon-abarth_icon_brochure__1MM6X:before {
    content: "\e91d";
    color: #ad0c33;
}

.Abarth_new-icon-abarth_icon_discovermodels__1zkrn:before {
    content: "\e91f";
    color: #ad0c33;
}

.Abarth_new-icon-abarth_icon_discoverpromotions__Zw3KF:before {
    content: "\e920";
    color: #ad0c33;
}

.Abarth_answerTitle__3PR84 > h2 {
    padding-top: 50px;
    padding-bottom: 20px;
    color: #484848 !important;
}

.Abarth_copy-box__1oCDx {
    text-align: center;
    /*padding-top: 40px;*/
    position: relative;
    font-family: "Abarth", sans-serif !important;
    font-size: 16px;
    color: #000000;
    height: auto;
}

.Abarth_abarth-red-color__3-Zaw {
    color: #ff031c !important;
}

/*abarth PRO STYLE*/
.Abarth_new-form-submit__1pXKf.Abarth_submit__2FUwm.Abarth_btn-red-shadow__3loTm > button {
    background-color: #9C1019;
    box-shadow: 0 2px 17px -5px #333333;
    color: #fff;
    outline: none;
    border-radius: 30px;
    padding: 12px 20px;
    font-weight: 600;
    font-family: "Abarth", sans-serif !important;
    position: relative;
    width: 100%;
    max-width: 200px !important;
    text-align: left;
}

.Abarth_btn-red-shadow__3loTm button:after {
    content: "\e948";
    font-family: 'icomoon-form' !important;
    position: absolute;
    right: 20px;
}

.Abarth_btn-red-shadow__3loTm button:hover:before {
    content: "\e949";
    font-family: 'icomoon-form' !important;
    position: absolute;
    right: 20px;
}

.Abarth_new-form-submit__1pXKf.Abarth_submit__2FUwm.Abarth_btn-red-shadow__3loTm > button:hover {
    background-color: #fff !important;
    color: #9C1019;
    box-shadow: 0 2px 20px -5px #333333;
    border-radius: 30px;
}

/*.rect-btn-red.abarthpro-btn.btn-red-shadow {*/
/*    background-color: #9C1019;*/
/*    box-shadow: 0 2px 17px -5px #333333;*/
/*    color: #fff;*/
/*    outline: none;*/
/*    border-radius: 30px;*/
/*    padding: 12px 20px;*/
/*    font-weight: 600;*/
/*    font-family: Roboto, serif;*/
/*}*/

.Abarth_form-wizard__1dQeZ > h1, .Abarth_page-owner__YpvgA h1 {

    font-weight: bold !important;
    color: #ad0c32;
    font-size: 37px;
    font-family: "Abarth", sans-serif !important;
    display: block;
    margin: 0 auto;
    text-align: left;

}


/*wizard form*/
.Abarth_bs-wizard__18fpb {
    padding: 0;
    position: relative;
}

li:first-child {
    border-color: #AD0C32 !important;
    color: #AD0C32 !important;
}

/*.step-indicator li:nth-child(1) {*/
/* border: 1px solid #ff031c;*/
/*}*/
#Abarth_step1__lttQL .Abarth_step__AkyxX {
    border: 4px solid #ff031c;

    font-family: "Abarth", sans-serif !important;

    text-align: center;

    font-weight: 800;
    font-size: 18px;

}

.Abarth_new-form-container__20ohQ .Abarth_bs-wizard__18fpb > .Abarth_bs-wizard-step__1VM3_:first-child.Abarth_active__l6oYw > .Abarth_progress__2_LAK {
    width: 0%;
}

.Abarth_new-form-container__20ohQ .Abarth_bs-wizard__18fpb > .Abarth_bs-wizard-step__1VM3_:first-child > .Abarth_progress__2_LAK {
    left: 50%;
    width: 50%;
}

.Abarth_new-form-container__20ohQ .Abarth_bs-wizard__18fpb > .Abarth_bs-wizard-step__1VM3_ > .Abarth_progress__2_LAK {
    position: relative;
    border-radius: 0px;
    height: 4px;
    box-shadow: none;
    margin: 26px 0;
}

.Abarth_new-form-container__20ohQ .Abarth_progress__2_LAK {
    overflow: hidden;
    height: 20px;
    margin-bottom: 20px;
    background: none;
    /* background-color: #D8D8D8; */
    border-radius: 4px;
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
}

.Abarth_progress__2_LAK {
    height: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    background-color: #f5f5f5;
    border-radius: 4px;
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
}

.Abarth_new-form-container__20ohQ .Abarth_bs-wizard__18fpb > .Abarth_bs-wizard-step__1VM3_.Abarth_active__l6oYw > .Abarth_progress__2_LAK > .Abarth_progress-bar__4EfED {
    width: 50%;
    transition: none;
}

.Abarth_new-form-container__20ohQ .Abarth_bs-wizard__18fpb > .Abarth_bs-wizard-step__1VM3_ > .Abarth_progress__2_LAK > .Abarth_progress-bar__4EfED {
    width: 0px;
    box-shadow: none;
    background: #AD0C32;
    transition: none;
}

.Abarth_progress-bar__4EfED {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
    transition: width .6s ease;
}

/*font */
.Abarth_fs-small__1um9Z {
    font-size: 11px !important;
    color: #fff;
}

/*WIZARD FORM*/
.Abarth_w-30__3sYU- {
    width: 100%;
    max-width: 768px;
}

@media (max-width: 768px) {
    .Abarth_w-30__3sYU- {
        max-width: 100% !important;
    }
}

.Abarth_step-indicator__3souA {
    border-collapse: separate;
    display: table;
    margin-left: 0;
    position: relative;
    table-layout: fixed;
    text-align: center;
    vertical-align: middle;
    padding-left: 0;
    padding-top: 20px;
    color: #ffffff;
}

.Abarth_step-indicator__3souA li {
    display: table-cell;
    position: relative;
    float: none;
    padding: 0;
    width: 1%;
}


li.Abarth_complete__QhVM7:after {
    background-color: #ccc;
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    width: 100%;
    top: 22px;
}

.Abarth_step-indicator__3souA li:after {
    left: 55%;
}

.Abarth_step-indicator__3souA li:last-child:after {
    display: none;
}

.Abarth_step-indicator__3souA li.Abarth_active__l6oYw .Abarth_step__AkyxX {
    border-color: #AD0C32;
    color: #AD0C32;
}

.Abarth_step-indicator__3souA li.Abarth_active__l6oYw .Abarth_caption__2EJSe {
    color: #AD0C32;
}

.Abarth_step-indicator__3souA li.Abarth_complete__QhVM7:after {
    background-color: #AD0C32;
    height: 3px;
}

.Abarth_step-indicator__3souA li.Abarth_complete__QhVM7 .Abarth_step__AkyxX {
    border-color: #AD0C32;
    color: #AD0C32;
    background: #ff031c !important;
}


.Abarth_step-indicator__3souA li.Abarth_complete__QhVM7 .Abarth_caption__2EJSe {
    color: #AD0C32;
}

.Abarth_step-indicator__3souA .Abarth_step__AkyxX {

    border-radius: 50%;
    border: 2px solid #979797;
    font-size: 20px;
    height: 40px;
    line-height: 40px;
    margin: 0 auto;
    position: relative;
    width: 40px;
    z-index: 1;
    background-color: #333333;
    color: #fff;
}

.Abarth_step-indicator__3souA .Abarth_step__AkyxX:hover {
    cursor: pointer;
}

.Abarth_step-indicator__3souA .Abarth_caption__2EJSe {
    color: #ccc;
    padding: 11px 16px;
}

.Abarth_step-indicator__3souA .Abarth_caption__2EJSe > span {
    color: #FFFFFF !important;
}

.Abarth_complete__QhVM7 > .Abarth_step__AkyxX {
    background-color: #AD0C32;

}


.Abarth_complete__QhVM7 > .Abarth_step__AkyxX > span {
    color: #fff;
}

.Abarth_form-wizard__1dQeZ * span {
    font-family: "Abarth", sans-serif !important;
    font-size: 12px;
    /*color: #0a0a0a;*/
    font-weight: bold;
    text-transform: uppercase;
}

.Abarth_step__AkyxX > span {
    top: -3px;
    position: absolute;
    left: 0;
    right: 0;
    /*font-weight: normal;*/
    font-size: 18px;
    font-weight: 700;
    font-family: "Abarth", sans-serif !important;
}


/*.complete + li > .step , .complete + li:nth-child(2) .step {*/
/*    background-color: #ff031c !important;*/
/*}*/

.Abarth_complete__QhVM7 {
    font-weight: bold;
}

.Abarth_complete__QhVM7 + li > .Abarth_step__AkyxX {
    border: 4px solid #ff031c;
    color: #ff031c;
}

.Abarth_active__l6oYw {
    background: #333333 !important;
}

/*#step1 > div.step {*/
/*    border:4px solid #ff031c !important;*/
/*    background-color: #FFFFFF;*/
/*    color: #ff031c;*/
/*    font-weight: bold;*/

/*}*/
@media (max-width: 768px) {
    .Abarth_new-form-step-content-list-flex__2wSsT {
        flex-direction: column !important;
    }

    .Abarth_formAnagrafic__1RpFn {
        margin-top: 5rem;
    }

    .Abarth_new-form-submit__1pXKf.Abarth_submit__2FUwm {
        text-align: center;
    }

    .Abarth_step-indicator__3souA {
        font-size: .7rem !important;
    }
}

@media (max-width: 999px) {
    .Abarth_new-form-step-content-list-item__2gMb-, .Abarth_wrap-car-model__Jjw68 {
        flex-basis: 32% !important;
    }
}

.Abarth_active-step__hyuEP .Abarth_step__AkyxX {
    background: #333333 !important;
    border: 3px solid #ff031c;
    color: #FFFFFF !important;
    font-weight: bold !important;
}

.Abarth_active-step__hyuEP .Abarth_step__AkyxX .Abarth_text-dark__2Ofss {
    color: #FFFFFF !important;
}

.Abarth_ctive-step__2PsHg .Abarth_step__AkyxX span {
    color: #000 !important;
}

.Abarth_submit-abarth__1gGXi button, .Abarth_submit-abarth__1gGXi a {
    width: 180px;
    text-align: left;
    line-height: 1.6;
    font-family: "Abarth", sans-serif;
    font-size: 10px;
    letter-spacing: 0;
    font-weight: bold;
    border-radius: 5px;
    background-color: #ff031c;
    color: #fff;
    text-transform: uppercase;
    position: relative;
    padding: 12px 20px;
    border: 0;
}

.Abarth_submit-abarth__1gGXi button:hover {
    color: #fff !important;
}

.Abarth_submit-abarth__1gGXi button:after, .Abarth_submit-abarth__1gGXi a:after {
    content: "\e91b";
    font-family: 'icomoon-form' !important;
    font-size: 6px;
    position: absolute;
    right: 20px;
    top: 15px;
    /*padding: 12px;*/
}

input[type="checkbox"] {
    cursor: pointer !important;
}

.Abarth_section-models__HDSE6, .Abarth_section-header__3UQ-1 h2 {
    font-family: "Abarth", sans-serif !important;
    font-size: 22px !important;
    color: #737373;
    letter-spacing: 0;
    text-align: left;
    padding-right: 15px;
    /*padding-left: 15px;*/
    font-weight: 700;
    padding-left: 0;
    outline: 0 !important;
}

/*.section-models, .section-header h2:hover {*/
/*    color: #fff !important;*/
/*}*/

@media (max-width: 760px) {
    .Abarth_form-wizard__1dQeZ {
        width: 100% !important;
        display: block !important;
        margin: 0 auto;
    }
}

.Abarth_abarth-form__238uH * input {
    color:#FFFFFF !important;
}
/*reset all */
*, *:before, *:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/*body {*/
/*    background-color: #212121 !important;*/
/*}*/

/*font familly */

@font-face {
    font-family: "icomoon-form";
    src: url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.ttf?u9p37z");
    src: url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.eot?u9p37z#iefix") format("embedded-opentype"),
    url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.ttf?u9p37z") format("truetype"),
    url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.woff?u9p37z") format("woff"),
    url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.svg?u9p37z#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
}

/*form */
.formAnagrafic {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
}

/*INPUTS */
.mui-textfield {
    position: relative;
}

.mui-textfield, .form-group {
    margin-bottom: 25px !important;
}

.mui-radio .mui-radio-inline .field label {
    display: inline-block;
    height: 20px;
    position: relative;
    padding: 0 30px;
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;
    white-space: nowrap;
    width: 140px;
}

.mui-radio label {
    padding: 0 15px !important;
}

.mui-textfield--float-label > label {
    font-weight: 400;
    font-family: Roboto, serif;
    font-size: 13px !important;
    color: gray !important;
}

.form-control:focus {
    border-color: #828282 !important;
    border-width: 1px !important;
}

.form-check .form-check-input {
    height: 20px;
    width: 20px;
    margin-right: 10px;
    cursor: pointer;
}

.form-check:checked {
    transform: scale(1);
}

.form-check.form-check-inline > div {
    display: flex;
    align-items: center;
}

.form-check {
    padding: 0 20px;
    transform: scale(1);
}

.form-check-input:checked {
    border-color: #ffba00;
    background-color: #ffba00;
}

.form-check-input:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: #828282;
}


/*select input*/
.dropdownSelect {
    border: none;
    border-bottom: solid 1px #828282;
    background: none;
    height: 37px;
    display: block;
    width: 100%;
    -webkit-appearance: none;
    outline: 0;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
    font-size: 16px;
    font-family: inherit;
    line-height: 37px;
    padding: 0px 25px 0px 10px;
}

/*select options */
option {
    top: calc(100% + 2px) !important;
    height: auto !important;
    min-height: 84px;
    max-height: 150px;
    background: #fff;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    position: absolute;
    z-index: 2;
    width: 100%;
    overflow-y: auto;
    font-size: 16px;
    padding: 0 22px;
    line-height: 42px;
    cursor: pointer;
    white-space: nowrap;
    border-radius: 0;
    color:black
}


.new-select-form:after {
    content: "\e902" !important;
    font-family: icomoon-form !important;
    color: #828282;
    position: absolute;
    right: 7px;
    top: 22px;
    z-index: 1;
    width: 14px;
    height: 20px;
    pointer-events: none;
    font-size: 9px;
}

/*input text container*/
.mui-textfield {
    margin-bottom: 25px;
    display: block;
    padding-top: 20px;
    position: relative;
}

.mui-textfield > input {
    padding-left: 10px;
    padding-right: 10px;
    height: 37px !important;
    margin-bottom: 0 !important;
}

.mui-textfield > input, .new-form-container .mui-textfield > textarea {
    border-color: #828282;
}

.mui-textfield > input, .mui-textfield > textarea {
    box-sizing: border-box;
    display: block;
    background-color: transparent;
    color: black;
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, .26);
    outline: 0;
    width: 100%;
    padding: 0;
    box-shadow: none;
    border-radius: 0;
    font-size: 16px;
    font-family: inherit;
    line-height: inherit;
    background-image: none;
    padding-left: 5px;
}

/*label input style*/
.mui-textfield--float-label > label {
    padding-left: 10px;
    color: #828282;
    overflow-x: visible;
    font-size: 14px;
}

.mui-textfield--float-label > label {
    position: absolute;
    transform: translate(0, 15px);
    font-size: 16px;
    line-height: 30px;
    color: rgba(0, 0, 0, .26);
    text-overflow: clip;
    cursor: text;
    pointer-events: none;
}

.mui-textfield > label {
    top: 0;
    display: block;
    width: 100%;
    white-space: nowrap;
    font-size: 16px;
}

.is-not-empty {
    padding: 0 !important;
    transform: translate(0, 0) !important;
    top: 4px !important;
    left: 5px;
    font-size: 12px !important;
}

/*.mui-textfield > input:focus + label,*/
/*.mui-textfield > select:focus + label,*/
/*.mui-textfield > input[value]:not([value=""]) + label {*/
/*    position: absolute;*/
/*    top: 0;*/
/*    display: block;*/
/*    width: 100%;*/
/*    color: rgba(0, 0, 0, 0.54);*/
/*    font-size: 12px;*/
/*    font-weight: 400;*/
/*    line-height: 1.5;*/
/*    overflow-x: hidden;*/
/*    text-overflow: ellipsis;*/
/*    white-space: nowrap;*/
/*    transform: translate(0, 0);*/
/*}*/

.mui-textfield--float-label > label {
    transition: all 0.15s ease-out;
    top: 1px !important;
}

/*input focus style*/
.mui-textfield:focus > input, .new-form-container .mui-textfield > input:focus {
    border-color: #828282;
    border-width: 1px;
    height: 37px;
}

.form-control:focus {
    box-shadow: none;
}

.mui-textfield--float-label > input:focus ~ label, .mui-textfield--float-label > textarea:focus ~ label {
    transform: translate(0, 0);
    font-size: 12px;
    line-height: 1.5;
    text-overflow: ellipsis;
}

.new-form-text a {
    color: #999999 !important;
    font-family: "Apex New Book", sans-serif;
}

.privacyMask {



    /*background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 71%, rgba(255, 255, 255, 0.14) 75%, rgba(255, 255, 255, 1) 100%);*/
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
    width: 100%;
    height: 140px;
    position: absolute;
    pointer-events: none;
    bottom: 0;
}

.new-form-vehicle-subtitle {
    font-size: 12px;
    margin-bottom: 25px;
    cursor: pointer;
}

.section-header h2 {
    font-size: 35px;
    font-weight: bolder;
    text-transform: uppercase;
    font-family: "proxima_nova_rgbold", sans-serif;
    line-height: 35px;
    width: 80%;
    text-align: center;
    margin: 20px auto;
}

.section-header.title-form {
    text-align: center;
    padding-top: 20px;
    /*padding-bottom: 50px;*/
    position: relative;
}

.page-inner {
    width: 1450px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
}

/*accordiam*/
.new-form-header-number {
    padding-top: 0 !important;
}


@media (max-width: 768px) {
    .w-75 {
        width: 100% !important;
    }

    /*.d-flex {*/
    /*    flex-direction: column !important;*/
    /*    display: grid !important;*/
    /*}*/
}



