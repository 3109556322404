/*reset all */
*, *:before, *:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/*body {*/
/*    background-color: #212121 !important;*/
/*}*/

/*font familly */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@font-face {
    font-family: "icomoon-form";
    src: url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.ttf?u9p37z");
    src: url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.eot?u9p37z#iefix") format("embedded-opentype"),
    url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.ttf?u9p37z") format("truetype"),
    url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.woff?u9p37z") format("woff"),
    url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.svg?u9p37z#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
}

/*form */
.formAnagrafic {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
}

/*INPUTS */
.mui-textfield {
    position: relative;
}

.mui-textfield, .form-group {
    margin-bottom: 25px !important;
}

.mui-radio .mui-radio-inline .field label {
    display: inline-block;
    height: 20px;
    position: relative;
    padding: 0 30px;
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;
    white-space: nowrap;
    width: 140px;
}

.mui-radio label {
    padding: 0 15px !important;
}

.mui-textfield--float-label > label {
    font-weight: 400;
    font-family: Roboto, serif;
    font-size: 13px !important;
    color: gray !important;
}

.form-control:focus {
    border-color: #828282 !important;
    border-width: 1px !important;
}

.form-check .form-check-input {
    height: 20px;
    width: 20px;
    margin-right: 10px;
    cursor: pointer;
}

.form-check:checked {
    transform: scale(1);
}

.form-check.form-check-inline > div {
    display: flex;
    align-items: center;
}

.form-check {
    padding: 0 20px;
    transform: scale(1);
}

.form-check-input:checked {
    border-color: #ffba00;
    background-color: #ffba00;
}

.form-check-input:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: #828282;
}


/*select input*/
.dropdownSelect {
    border: none;
    border-bottom: solid 1px #828282;
    background: none;
    height: 37px;
    display: block;
    width: 100%;
    -webkit-appearance: none;
    outline: 0;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
    font-size: 16px;
    font-family: inherit;
    line-height: 37px;
    padding: 0px 25px 0px 10px;
}

/*select options */
option {
    top: calc(100% + 2px) !important;
    height: auto !important;
    min-height: 84px;
    max-height: 150px;
    background: #fff;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    position: absolute;
    z-index: 2;
    width: 100%;
    overflow-y: auto;
    font-size: 16px;
    padding: 0 22px;
    line-height: 42px;
    cursor: pointer;
    white-space: nowrap;
    border-radius: 0;
    color:black
}


.new-select-form:after {
    content: "\e902" !important;
    font-family: icomoon-form !important;
    color: #828282;
    position: absolute;
    right: 7px;
    top: 22px;
    z-index: 1;
    width: 14px;
    height: 20px;
    pointer-events: none;
    font-size: 9px;
}

/*input text container*/
.mui-textfield {
    margin-bottom: 25px;
    display: block;
    padding-top: 20px;
    position: relative;
}

.mui-textfield > input {
    padding-left: 10px;
    padding-right: 10px;
    height: 37px !important;
    margin-bottom: 0 !important;
}

.mui-textfield > input, .new-form-container .mui-textfield > textarea {
    border-color: #828282;
}

.mui-textfield > input, .mui-textfield > textarea {
    box-sizing: border-box;
    display: block;
    background-color: transparent;
    color: black;
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, .26);
    outline: 0;
    width: 100%;
    padding: 0;
    box-shadow: none;
    border-radius: 0;
    font-size: 16px;
    font-family: inherit;
    line-height: inherit;
    background-image: none;
    padding-left: 5px;
}

/*label input style*/
.mui-textfield--float-label > label {
    padding-left: 10px;
    color: #828282;
    overflow-x: visible;
    font-size: 14px;
}

.mui-textfield--float-label > label {
    position: absolute;
    transform: translate(0, 15px);
    font-size: 16px;
    line-height: 30px;
    color: rgba(0, 0, 0, .26);
    text-overflow: clip;
    cursor: text;
    pointer-events: none;
}

.mui-textfield > label {
    top: 0;
    display: block;
    width: 100%;
    white-space: nowrap;
    font-size: 16px;
}

.is-not-empty {
    padding: 0 !important;
    transform: translate(0, 0) !important;
    top: 4px !important;
    left: 5px;
    font-size: 12px !important;
}

/*.mui-textfield > input:focus + label,*/
/*.mui-textfield > select:focus + label,*/
/*.mui-textfield > input[value]:not([value=""]) + label {*/
/*    position: absolute;*/
/*    top: 0;*/
/*    display: block;*/
/*    width: 100%;*/
/*    color: rgba(0, 0, 0, 0.54);*/
/*    font-size: 12px;*/
/*    font-weight: 400;*/
/*    line-height: 1.5;*/
/*    overflow-x: hidden;*/
/*    text-overflow: ellipsis;*/
/*    white-space: nowrap;*/
/*    transform: translate(0, 0);*/
/*}*/

.mui-textfield--float-label > label {
    -webkit-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    -o-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
    top: 1px !important;
}

/*input focus style*/
.mui-textfield:focus > input, .new-form-container .mui-textfield > input:focus {
    border-color: #828282;
    border-width: 1px;
    height: 37px;
}

.form-control:focus {
    box-shadow: none;
}

.mui-textfield--float-label > input:focus ~ label, .mui-textfield--float-label > textarea:focus ~ label {
    transform: translate(0, 0);
    font-size: 12px;
    line-height: 1.5;
    text-overflow: ellipsis;
}

.new-form-text a {
    color: #999999 !important;
    font-family: "Apex New Book", sans-serif;
}

.privacyMask {



    /*background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 71%, rgba(255, 255, 255, 0.14) 75%, rgba(255, 255, 255, 1) 100%);*/
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
    width: 100%;
    height: 140px;
    position: absolute;
    pointer-events: none;
    bottom: 0;
}

.new-form-vehicle-subtitle {
    font-size: 12px;
    margin-bottom: 25px;
    cursor: pointer;
}

.section-header h2 {
    font-size: 35px;
    font-weight: bolder;
    text-transform: uppercase;
    font-family: "proxima_nova_rgbold", sans-serif;
    line-height: 35px;
    width: 80%;
    text-align: center;
    margin: 20px auto;
}

.section-header.title-form {
    text-align: center;
    padding-top: 20px;
    /*padding-bottom: 50px;*/
    position: relative;
}

.page-inner {
    width: 1450px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
}

/*accordiam*/
.new-form-header-number {
    padding-top: 0 !important;
}


@media (max-width: 768px) {
    .w-75 {
        width: 100% !important;
    }

    /*.d-flex {*/
    /*    flex-direction: column !important;*/
    /*    display: grid !important;*/
    /*}*/
}

