/* jeep css  */

/*@import url("https://www.jeep.fr/etc.clientlibs/jeep/clientlibs/clientlib-base.min.css");*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");


@font-face {
    font-family: "icomoon-form";
    src: url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.ttf?u9p37z");
    src: url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.eot?u9p37z#iefix") format("embedded-opentype"),
    url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.ttf?u9p37z") format("truetype"),
    url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.woff?u9p37z") format("woff"),
    url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.svg?u9p37z#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'proxima_nova_semibold';
    src: url("https://www.jeep.fr/etc.clientlibs/jeep/clientlibs/clientlib-base/resources/assets/fonts/ProximaNova-Semibold.eot");
    src: url("https://www.jeep.fr/etc.clientlibs/jeep/clientlibs/clientlib-base/resources/assets/fonts/ProximaNova-Semibold.eot?#iefix") format("embedded-opentype"), url("https://www.jeep.fr/etc.clientlibs/jeep/clientlibs/clientlib-base/resources/assets/fonts/ProximaNova-Semibold.woff") format("woff"), url("https://www.jeep.fr/etc.clientlibs/jeep/clientlibs/clientlib-base/resources/assets/fonts/ProximaNova-Semibold.ttf") format("truetype"), url("https://www.jeep.fr/etc.clientlibs/jeep/clientlibs/clientlib-base/resources/assets/fonts/ProximaNova-Semibold.svg#proxima_novasemibold") format("svg");
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'proxima_nova_rgbold';
    src: url("https://www.jeep.fr/etc.clientlibs/jeep/clientlibs/clientlib-base/resources/assets/fonts/ProximaNova-Bold.eot");
    src: url("https://www.jeep.fr/etc.clientlibs/jeep/clientlibs/clientlib-base/resources/assets/fonts/ProximaNova-Bold.eot?#iefix") format("embedded-opentype"), url("https://www.jeep.fr/etc.clientlibs/jeep/clientlibs/clientlib-base/resources/assets/fonts/ProximaNova-Bold.woff") format("woff"), url("https://www.jeep.fr/etc.clientlibs/jeep/clientlibs/clientlib-base/resources/assets/fonts/ProximaNova-Bold.ttf") format("truetype"), url("https://www.jeep.fr/etc.clientlibs/jeep/clientlibs/clientlib-base/resources/assets/fonts/ProximaNova-Bold.svg#robotoregular") format("svg");
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'proxima_nova_rgregular';
    src: url("https://www.jeep.fr/etc.clientlibs/jeep/clientlibs/clientlib-base/resources/assets/fonts/ProximaNova-Reg.eot");
    src: url("https://www.jeep.fr/etc.clientlibs/jeep/clientlibs/clientlib-base/resources/assets/fonts/ProximaNova-Reg.eot?#iefix") format("embedded-opentype"), url("https://www.jeep.fr/etc.clientlibs/jeep/clientlibs/clientlib-base/resources/assets/fonts/ProximaNova-Reg.woff") format("woff"), url("https://www.jeep.fr/etc.clientlibs/jeep/clientlibs/clientlib-base/resources/assets/fonts/ProximaNova-Reg.ttf") format("truetype"), url("https://www.jeep.fr/etc.clientlibs/jeep/clientlibs/clientlib-base/resources/assets/fonts/ProximaNova-Reg.svg#robotoregular") format("svg");
    font-weight: normal;
    font-style: normal
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

.accordion {
    font-size: 17px;
    margin: 0;
}

.accordion__button {
    align-items: center;
    background: black;
    border: 0;
    color: white;
    cursor: pointer;
    display: flex;
    font-size: inherit;
    justify-content: space-between;
    margin: 0;
    padding: 25px 30px;
    width: 100%;
    font-family: 'Roboto', sans-serif !important;
}

.accordion__button span {
    height: 30px;
    /*width: 60px;*/
    /* border-radius: 50%; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #000;
    font-size: 15px;
    white-space: nowrap;
}

.accordion__button:focus {
    outline: none;
}

.accordion__content {
    background: #fff;
    padding: 0 20px;
    opacity: 0;
    transition: all 0.5s;
    height: 0;
    margin: 0;
    margin-top: -10px;
    border: 1px solid #f1f1f1;
    border-top: 0;
    overflow: hidden;
}

.accordion__content.active {
    height: auto;
    opacity: 1;
    padding: 20px;
    display: block;
    background: #fff;
}

.section-header {
    color: #222222;
    padding-bottom: 40px;
    padding-top: 40px;
    position: relative;
    text-align: center;
    font-family: "proxima_nova_rgbold", sans-serif !important;
}

/* .new-form-header-title {
  border-bottom: 4px solid #ffba00;
} */
.new-form-header-title {
    font-size: 14px;
    font-weight: 700;
    position: relative;
    top: -7px;
    border-bottom: 4px solid #bdbdbd;
    padding-bottom: 2px;
}

#essai-bg {
    background: url("https://www.jeep.fr/content/dam/jeep/crossmarket/hero/jeep-Ecommerce-1600X505-V4.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    max-height: 600px;
    min-height: 600px;
}

.subtitle {
    font-size: 35px !important;
    font-family: "proxima_nova_rgbold", sans-serif !important;
    font-weight: bold;
    line-height: 1.1;
    margin-bottom: 10px;
    text-align: center;
    color: #fff;
    padding-top: 5rem;
}

.new-form-vehicle-image {
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    color: #222;
    text-align: center;
    box-sizing: border-box;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline !important;
    border: 0 none !important;
    margin: 0 0 30px !important;
    max-height: 130px !important;
    cursor: pointer;
}

.card {
    border: 0;
}

.color-yellow {
    margin-left: 10px;
    color: #ffba00;
    position: relative;
    font-size: 12px;
}

.new-form-vehicle-link,
.step-cars {
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    text-align: center;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    background-color: transparent;
    color: black;
    font-weight: 700;
    font-size: 16px;
    font-family: 'Roboto', sans-serif !important;
    text-decoration: none;
    cursor: pointer;
}

.new-form-vehicle-link,
.step-cars:hover {
    padding: 0 !important;
    text-decoration: none !important;
    color: #000 !important;
}


.accordion__button svg, .new-icon-jeep_arrow_cta {
    color: #ffba00 !important;
}

.accordion__content .active + .accordion__button {
    color: #ffba00 !important;
}


.new-icon-jeep_arrow_cta {
    margin-left: 10px;
    color: #ffba00 !important;
    position: relative;
    font-size: 12px;
}

.new-form-step-content-list-flex {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    justify-content: left;
}

.new-form-step-content-list-item .wrap-car-model {
    flex-basis: 25%;
    align-self: stretch;
    padding-bottom: 40px;
    margin-bottom: 50px;
    position: relative;
}

.new-form-vehicle {
    padding: 0 10px;
    text-align: center;
}

.new-form-vehicle-title {
    margin: 0 0 10px 0;
    font-weight: 700;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Roboto', sans-serif !important;
}

.new-form-vehicle-subtitle {
    font-family: 'Roboto', sans-serif !important;
    font-size: 12px;
    margin-bottom: 25px;
    cursor: pointer;
}

.new-form-step-content-list-item .wrap-car-model:after {
    content: " ";
    border-bottom: 1px solid #e7e7e7;
    width: calc(100% - 20px);
    position: absolute;
    height: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.new-form-step-content-list-flex {
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    color: #222;
    box-sizing: border-box;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    justify-content: left;
}

.new-form-step-content-list-item,
.wrap-car-model {
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    color: #222;
    box-sizing: border-box;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    flex-basis: 25%;
    align-self: stretch;
    padding: 0 0 40px;
    margin: 0 0 50px;
    position: relative;
}

.new-form-vehicle {
    padding: 0 10px;
    text-align: center;
}

.wrap-car-model::after {
    width: calc(100% - 50px);
    content: " ";
    border-bottom: 1px solid #e7e7e7;
    position: absolute;
    height: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.new-form-step-content-list {
    margin-top: 40px;
}

.new-form-vehicle-title {
    margin: 0 0 10px 0;
    font-weight: 700;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Roboto', sans-serif !important;
}

.req-fields {
    font-family: 'Roboto', sans-serif !important;
    font-size: 13px !important;
}

.new-form-text-title, .new-form-text-content {
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    color: #222;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    font-family: 'Roboto', sans-serif !important;
}

.new-form-text-content {
    font-family: 'Roboto', sans-serif !important;
}

.title-form {
    font-family: 'Roboto', sans-serif !important;
    font-size: 16px;
}

.formAnagrafic * input, .formAnagrafic * label, .formAnagrafic * select {
    font-family: Roboto, serif !important;
    font-style: normal;
    font-size: 16px;
}

.formAnagrafic * div {
    font-family: 'Roboto', sans-serif !important;
}

.fm-jeep {
    font-family: Roboto, serif !important;
    font-size: 14px !important;
}

.acceptedMarketingActivity + label {
    font-family: Roboto, serif !important;
}

.p-abs {
    font-family: Roboto, sans-serif;
    padding-left: 10px;
    color: #828282;
    overflow-x: visible;
    font-size: 14px !important;
    font-weight: 400;
}

.contFormAnagraphic {
    height: auto;
    overflow: hidden;
    padding-bottom: 50px;
    padding-top: 15px;
}

.dropdownSelect {
    border: none;
    border-bottom: solid 1px #828282;
    background: none;
    height: 45px;
    display: block;
    width: 100%;
    -webkit-appearance: none;
    outline: 0;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
    font-size: 16px;
    font-family: inherit;
    line-height: 37px;
    padding: 0 25px 0 10px;
}

option:hover {
    background-color: #ffba00 !important;
}

@media only screen and (max-width: 999px) {
    .wrap-car-model {
        flex-basis: 50%;
    }
}

@media only screen and (max-width: 767px) {
    .wrap-car-model {
        flex-basis: 100%;
    }
}

/*FORM  */
.section-anagraphic,
.title-form {
    font-size: 16px;
    font-family: Roboto;
}

.section-anagraphic,
.new-form-img-title {
    font-family: 'Roboto', sans-serif !important;
    font-size: 25px;
    font-weight: bold;
    margin-top: 7px;
    margin-bottom: 20px;
}

.dropdownSelect > option:first-child {
    height: 0 !important;
}

/*.p-abs {*/
/*    position: absolute;*/
/*    bottom: 30px;*/
/*}*/

/*option:checked, .p-abs {*/
/*    bottom: 50px;*/
/*}*/
.new-select-form {
    position: relative;
}

.new-select-form * label[for='gender'], .new-select-form * label[for='city'] {
    top: -2px !important;
}

.new-select-form:after {
    /*content: "\f078";*/
    /*font-family: "FontAwesome" !important;*/
    content: "\e902";
    font-family: icomoon-form !important;
    color: #828282;
    position: absolute;
    right: 7px;
    top: 22px;
    z-index: 1;
    width: 14px;
    height: 20px;
    pointer-events: none;
    font-size: 9px;
}

.new-form-link,
.new-form-link-change,
.model {
    margin-top: 15px;
    color: black;
    display: inline-block;
    font-family: 'Roboto', sans-serif !important;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
}

.new-icon-jeep_arrow_changemodel {
    font-size: 13px;
    margin-right: 4px;
    color: #ffba01;
}

.new-icon-jeep_icon_brochure:before {
    content: "\e903";
    color: #ffba00;

}

.copy-box-ico {
    font-family: 'icomoon-form' !important;
    font-size: 47px;
}

.new-icon-jeep_icon_discoverpromotions:before {
    content: "\e907";
    color: #ffb800;
}

.new-icon-jeep_icon_discovermodels:before {
    content: "\e906";
    color: #ffb900;
}

.formAnagrafic,
.mui-form {
    font-size: 14px;
}

.mui-textfield > input {
    padding-left: 10px;
    padding-right: 10px;
    height: 37px !important;
    margin-bottom: 0 !important;
}

.mui-textfield > input:focus + label,
.mui-textfield > select:focus + label,
.mui-textfield > input[value]:not([value=""]) + label {
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transform: translate(0, 0);
}

.mui-textfield > input:focus {
    border-color: #828282;
    border-width: 1px;
}

.mui-textfield--float-label > label {
    -webkit-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    -o-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}

.mui-radio .mui-radio-inline .field label {
    display: inline-block;
    height: 20px;
    position: relative;
    padding: 0 30px;
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;
    white-space: nowrap;
    width: 140px;
}


.mui-radio label {
    padding: 0 15px !important;
}

.form-check-input {
    border: 1px solid #828282 !important;
    box-shadow: 0 0 0 1px #828282;
    appearance: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: #fff;
    transition: all ease-in 0.2s;
    cursor: pointer;
}

.form-check-input:checked {
    border-color: #ffba00 !important;
    background-color: #ffba00 !important;
}

.form-check-input:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: #828282;
}

.form-check,
.form-check-inline,
.form-check-label {
    margin-left: 10px;
}

.section-anagraphic,
.new-form-text {
    margin: 40px 0 10px 0;
    line-height: 1.42857;
}

.new-form-text a {
    color: black;
    text-decoration: underline;
    font-size: 14px;
}

.jeep-btn button {
    background-color: #ffba00;
    color: black;
    font-family: Roboto, serif !important;
    width: 144px;
    cursor: pointer;
    padding: 12px 20px;
    border-radius: 2px;
    font-weight: bold;
    font-size: 15px;
    display: inline-block;
    margin-right: 10px;
    text-transform: uppercase;
    border: 0;
    -webkit-appearance: button;
    white-space: pre-wrap;
}

.jeep-btn button svg {
    margin-left: 10px;
}

.accordion > .accordion__button > .accordion__content > .active {
    display: none;
}

.new-form-linktext {
    margin-left: 5px;
    text-decoration: underline !important;
    cursor: pointer !important;
}

.new-form-link,
.new-form-link-change,
.model,
.new-form-link,
.new-form-link-change,
.model:hover {
    text-decoration: none !important;
    color: black;
}

.bb-yellow {
    border-bottom: 4px solid #ffba00;
}

.new-form-header-number {
    display: inline-block;
    height: 35px !important;
    width: 35px !important;
    background: #bdbdbd !important;
    font-size: 25px !important;
    font-weight: 700;
    color: #636363 !important;
    border-radius: 50% !important;
    padding-left: -3.5px !important;
    padding-top: 2px !important;
    margin-right: 15px !important;
    position: relative !important;
}

.new-form-header-number svg {
    color: #fff !important;
}

.new-form-header-number.bg-yellow {
    background: #ffba00 !important;
}

.bg-yellow {
    color: #000 !important;
}

.rect-btn-yellow {
    background-color: #ffba00;
    color: black;
    text-decoration: none !important;
    padding: 8px 10px;
    margin-right: 5px;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 2px;
    font-weight: bold;
    font-size: 14px;
    display: inline-block;
    text-transform: uppercase;
    border: 0;
    white-space: nowrap;
}

/* .rect-btn-yellow::after {
    content: "\f054";
    font-family:"FontAwesome" ;
    margin-right: 20px;
    
} */
.showroom-card {
    color: #333 !important;
    font-family: 'Roboto', sans-serif !important;
}

.showroom-card .showroom-city {
    margin: 0 !important;
}

.showroom-card .showroom-title {
    margin-top: 25px;
}

.showroom-card a {
    color: #000 !important;
    max-width: 150px;
    white-space: nowrap;
    width: 100%;
    margin: 0;
}

.showroom-card .showroom-address {
    padding-bottom: 10px !important;
}

.showroom-card a {
    margin-top: 20px;
}

/*.showroom-title:after {*/
/*    content: "";*/
/*    width: 34px;*/
/*    height: 2px;*/
/*    background-color: #ad0c33;*/
/*    display: inline-block;*/
/*    position: absolute;*/
/*    left: 0;*/
/*    bottom: -5px;*/
/*}*/


.rect-btn-yellow svg {
    margin-left: 20px;
}

.rect-btn-yellow:hover {
    color: #000 !important;
}

.showroom-title {
    font-weight: 800;
    min-height: 30px;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.section-header h2, .section-header h1 {
    font-size: 35px;
    text-transform: uppercase;
    max-width: 80%;
    margin: 0 auto 40px;
    font-family: "proxima_nova_rgbold", sans-serif;
    font-weight: bold;
}

.form-title {
    background: #000 !important;
    /*margin-bottom: 30px;*/
}

.form-title h1 {
    font-size: 34px;
    font-weight: bold;
    padding: 2rem 0;
    font-family: "proxima_nova_rgbold", sans-serif !important;
    color: #fff;
    text-transform: uppercase;
    margin: 0 auto;
    text-align: center;
}

.section-header h4 {
    line-height: 1.2em;
    width: 100%;
    padding: 0 20px;
    max-width: 672px;
    font-size: 14px;
    margin: 20px auto 0;
    font-weight: 400;
    font-family: 'Roboto', sans-serif !important;
}

.copy-box, .answerTitle {
    padding-top: 0;
    padding-bottom: 20px;
    text-transform: capitalize;
}

.copy-box, .answerTitle h2 {
    font-size: 25px;
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-family: "proxima_nova_rgbold", sans-serif !important;
}

.copy-box-ico .ico1 .new-icon-jeep_icon_quote {
    font-size: 47px;
    width: 93px;
    margin: auto;
    background: #fff;
    padding: 0px 20px;
}

[class^="new-icon-"], [class*=" new-icon-"] {
    font-family: 'icomoon-form' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.new-icon-jeep_icon_quote:before {
    content: "\e90a";
    color: #ffb900;
}

.new-icon-separator-container {
    height: auto;
    overflow: hidden;
    position: relative;
    padding-top: 10px;
    max-width: inherit !important;
}

.new-icon-jeep_icon_quote, .new-icon-jeep_icon_calendar, .new-icon-jeep_icon_testdrive {
    font-size: 47px !important;
    width: 93px !important;
    margin: auto !important;
    background: #fff !important;
    padding: 0px 20px !important;
}

.new-icon-separator-container > .separator {
    width: 60%;
    height: 2px;
    background: #ffba00;
    /* margin: auto; */
    top: 35px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 768px) {
    .col-sm-4 {
        width: 33.33333333% !important;
    }
}

.new-icon-jeep_icon_calendar:before {
    content: "\e904";
    color: #ffba00;
}

.new-icon-jeep_icon_testdrive:before {
    content: "\e90b";
    color: #fbba00;
}

.copy-box {
    text-align: center !important;
    padding-top: 40px;
    position: relative !important;
    font-family: 'Roboto', sans-serif !important;
    font-size: 16px;
    color: #000000 !important;
    height: auto !important;
    min-height: 130px !important;
    line-height: 16px;
    font-weight: 400 !important;

}

.containerCTATYP > a:hover {
    color: inherit !important;
    cursor: pointer;
}

.rect-btn-yellow, .rect-btn-grey {
    position: relative;
    font-size: 16px;
    text-align: left;
    font-family: "proxima_nova_rgbold", sans-serif !important;
    width: auto !important;
    max-width: 230px !important;
    min-width: 200px !important;
    padding: 12px 15px;
    margin-top: 30px;
    border: 0 !important;
    text-transform: uppercase;
    cursor: pointer;
}

.rect-btn-yellow:hover, .rect-btn-grey:hover {
    text-decoration: none !important;
    color: inherit !important;
}

.rect-btn-yellow {
    background-color: #ffba00;
    color: black;
}

.rect-btn-grey {
    background-color: #888888 !important;
    color: #fff !important;
}

.rect-btn-yellow * svg {
    position: absolute;
    right: 10px;
}


.containerCTATYP > a > svg {
    margin-left: 65px;
}

.new-form-submit .submit-jeep {
    margin-top: 30px;
}

.submit-jeep {
    position: relative;
    /*max-width: 280px;*/
    width: 100% !important;
}

.submit-jeep > button, .submit-jeep a {
    color: black;
    font-family: Roboto, serif !important;
    font-weight: 700;
    max-width: 200px !important;
    min-width: 200px !important;
    width: 100%;
    background-color: #edad01;
    cursor: pointer;
    padding: 12px 20px;
    border-radius: 2px;
    font-size: 15px;
    display: inline-block;
    margin-right: 10px;
    text-transform: uppercase;
    border: 0;
    -webkit-appearance: button;
    white-space: pre-line;
    position: relative;
    text-align: left;

}

.submit-jeep button:after, .submit-jeep a:after {
    content: "\e901";
    font-family: 'icomoon-form' !important;
    position: absolute;
    right: 15px;
}

.submit-jeep > button:focus, .submit-jeep > a:focus {
    outline: 0 !important;
    box-shadow: none !important;
}

.bg-yellow span::before {
    border-radius: 50%;
    position: absolute;
    height: 35px;
    width: 35px;
    top: 0;
    left: 0;
    background: #ffba00;
    color: white;
}

.new-form-vehicle {
    margin-top: 40px !important;
}


.page-inner {
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    width: 1450px;
}

@media only screen and (max-width: 1599px) {
    .page-inner {
        width: 100%;
        max-width: 1450px;
    }
}

.copy-box-ico .ico1 .new-icon-jeep_icon_quote {
    font-size: 47px;
    width: 93px;
    margin: auto;
    background: #fff;
    padding: 0px 20px;
}

.new-form-container .thank-you-page .thank-you-page-buttons, .new-form-container .thank-you-section .thank-you-page-buttons {
    max-width: 1100px;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 40px;
    height: auto;
    overflow: hidden;
    position: relative;
    padding-top: 10px;
}

.thank-you-section .new-icon-jeep_icon_quote {
    font-size: 47px;
    width: 93px;
    margin: auto;
    background: #fff;
    padding: 0px 20px;

}

.new-icon-jeep_icon_quote, .new-icon-jeep_icon_calendar, .new-icon-jeep_icon_testdrive {
    font-family: 'icomoon-form' !important;
    speak: none !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    line-height: 1 !important;
    -webkit-font-smoothing: antialiased !important;
}

.copy-box {
    text-transform: inherit;
}

.showroom-city {
    position: relative;
    padding: 0 !important;
    margin: 1rem;
    font-family: 'Roboto', sans-serif;

}

.showroom-city:after {
    content: "";
    width: 34px;
    height: 2px;
    background-color: #ffba00;
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: -3px;
}

.showroom-address {
    font-size: 14px;
    font-weight: 400 !important;
    text-transform: lowercase !important;
}


@media (max-width: 768px) {
    .new-form-step-content-list-flex {
        flex-direction: column !important;
    }

    .formAnagrafic {
        margin-top: 5rem;
    }

    .new-form-submit.submit {
        text-align: center;
    }

    .step-indicator {
        font-size: .7rem !important;
    }

    .section-header h2, .section-header h1 {
        font-size: 25px;
        max-width: 100%;
    }
}
