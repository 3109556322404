/* abarth css  */
@import url('https://use.fontawesome.com/releases/v5.15.3/css/all.css');
/*@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');*/

@font-face {
    font-family: "icomoon-form";
    src: url("https://staticpromo.abarth.com/form-rationalization/fonts/new-form-icomoon2.ttf?u9p37z");
    src: url("https://staticpromo.abarth.com/form-rationalization/fonts/new-form-icomoon2.eot?u9p37z#iefix") format("embedded-opentype"),
    url("https://staticpromo.abarth.com/form-rationalization/fonts/new-form-icomoon2.ttf?u9p37z") format("truetype"),
    url("https://staticpromo.abarth.com/form-rationalization/fonts/new-form-icomoon2.woff?u9p37z") format("woff"),
    url("https://staticpromo.abarth.com/form-rationalization/fonts/new-form-icomoon2.svg?u9p37z#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Abarth";
    src: url("https://www.abarth.fr/etc.clientlibs/abarth/clientlibs/clientlib-base/resources/fonts/abarth_medium-webfont.woff2") format("woff2");
    src: url("https://www.abarth.fr/etc.clientlibs/abarth/clientlibs/clientlib-base/resources/fonts/abarth_light-webfont.woff2") format("woff2");
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

.accordion {
    font-size: 17px;
    margin: 0;

}

.accordion__button {
    align-items: center;
    background: black;
    border: 0;
    color: white;
    cursor: pointer;
    display: flex;
    font-size: inherit;
    justify-content: space-between;
    margin: 0;
    padding: 25px 30px;
    width: 100%;
}

.accordion__button span {
    height: 30px;
    /*width: 60px;*/
    /* border-radius: 50%; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #000;
    font-size: 15px;
    white-space: nowrap;
}

.accordion__button:focus {
    outline: none;
}

.accordion__content {
    background: #fff;
    padding: 0 20px;
    opacity: 0;
    transition: all 0.5s;
    height: 0;
    margin: -10px 0 0;
    /*border: 1px solid #f1f1f1;*/
    border-top: 0;
    overflow: hidden;
}

.accordion__content.active {
    height: auto;
    opacity: 1;
    padding: 20px;
    display: block;
    background: #fff;
}

.container {
    min-height: 100vh;
    padding: 0 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.main {
    padding: 5rem 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer {
    width: 100%;
    height: 100px;
    border-top: 1px solid #eaeaea;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer a {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.title a {
    color: #0070f3;
    text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
    text-decoration: underline;
}

.title {
    margin: 0;
    line-height: 1.15;
    font-size: 4rem;
}

.title,
.description {
    text-align: center;
}

.description {
    line-height: 1.5;
    font-size: 1.5rem;
}

.code {
    background: #fafafa;
    border-radius: 5px;
    padding: 0.75rem;
    font-size: 1.1rem;
    font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
}

.grid {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 800px;
    margin-top: 3rem;
}

.card {
    margin: 1rem;
    padding: 1.5rem;
    text-align: left;
    color: inherit;
    text-decoration: none;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    transition: color 0.15s ease, border-color 0.15s ease;
    width: 45%;
}

.card:hover,
.card:focus,
.card:active {
    color: #0070f3;
    border-color: #0070f3;
}

.card h2 {
    margin: 0 0 1rem 0;
    font-size: 1.5rem;
}

.card p {
    margin: 0;
    font-size: 1.25rem;
    line-height: 1.5;
}

.logo {
    height: 1em;
    margin-left: 0.5rem;
}

@media (max-width: 600px) {
    .grid {
        width: 100%;
        flex-direction: column;
    }
}

.new-form-vehicle {
    text-align: center;
    background: #333333 !important;
    padding: 6px;
    border-radius: 10px;
    margin: 10px;
    cursor: pointer;
}

.new-form-vehicle-title {
    margin: 0 0 10px 0;
    font-weight: 700;
    cursor: pointer;
    font-family: "Abarth", sans-serif !important;
    color: #ad0c33;
    font-size: 14px;
}

.model-name {
    font-family: "Abarth", sans-serif !important;
    color: #828282;
}

.new-form-step-content-list-item, .wrap-car-model {
    flex-basis: 25%;
    align-self: stretch;
    padding-bottom: 0px;
    margin-bottom: 0px;
    position: relative;
}

.title-form {
    font-family: "Abarth", sans-serif !important;
    font-size: 16px;
}

.radio-check::before {
    font-family: 'icomoon-form';
    content: "\e97d";
    color: #979797;
    font-style: normal;
    background: #333;
    font-weight: 100;
    margin-right: 10px;
    font-size: 20px;
    border-radius: 50%;
}

.new-form-img-title {
    font-family: "Abarth", sans-serif !important;
    font-size: 25px;
    font-weight: bold;
    margin-top: 7px;
    margin-bottom: 20px;
    color: #FFFFFF !important;
}


.dropdownSelect {
    border-bottom: solid 2px #828282;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    background: none;
    display: block;
    width: 100%;
    -webkit-appearance: none;
    outline: 0;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
    font-size: 16px;
    font-family: inherit;
    padding: 15px;
}

.p-abs {
    padding-bottom: 5px;
}

.form-check-input:checked {
    border-color: #ff031c !important;
    background-color: #ff031c !important;
}

.new-form-submit.submit {
    margin-top: 60px;
    text-align: right;
}

div.col-xs-12 > div > button {
    background-color: #ff031c;
    color: #fff;
    width: 180px;
    text-align: left;
    line-height: 1.6;
    font-family: "Abarth", sans-serif !important;
    font-size: 10px;
    letter-spacing: 0px;
    font-weight: bold;
    border-radius: 5px;
}

div.col-xs-12 > div > button svg {
    font-size: 16px;
    margin-left: 50px;
}

.new-form-link.new-form-link-change.model > svg {
    color: #ff031c;
}

.new-form-link {
    margin-top: 15px;
    color: #787878;
    display: inline-block;
    font-family: "Abarth", sans-serif !important;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
}

.new-form-link:hover {
    text-decoration: none !important;
    color: #000000;
}

.form-check-input:checked {
    border-color: #ff031c !important;
    background-color: #ff031c !important;
}

.new-select-form:after {
    right: 22px !important;
    top: 32px !important;
    top: 32px !important;
}

.new-form-step-content-list-flex {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    justify-content: left;
}

.img-fluid, .new-form-vehicle-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 30px;
    max-height: inherit;
    cursor: pointer;
    width: 100%;
    /*margin-top: 40px;*/
    margin-top: 0;
}

.section-header {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
    margin: 30px 0 0 0;
}

.section-header h1 {
    font-family: "Abarth", sans-serif !important;
    font-size: 36px;
    color: #333333;
    font-weight: 400;
}

.new-select-form {
    position: relative;
}

.new-form-container * input, .new-select-form select {
    padding-left: 5px !important;
    padding-top: 20px !important;
}

.input-container {
    border-bottom: 1px solid #cccccc;
    margin-bottom: 20px;
    position: relative;
}


label[for='gender'], label[for='city'], label[for='firstName'], label[for='lastName'], label[for='email'], label[for='telephone'] {
    position: absolute;

}

label[for='gender'], label[for='city'] {
    top: 15px;
}

label[for='firstName'], label[for='lastName'], label[for='email'], label[for='telephone'] {
    top: -5px !important;
}

.is-not-empty {
    padding: 0 !important;
    transform: translate(0, 0) !important;
    top: 4px !important;
    left: 5px;
    font-size: 11px !important;
}

.new-select-form:after {
    /*content: "\f078";*/
    /*font-family: "FontAwesome" !important;*/
    content: "\e902";
    font-family: 'icomoon-form' !important;
    color: #828282;
    position: absolute;
    right: 15px !important;
    top: 22px !important;
    z-index: 1;
    width: 14px;
    height: 20px;
    pointer-events: none;
    font-size: 10px !important;
}

/*.new-form-container .new-select-form > .dropdownSelect {*/
/*    background: none;*/
/*    height: 38px;*/
/*    width: 100%;*/
/*    -webkit-appearance: none;*/
/*    outline: 0;*/
/*    border-radius: 0;*/
/*    box-shadow: none;*/
/*    cursor: pointer;*/
/*    font-size: 16px;*/
/*    font-family: inherit;*/
/*    line-height: 42px;*/
/*    position: absolute;*/
/*    top: 10px;*/
/*    padding: 0px 30px 0px 10px;*/
/*}*/

.showroom-address, .showroom-phone {
    font-family: "Apex New Light", sans-serif !important;
    font-size: 13px;
}

.showroom-card {
    color: #333 !important;
    font-family: "Abarth", sans-serif !important;
}

.showroom-card .showroom-city {
    margin: 0 !important;
}

.showroom-city {
    padding-bottom: 0 !important;
}

.showroom-title {
    padding-bottom: 0 !important;
}

.showroom-card .showroom-title {
    margin-top: 25px;
}

.showroom-card a {
    color: #000 !important;
    max-width: 150px;
    white-space: nowrap;
    width: 100%;
    margin: 0;
}

.showroom-card .showroom-address {
    padding-bottom: 10px !important;
}

.showroom-card a {
    color: #fff !important;
}

/*showroom*/
.showroom-title {
    font-weight: 700 !important;
    min-height: 30px;
    font-size: 16px;
    font-family: "Abarth", sans-serif !important;
    color: #484848 !important;
}

.section-header {
    font-family: "Abarth", sans-serif !important;
    line-height: 1.2;
}

.showroom-address, .showroom-phone {
    font-size: 13px;
    text-transform: capitalize !important;

    line-height: 1.5;
}

.formAnagrafic, .formAnagrafic label, .formAnagrafic input, .formAnagrafic select {
    font-family: "Abarth", sans-serif !important;
    font-style: normal;
    font-size: 16px;
    color: #fff;
}

.formAnagrafic * input {
    padding-left: 5px !important;
}

.formAnagrafic * div {
    font-family: "Abarth", sans-serif !important;
    color: #999999;
}

.new-form-text-content a {
    text-decoration: underline;
    color: #fff !important;
}

.new-form-text-title {
    margin-bottom: 20px;
}


.rect-btn-red > svg {
    position: absolute;
    right: 10px;
}

.showroom-card {
    font-family: "Abarth", sans-serif !important;
}


.showroom-address, .showroom-phone {
    font-weight: 600 !important;
    font-family: "Abarth", serif;
}

.form-title {
    display: block;
}

.h1-form-type {
    font-weight: 700 !important;
    color: #ad0c32;
    font-size: 37px;
    font-family: "Abarth", sans-serif !important;
    display: block;
    margin: 0 auto;
    text-align: left;
}

.showroom-city {
    position: relative;
    padding: 0;
    margin: 10px 15px;
    font-family: "Abarth";
}

.showroom-city:after {
    content: "";
    width: 34px;
    height: 2px;
    background-color: #ff031c;
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: -5px;
}

/*thank you*/

.thx-title {
    color: #777777;
    font-size: 30px !important;
    text-transform: inherit;
    margin-bottom: 40px;
}

.answerTitle > h2 {
    text-align: center !important;
}

.copy-box h2 {
    text-align: center;
}

.new-icon-separator-container {
    height: auto;
    overflow: hidden;
    position: relative;
    padding-top: 10px;
}

.new-icon-separator-container .separator {
    width: 60% !important;
    height: 2px;
    background: #ff031c;
    /* margin: auto; */
    top: 35px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
}

.thanks-title {
    color: #777777;
    font-size: 30px !important;
    text-transform: inherit;
    font-family: "Abarth", sans-serif !important;
    font-weight: 700 !important;
    margin-top: 10px;
    text-align: center;
    margin-bottom: 0;
}

.new-icon-jeep_icon_quote {
    font-size: 47px;
    width: 93px;
    margin: auto;
    background: #fff;
    padding: 0px 20px;
    margin-top: 50px;
}

.copy-box-ico.ico1.new-icon-jeep_icon_quote:before {
    content: "\e923";
    color: #ad0c33;
    font-family: 'icomoon-form', sans-serif !important;
}

.copy-box-ico.ico1, .copy-box-ico.ico2, .copy-box-ico.ico3 {
    padding-bottom: 20px;
}

.new-icon-jeep_icon_calendar {
    font-size: 47px;
    width: 96px;
    background: #fff;
    margin: 50px auto auto;
    padding: 0px 20px;
}

.new-icon-abarth_icon_testdrive:before {
    content: "\e924";
    color: #ad0c33;
}

.copy-box-ico.ico2.new-icon-jeep_icon_calendar:before {
    content: "\e922";
    color: #ad0c33;
}

.new-icon-jeep_icon_testdrive {
    font-size: 47px;
    width: 96px;
    background: #fff;
    margin: 50px auto auto;
    padding: 0px 20px;
}

.containerCTATYP {
    margin-bottom: 20px;
    margin-top: 30px;
}

.rect-btn-red {
    background-color: #ff031c;
    color: #fff;
}

.rect-btn-grey {
    background: #888888 !important;
    color: #fff;
}

.rect-btn-red, .rect-btn-grey {
    font-size: 13px;
    font-family: "Abarth", sans-serif !important;
    min-width: 220px;
    height: 40px;
    border-radius: 5px;
    text-align: left;
    padding: 13px 35px;
    margin-top: 40px;
    text-transform: uppercase;
    cursor: pointer;
    max-width: 250px;
    position: relative;

}

.rect-btn-red:hover, .rect-btn-grey:hover {
    text-decoration: none !important;
    color: #FFFFFF !important;
}

.copy-box-ico {
    font-family: 'icomoon-form' !important;
    font-size: 47px;
    margin-top: 50px;
}

.copy-box-ico.ico3.new-icon-jeep_icon_testdrive:before {
    content: "\e91f";
    color: #ad0c33;
}

.new-icon-abarth_icon_brochure:before {
    content: "\e91d";
    color: #ad0c33;
}

.new-icon-abarth_icon_discovermodels:before {
    content: "\e91f";
    color: #ad0c33;
}

.new-icon-abarth_icon_discoverpromotions:before {
    content: "\e920";
    color: #ad0c33;
}

.answerTitle > h2 {
    padding-top: 50px;
    padding-bottom: 20px;
    color: #484848 !important;
}

.copy-box {
    text-align: center;
    /*padding-top: 40px;*/
    position: relative;
    font-family: "Abarth", sans-serif !important;
    font-size: 16px;
    color: #000000;
    height: auto;
}

.abarth-red-color {
    color: #ff031c !important;
}

/*abarth PRO STYLE*/
.new-form-submit.submit.btn-red-shadow > button {
    background-color: #9C1019;
    box-shadow: 0 2px 17px -5px #333333;
    color: #fff;
    outline: none;
    border-radius: 30px;
    padding: 12px 20px;
    font-weight: 600;
    font-family: "Abarth", sans-serif !important;
    position: relative;
    width: 100%;
    max-width: 200px !important;
    text-align: left;
}

.btn-red-shadow button:after {
    content: "\e948";
    font-family: 'icomoon-form' !important;
    position: absolute;
    right: 20px;
}

.btn-red-shadow button:hover:before {
    content: "\e949";
    font-family: 'icomoon-form' !important;
    position: absolute;
    right: 20px;
}

.new-form-submit.submit.btn-red-shadow > button:hover {
    background-color: #fff !important;
    color: #9C1019;
    box-shadow: 0 2px 20px -5px #333333;
    border-radius: 30px;
}

/*.rect-btn-red.abarthpro-btn.btn-red-shadow {*/
/*    background-color: #9C1019;*/
/*    box-shadow: 0 2px 17px -5px #333333;*/
/*    color: #fff;*/
/*    outline: none;*/
/*    border-radius: 30px;*/
/*    padding: 12px 20px;*/
/*    font-weight: 600;*/
/*    font-family: Roboto, serif;*/
/*}*/

.form-wizard > h1, .page-owner h1 {

    font-weight: bold !important;
    color: #ad0c32;
    font-size: 37px;
    font-family: "Abarth", sans-serif !important;
    display: block;
    margin: 0 auto;
    text-align: left;

}


/*wizard form*/
.bs-wizard {
    padding: 0;
    position: relative;
}

li:first-child {
    border-color: #AD0C32 !important;
    color: #AD0C32 !important;
}

/*.step-indicator li:nth-child(1) {*/
/* border: 1px solid #ff031c;*/
/*}*/
#step1 .step {
    border: 4px solid #ff031c;

    font-family: "Abarth", sans-serif !important;

    text-align: center;

    font-weight: 800;
    font-size: 18px;

}

.new-form-container .bs-wizard > .bs-wizard-step:first-child.active > .progress {
    width: 0%;
}

.new-form-container .bs-wizard > .bs-wizard-step:first-child > .progress {
    left: 50%;
    width: 50%;
}

.new-form-container .bs-wizard > .bs-wizard-step > .progress {
    position: relative;
    border-radius: 0px;
    height: 4px;
    box-shadow: none;
    margin: 26px 0;
}

.new-form-container .progress {
    overflow: hidden;
    height: 20px;
    margin-bottom: 20px;
    background: none;
    /* background-color: #D8D8D8; */
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
}

.progress {
    height: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    background-color: #f5f5f5;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
}

.new-form-container .bs-wizard > .bs-wizard-step.active > .progress > .progress-bar {
    width: 50%;
    transition: none;
}

.new-form-container .bs-wizard > .bs-wizard-step > .progress > .progress-bar {
    width: 0px;
    box-shadow: none;
    background: #AD0C32;
    transition: none;
}

.progress-bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    -webkit-box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
    -webkit-transition: width .6s ease;
    -o-transition: width .6s ease;
    transition: width .6s ease;
}

/*font */
.fs-small {
    font-size: 11px !important;
    color: #fff;
}

/*WIZARD FORM*/
.w-30 {
    width: 100%;
    max-width: 768px;
}

@media (max-width: 768px) {
    .w-30 {
        max-width: 100% !important;
    }
}

.step-indicator {
    border-collapse: separate;
    display: table;
    margin-left: 0;
    position: relative;
    table-layout: fixed;
    text-align: center;
    vertical-align: middle;
    padding-left: 0;
    padding-top: 20px;
    color: #ffffff;
}

.step-indicator li {
    display: table-cell;
    position: relative;
    float: none;
    padding: 0;
    width: 1%;
}


li.complete:after {
    background-color: #ccc;
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    width: 100%;
    top: 22px;
}

.step-indicator li:after {
    left: 55%;
}

.step-indicator li:last-child:after {
    display: none;
}

.step-indicator li.active .step {
    border-color: #AD0C32;
    color: #AD0C32;
}

.step-indicator li.active .caption {
    color: #AD0C32;
}

.step-indicator li.complete:after {
    background-color: #AD0C32;
    height: 3px;
}

.step-indicator li.complete .step {
    border-color: #AD0C32;
    color: #AD0C32;
    background: #ff031c !important;
}


.step-indicator li.complete .caption {
    color: #AD0C32;
}

.step-indicator .step {

    border-radius: 50%;
    border: 2px solid #979797;
    font-size: 20px;
    height: 40px;
    line-height: 40px;
    margin: 0 auto;
    position: relative;
    width: 40px;
    z-index: 1;
    background-color: #333333;
    color: #fff;
}

.step-indicator .step:hover {
    cursor: pointer;
}

.step-indicator .caption {
    color: #ccc;
    padding: 11px 16px;
}

.step-indicator .caption > span {
    color: #FFFFFF !important;
}

.complete > .step {
    background-color: #AD0C32;

}


.complete > .step > span {
    color: #fff;
}

.form-wizard * span {
    font-family: "Abarth", sans-serif !important;
    font-size: 12px;
    /*color: #0a0a0a;*/
    font-weight: bold;
    text-transform: uppercase;
}

.step > span {
    top: -3px;
    position: absolute;
    left: 0;
    right: 0;
    /*font-weight: normal;*/
    font-size: 18px;
    font-weight: 700;
    font-family: "Abarth", sans-serif !important;
}


/*.complete + li > .step , .complete + li:nth-child(2) .step {*/
/*    background-color: #ff031c !important;*/
/*}*/

.complete {
    font-weight: bold;
}

.complete + li > .step {
    border: 4px solid #ff031c;
    color: #ff031c;
}

.active {
    background: #333333 !important;
}

/*#step1 > div.step {*/
/*    border:4px solid #ff031c !important;*/
/*    background-color: #FFFFFF;*/
/*    color: #ff031c;*/
/*    font-weight: bold;*/

/*}*/
@media (max-width: 768px) {
    .new-form-step-content-list-flex {
        flex-direction: column !important;
    }

    .formAnagrafic {
        margin-top: 5rem;
    }

    .new-form-submit.submit {
        text-align: center;
    }

    .step-indicator {
        font-size: .7rem !important;
    }
}

@media (max-width: 999px) {
    .new-form-step-content-list-item, .wrap-car-model {
        flex-basis: 32% !important;
    }
}

.active-step .step {
    background: #333333 !important;
    border: 3px solid #ff031c;
    color: #FFFFFF !important;
    font-weight: bold !important;
}

.active-step .step .text-dark {
    color: #FFFFFF !important;
}

.ctive-step .step span {
    color: #000 !important;
}

.submit-abarth button, .submit-abarth a {
    width: 180px;
    text-align: left;
    line-height: 1.6;
    font-family: "Abarth", sans-serif;
    font-size: 10px;
    letter-spacing: 0;
    font-weight: bold;
    border-radius: 5px;
    background-color: #ff031c;
    color: #fff;
    text-transform: uppercase;
    position: relative;
    padding: 12px 20px;
    border: 0;
}

.submit-abarth button:hover {
    color: #fff !important;
}

.submit-abarth button:after, .submit-abarth a:after {
    content: "\e91b";
    font-family: 'icomoon-form' !important;
    font-size: 6px;
    position: absolute;
    right: 20px;
    top: 15px;
    /*padding: 12px;*/
}

input[type="checkbox"] {
    cursor: pointer !important;
}

.section-models, .section-header h2 {
    font-family: "Abarth", sans-serif !important;
    font-size: 22px !important;
    color: #737373;
    letter-spacing: 0;
    text-align: left;
    padding-right: 15px;
    /*padding-left: 15px;*/
    font-weight: 700;
    padding-left: 0;
    outline: 0 !important;
}

/*.section-models, .section-header h2:hover {*/
/*    color: #fff !important;*/
/*}*/

@media (max-width: 760px) {
    .form-wizard {
        width: 100% !important;
        display: block !important;
        margin: 0 auto;
    }
}

.abarth-form * input {
    color:#FFFFFF !important;
}