/* alfaromeo css  */
@import "https://use.fontawesome.com/releases/v5.15.3/css/all.css";
@import "https://fonts.cdnfonts.com/css/apex-new";
@import "https://use.fontawesome.com/releases/v5.15.3/css/all.css";


@font-face {
    font-family: "icomoon-form";
    src: url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.ttf?u9p37z");
    src: url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.eot?u9p37z#iefix") format("embedded-opentype"),
    url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.ttf?u9p37z") format("truetype"),
    url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.woff?u9p37z") format("woff"),
    url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.svg?u9p37z#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    font-family: 'Apex New', sans-serif !important;
}


::placeholder {
    font-family: 'Apex New', sans-serif !important;
}

.accordion {
    font-size: 17px;
    margin: 0;
}

.mui-textfield > label {
    padding-left: 10px;
    color: #909090;
    overflow-x: visible;
    font-size: 16px;
    font-family: 'Apex New Book', sans-serif !important;
    font-style: italic;
    color: #909090 !important;
    line-height: 24px;
    top: -3px;
}

.accordion__button {
    align-items: center;
    background: black;
    border: 0;
    color: white;
    cursor: pointer;
    display: flex;
    font-size: inherit;
    justify-content: space-between;
    margin: 0;
    padding: 25px 30px;
    width: 100%;
}

.accordion__button span {
    height: 30px;
    /*width: 60px;*/
    /* border-radius: 50%; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #000;
    font-size: 15px;
    white-space: nowrap;
}

.accordion__button:focus {
    outline: none;
}

.accordion__content {
    background: #fff;
    padding: 0 20px;
    opacity: 0;
    transition: all 0.5s;
    height: 0;
    margin: 0;
    margin-top: -10px;
    border: 1px solid #f1f1f1;
    border-top: 0;
    overflow: hidden;
}

.accordion__content.active {
    height: auto;
    opacity: 1;
    padding: 20px;
    display: block;
    background: #f1f1f1;
}

.section-header {
    color: #000;
    padding-bottom: 40px;
    padding-top: 40px;
    position: relative;
    text-align: center;
    font-family: "proxima_nova_rgbold", sans-serif !important;
}

.section-header.title-form h2 {
    font-family: "Apex New Medium", sans-serif !important;
    font-weight: 800;
    font-size: 16px;
    text-transform: inherit;
    text-align: left;
    margin: 0;
    color: #000000 !important;
}

/* .new-form-header-title {
  border-bottom: 4px solid #ffba00;
} */
.new-form-header-title {
    font-size: 14px;
    font-weight: 700;
    position: relative;
    top: -7px;
    border-bottom: 4px solid #bdbdbd;
    padding-bottom: 2px;
}

#essai-bg {
    background: url("https://www.jeep.fr/content/dam/jeep/crossmarket/hero/jeep-Ecommerce-1600X505-V4.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    max-height: 600px;
    min-height: 600px;
}

.subtitle {
    font-size: 35px !important;
    font-family: "proxima_nova_rgbold", sans-serif !important;
    font-weight: bold;
    line-height: 1.1;
    margin-bottom: 10px;
    text-align: center;
    color: #fff;
    padding-top: 5rem;
}

.new-form-vehicle-image {
    margin-bottom: 20px;
    max-height: inherit;
    cursor: pointer;
    width: 100%;
}

.new-form-vehicle {
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    margin: 10px;
}

.new-form-vehicle-title {
    text-align: left;
    margin-left: 20px;
}

.card {
    border: 0;
}

.color-yellow {
    margin-left: 10px;
    color: #ffba00;
    position: relative;
    font-size: 12px;
}

.new-form-vehicle-link,
.step-cars {
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    text-align: center;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    background-color: transparent;
    color: black;
    font-weight: 700;
    font-size: 16px;
    font-family: 'Apex New', sans-serif !important;
    text-decoration: none;
    cursor: pointer;
}

.new-form-vehicle-link,
.step-cars:hover {
    padding: 0 !important;
    text-decoration: none !important;
    color: #000;
}

.accordion__button svg {
    color: #ffba00 !important;
}

.accordion__content .active + .accordion__button {
    color: #ffba00 !important;
}

.new-icon-jeep_arrow_cta {
    margin-left: 10px;
    color: #ffba00;
    position: relative;
    font-size: 12px;
}

.new-form-step-content-list-flex {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    justify-content: left;
}

.new-form-step-content-list-item .wrap-car-model {
    flex-basis: 25%;
    align-self: stretch;
    padding-bottom: 40px;
    margin-bottom: 50px;
    position: relative;
}

.new-form-vehicle {
    padding: 0 10px;
    text-align: center;
}

.new-form-vehicle-title {
    margin: 0 0 10px 0;
    font-weight: 700;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Apex New', sans-serif !important;
}

.new-form-step-content-list-item .wrap-car-model:after {
    content: " ";
    border-bottom: 1px solid #e7e7e7;
    width: calc(100% - 20px);
    position: absolute;
    height: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.new-form-step-content-list-flex {
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    color: #222;
    box-sizing: border-box;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    justify-content: left;
}

.new-form-step-content-list-item,
.wrap-car-model {
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    color: #222;
    box-sizing: border-box;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    flex-basis: 25%;
    align-self: stretch;
    padding: 0 0 40px;
    margin: 0 0 50px;
    position: relative;
}

.new-form-vehicle {
    padding: 0 10px;
    text-align: center;
}

.wrap-car-model::after {
    width: calc(100% - 50px);
    content: " ";
    border-bottom: 1px solid #e7e7e7;
    position: absolute;
    height: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.new-form-step-content-list {
    margin-top: 40px;
}

.new-form-vehicle-title {
    margin: 0 0 10px 0;
    font-weight: 700;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Apex New', sans-serif !important;
}

.contFormAnagraphic {
    height: auto;
    overflow: hidden;
    padding-bottom: 50px;
    padding-top: 15px;
}

.dropdownSelect {
    border: none;
    border-bottom: solid 1px #828282;
    background: none;
    height: 55px;
    display: block;
    width: 100%;
    -webkit-appearance: none;
    outline: 0;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
    font-size: 16px;
    font-family: inherit;
    line-height: 37px;
    padding: 0px 25px 0px 10px;
}

.fm-alfaromeo * {
    font-family: "Apex New Book" !important;
    font-size: 16px !important;
    font-weight: 500;
}

option:hover {
    background-color: #ffba00 !important;
}

@media only screen and (max-width: 999px) {
    .wrap-car-model {
        flex-basis: 50%;
    }
}

@media only screen and (max-width: 767px) {
    .wrap-car-model {
        flex-basis: 100%;
    }
}

/*FORM  */
.section-anagraphic,
.title-form {
    font-size: 16px;
    font-family: 'Apex New Book', sans-serif !important;
}

.section-anagraphic,
.new-form-img-title {
    font-family: 'Apex New Bold', sans-serif !important;
    font-size: 25px;
    font-weight: bold;
    margin-top: 7px;
    margin-bottom: 20px;
    text-transform: uppercase;
}

dropdownSelect > option:first-child {
    height: 0 !important;
}

/*.p-abs {*/
/*    position: absolute;*/
/*    bottom: 30px;*/
/*}*/

/*option:checked, .p-abs {*/
/*    bottom: 50px;*/
/*}*/
.new-select-form {
    position: relative;
}

/*CUSTOM INPUT STYLE*/
/*.new-select-form > div {*/
/*    background: #FFFFFF;*/
/*}*/

.new-select-form:after, .dropdownSelect:after {
    /*content: "\f078";*/
    /*font-family: "FontAwesome" !important;*/
    content: "\e902";
    font-family: icomoon-form !important;
    color: #828282;
    position: absolute;
    right: 20px !important;
    top: 22px;
    z-index: 1;
    width: 14px;
    height: 20px;
    pointer-events: none;
    font-size: 12px !important;
}

.new-form-link,
.new-form-link-change,
.model {
    margin-top: 15px;
    color: black;
    display: inline-block;
    font-family: 'Apex New', sans-serif !important;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
}

.new-form-link {
    display: block;
    border: 1px solid rgb(156, 16, 25);
    padding: 15px;
    width: 100%;
    max-width: 250px;
    font-family: "Apex New Medium", sans-serif !important;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    position: relative;
}

.new-form-link:before {
    content: "\e965";
    transform: rotate(180deg);
    display: block;
    font-family: 'icomoon-form' !important;
    position: absolute;
    left: 10px;
    color: rgb(156, 16, 25);
}

.new-icon-jeep_arrow_changemodel {
    font-size: 13px;
    margin-right: 4px;
    color: #ffba01;
}

/*icons */
.copy-box-ico {
    font-family: 'icomoon-form' !important;
    font-size: 47px;
    margin-top: 50px;
}

.copy-box-ico.ico3.new-icon-jeep_icon_testdrive:before {
    content: "\e91f";
    color: #8F0C25;
}

.new-icon-fiat_icon_brochure:before {
    content: "\e91d";
    color: #8F0C25;
}

.new-icon-fiat_icon_discovermodels:before {
    content: "\e91f";
    color: #8F0C25;
}

.new-icon-fiat_icon_discoverpromotions:before {
    content: "\e920";
    color: #8F0C25;
}

.new-icon-alfa-romeo_icon_quote:before {
    content: "\e96f";
    color: #8f0c25;
}

.new-icon-alfa-romeo_icon_calendar:before {
    content: "\e968";
    color: #8f0c25;
}

.new-icon-alfa-romeo_icon_testdrive:before {
    content: "\e970";
    color: #8f0c25;
}

/*.formAnagrafic * div {*/
/*    font-family: 'Apex New', sans-serif !important;*/
/*}*/

.anagraphic-btn {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
    font: inherit;
    overflow: visible;
    line-height: normal;
    -webkit-appearance: button;
    cursor: pointer;
    font-weight: bold;
    display: inline-block;
    text-transform: uppercase;
    border: 0;
    background-color: #8F0C25;
    color: #fff;
    font-family: 'Apex New-Bold', sans-serif !important;
    font-size: 14px;
    width: 210px;
    border-radius: 0;
    text-align: center;
    padding: 13px 15px;
    margin: 40px 0 0;
    outline: none;
}


.formAnagrafic,
.mui-form {
    font-size: 14px;
}

.mui-textfield > input {
    padding-left: 10px;
    padding-right: 10px;
    height: 37px !important;
    margin-bottom: 0 !important;
    font-family: "Apex New Medium", sans-serif !important;
    font-size: 16px !important;
}


.mui-textfield > input:focus + label,
.mui-textfield > select:focus + label,
.mui-textfield > input[value]:not([value=""]) + label {
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transform: translate(0, 0);
}

.mui-textfield > input:focus {
    border-color: #828282;
    border-width: 1px;
}

.mui-textfield--float-label > label, label {
    -webkit-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    -o-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
    font-family: 'Apex New Book', sans-serif !important;
    font-style: italic;
    font-size: 16px !important;
    color: #909090 !important;
    padding-left: 10px;
}

.mui-radio .mui-radio-inline .field label {
    display: inline-block;
    height: 20px;
    position: relative;
    padding: 0 30px;
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;
    white-space: nowrap;
    width: 140px;
}

.mui-radio label {
    padding: 0 15px !important;
}

.form-check-input {
    border: 1px solid #828282 !important;
    box-shadow: 0 0 0 1px #828282;
    appearance: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: #fff;
    transition: all ease-in 0.2s;
    cursor: pointer;
}

.form-check-input:checked {
    border-color: #ffba00 !important;
    background-color: #ffba00 !important;
}

.form-check-input:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: #828282;
}

.form-check,
.form-check-inline,
.form-check-label {
    margin-left: 10px;
}

.section-anagraphic,
.new-form-text {
    margin: 40px 0 10px 0;
    line-height: 1.42857;
}

.new-form-text a {
    color: black;
    text-decoration: underline;
    font-size: 14px;
}

.jeep-btn button {
    background-color: #ffba00;
    color: black;
    font-family: 'Apex New', sans-serif !important;
    width: 144px;
    cursor: pointer;
    padding: 12px 20px;
    border-radius: 2px;
    font-weight: bold;
    font-size: 15px;
    display: inline-block;
    margin-right: 10px;
    text-transform: uppercase;
    border: 0;
    -webkit-appearance: button;
    white-space: pre-wrap;
}

.jeep-btn button svg {
    margin-left: 10px;
}

.accordion > .accordion__button > .accordion__content > .active {
    display: none;
}

.new-form-linktext {
    margin-left: 10px;
}

.new-form-link,
.new-form-link-change,
.model,
.new-form-link,
.new-form-link-change,
.model:hover {
    text-decoration: none !important;
    color: black;
}

.bb-yellow {
    border-bottom: 4px solid #ffba00;
}

.new-form-header-number {
    display: inline-block;
    height: 35px !important;
    width: 35px !important;
    background: #bdbdbd !important;
    font-size: 25px !important;
    font-weight: 700;
    color: #636363 !important;
    border-radius: 50% !important;
    padding-left: -3.5px !important;
    padding-top: 2px !important;
    margin-right: 15px !important;
    position: relative !important;
}


.rect-btn-yellow {
    background-color: #ffba00;
    color: black;
    text-decoration: none !important;
    padding: 8px 10px;
    margin-right: 5px;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 2px;
    font-weight: bold;
    font-size: 14px;
    display: inline-block;
    text-transform: uppercase;
    border: 0;
    white-space: nowrap;
}

/* .rect-btn-yellow::after {
    content: "\f054";
    font-family:"FontAwesome" ;
    margin-right: 20px;
    
} */
.showroom-card {
    max-width: 100%;
    min-width: 400px;
    position: relative;
    font-family: 'Apex New', sans-serif !important;
}

.rect-btn-yellow svg {
    margin-left: 20px;
}

.rect-btn-yellow:hover {
    color: #000 !important;
}

.showroom-title {
    font-weight: 800;
    min-height: 30px;
    font-size: 16px;
    text-transform: uppercase;
    padding-top: 15px;
}

.showroom-address, .showroom-phone {
    font-family: "Apex New Book", sans-serif !important;
    font-size: 13px;
    text-transform: capitalize !important;
}

.showroom-card {
    color: #333 !important;
    font-family: Roboto, sans-serif !important;
}

.showroom-card .showroom-city {
    margin: 0 !important;
}

.showroom-card .showroom-title {
    margin-top: 25px;
}

.rect-btn-red {
    color: #FFFFFF !important;
    max-width: 150px;
    white-space: nowrap;
    width: 100%;
    margin: 0;
}


.showroom-card .showroom-address {
    padding-bottom: 10px !important;
}

.showroom-card a {
    margin-top: 20px;
}

/*.showroom-card a {*/
/*    color: #000;*/
/*    border: 1px solid #8F0C25;*/
/*    max-width: 150px;*/
/*    border-radius: 5px;*/
/*    padding: 15px 20px;*/
/*    position: relative;*/

/*}*/

.showroom-card a svg {
    position: absolute;
    right: 10px;
}

.showroom-city {
    position: relative;
    padding: 0 !important;
    margin: 0 15px;
}

.showroom-city::after {
    content: "";
    width: 34px;
    height: 2px;
    background-color: #8F0C25;
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: -3px;

}


/*thank you*/
.section-header h2 {
    color: #777777;
    font-size: 30px;
    text-transform: inherit;
    margin-bottom: 40px;
    text-align: center;
}

.new-icon-separator-container {
    height: auto;
    overflow: hidden;
    position: relative;
    padding-top: 10px;
}

.new-icon-separator-container .separator {
    width: 67%;
    height: 2px;
    background: #8F0C25;
    /* margin: auto; */
    top: 35px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
}

.new-icon-jeep_icon_quote {
    font-size: 47px;
    width: 93px;
    margin: auto;
    background: #fff;
    padding: 0px 20px;
    margin-top: 50px;
}

.copy-box-ico.ico1.new-icon-jeep_icon_quote:before {
    content: "\e923";
    color: #8F0C25;
}

.new-icon-alfa-romeo_icon_discoverdealers:before {
    content: "\e96a";
    color: #8f0c25;
}

.new-icon-jeep_icon_calendar {
    font-size: 47px;
    width: 96px;
    background: #fff;
    margin: 50px auto auto;
    padding: 0px 20px;
}

.copy-box-ico.ico2.new-icon-jeep_icon_calendar:before {
    content: "\e922";
    color: #8F0C25;
}

.new-icon-alfa-romeo_icon_brochure:before {
    content: "\e967";
    color: #8f0c25;
}

.new-icon-alfa-romeo_icon_discovermodels:before {
    content: "\e96b";
    color: #8f0c25;
}

.new-icon-alfa-romeo_icon_discoverpromotions:before {
    content: "\e96c";
    color: #8f0c25;
}

.new-icon-jeep_icon_testdrive {
    font-size: 47px;
    width: 96px;
    background: #fff;
    margin: 50px auto auto;
    padding: 0px 20px;
}

.containerCTATYP {
    margin-bottom: 20px;
    margin-top: 30px;
}

.containerCTATYP a {
    background-color: #8F0C25;
    color: #fff;
    font-size: 13px;
    font-family: "Open Sans", sans-serif !important;
    width: 200px;
    height: 40px;
    border-radius: 5px;
    text-align: left;
    padding: 13px 15px;
    margin-top: 40px;
}

.copy-box-ico.ico3.new-icon-jeep_icon_testdrive:before {
    content: "\e91f";
    color: #8F0C25;
}

.answerTitle > h2 {
    padding-top: 50px;
    padding-bottom: 20px;
    color: #484848 !important;
}

.copy-box {
    text-align: center;
    padding-top: 40px;
    position: relative;

    font-family: "Apex Book ", sans-serif !important;
    line-height: 22px;

    font-size: 16px;
    color: #000000;
    height: auto;

}

.rect-btn-red {
    background-color: #8F0C25;
    color: #fff;
}

.rect-btn-red-sec {
    background-color: #FFFFFF !important;
    color: #000000 !important;
    border: 1px solid #8F0C25;
}

.rect-btn-red, .rect-btn-red-sec {

    font-family: 'Apex New Bold', sans-serif !important;
    font-size: 14px;
    width: 210px;
    /* height: 40px; */
    border-radius: 0;
    text-align: center;
    padding: 13px 15px;
    margin-top: 40px;
    outline: none;
    text-transform: uppercase;
    cursor: pointer;
}

.rect-btn-red:hover, .rect-btn-red-sec:hover {
    text-decoration: none !important;
    color: inherit !important;
}

.fiat-red-color {
    color: #8F0C25 !important;
}

.radio-checked-red:checked {
    border-color: #8F0C25 !important;
    background-color: #8F0C25 !important;
}

/*WIZARD FORM*/
.step-indicator {
    border-collapse: separate;
    display: table;
    margin-left: 0px;
    position: relative;
    table-layout: fixed;
    text-align: center;
    vertical-align: middle;
    padding-left: 0;
    padding-top: 20px;
}

.step-indicator li {
    display: table-cell;
    position: relative;
    float: none;
    padding: 0;
    width: 1%;
}


li.complete:after {
    background-color: #ccc;
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    width: 100%;
    top: 32px;
}

.step-indicator li:after {
    left: 50%;
}

.step-indicator li:last-child:after {
    display: none;
}

.step-indicator li.active .step {
    border-color: #AD0C32;
    color: #AD0C32;
}

.step-indicator li.active .caption {
    color: #AD0C32;
}

.step-indicator li.complete:after {
    background-color: #AD0C32;
    height: 3px;
}

.step-indicator li.complete .step {
    border-color: #AD0C32;
    color: #AD0C32;
}

.step-indicator li.complete .caption {
    color: #AD0C32;
}

.step-indicator .step {

    border-radius: 50%;
    border: 3px solid #D8D8D8;
    color: #ccc;
    font-size: 20px;
    height: 64px;
    line-height: 64px;
    margin: 0 auto;
    position: relative;
    width: 64px;
    z-index: 1;
    background-color: #D8D8D8;
    color: #fff;
}

.step-indicator .step:hover {
    cursor: pointer;
}

.step-indicator .caption {
    color: #ccc;
    padding: 11px 16px;
}


.complete > .step {
    background-color: #AD0C32;

}


.complete > .step > span {
    color: #fff;
}

.form-wizard * span {
    font-family: 'Apex New Bold', sans-serif !important;
    font-size: 12px;
}

/*.complete + li > .step , .complete + li:nth-child(2) .step {*/
/*    background-color: #8F0C25 !important;*/
/*}*/

.complete {
    font-weight: bold;
}

.complete + li > .step {
    border: 3px solid #8F0C25;
    color: #8F0C25;

}

/*#step1 > div.step {*/
/*    border:3px solid #8F0C25 !important;*/
/*    background-color: #FFFFFF;*/
/*    color: #8F0C25;*/
/*    font-weight: bold;*/

/*}*/


@media (max-width: 768px) {
    .new-form-step-content-list-flex {
        flex-direction: column !important;
    }

    .formAnagrafic {
        margin-top: 5rem;
    }

    .new-form-submit.submit {
        text-align: center;
    }

    .step-indicator {
        font-size: .7rem !important;
    }
}

/*form type tabs*/
.active-tab {
    background: #8F0C25 !important;
    color: #fff !important;
    text-decoration: none !important;


}

.btn-tabs {
    background: none;
    color: #000000;
    text-transform: uppercase;
    font-family: "Apex New Medium", Helvetica, Arial, sans-serif;
    font-weight: 400;
    margin: 2rem 0;
    height: 45px;
    padding: 15px !important;
    border: 0;
    width: 100%;
    max-width: 130px;
    text-align: center;
}

.btn-tabs:hover {

    color: black;
    text-decoration: none;
}

.btn-tabs:after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #8F0C25;
    transition: width .3s;
}

.btn-tabs:hover::after {
    width: 100%;
}

.submit-alfaromeo button {
    background-color: rgb(143, 12, 37);
    color: rgb(255, 255, 255);
    font-family: "Apex New Bold", sans-serif !important;
    border-radius: 0;
    padding: 12px 20px;
    position: relative;
    width: 100%;
    max-width: 200px;
    min-width: 200px;
}

.submit-alfaromeo button:hover {
    color: #fff !important;
}

.submit-alfaromeo button:before {
    content: "\e965";
    font-family: 'icomoon-form' !important;
    position: absolute;
    right: 10px;
}


@media (max-width: 768px) {
    .form-wizard {
        width: 100% !important;
        display: block !important;
        margin: 0 auto;
    }

    .flex-direction-column {
        flex-direction: row !important;
    }

    .new-icon-separator-container {
        flex-direction: column !important;
    }

}

.input__styles {
    margin-bottom: 17px;
    background: #fff;
    color: #000000;
    font-family: 'Apex New Medium', sans-serif;
    font-style: italic;
    font-size: 16px;
    border-bottom: 1px solid #ccc !important;
    border: 0;
    width: 100%;
    padding: 20px 10px;
    position: relative;
}

.input__styles:focus {
    outline: none;
    border-bottom: 1px solid #828282 !important;
    transition: border ease-in-out 2ms;
}
.input__styles option {
    background: transparent;
    color: #000000;
}

.animate-label {
    position: absolute;
    left: 0;
    right: 0;
    padding: 30px 0;
}

.radio-btn {
    color: #333333;
}
.radio-label {
    font-family: 'Apex New Medium', sans-serif !important;
    color: #000000 !important;
    font-weight: bold;
    margin: 0 15px;
}

.all-in-dark-gray * {
    color: #333333;
}

.radio-btn:before {
    content: "";
    background: #f4f4f4;
    border-radius: 100%;
    border: 1px solid #b4b4b4;
    display: inline-block;
    width: 1.4em;
    height: 1.4em;
    position: relative;
    top: -0.2em;
    margin-right: 1em;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    transition: all 250ms ease;
}
.radio-btn:checked:before {
    background-color: #8F0C25;
    box-shadow: inset 0 0 0 4px #f4f4f4;
}

.flex-radio-btn {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.error {
    color:red;
    position: absolute;
    right: 0;
    top: 0;
    padding: 15px;
}

.correct {
    color:green;
    border-bottom:green;
}

.all-in-black * {
    color: #333333 !important;
}

.new-form-linktext {
    cursor: pointer !important;
}

.align-card-img {
    text-align: left;
}

