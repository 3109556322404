/* fiatpro css  */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "https://use.fontawesome.com/releases/v5.15.3/css/all.css";

@font-face {
    font-family: "icomoon-form";
    src: url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.ttf?u9p37z");
    src: url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.eot?u9p37z#iefix") format("embedded-opentype"),
        url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.ttf?u9p37z") format("truetype"),
        url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.woff?u9p37z") format("woff"),
        url("https://staticpromo.fiat.com/form-rationalization/fonts/new-form-icomoon2.svg?u9p37z#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
}


*,
*::before,
*::after {
    box-sizing: border-box;
}

.accordion {
    font-size: 17px;
    margin: 0;
}

.accordion__button {
    align-items: center;
    background: black;
    border: 0;
    color: white;
    cursor: pointer;
    display: flex;
    font-size: inherit;
    justify-content: space-between;
    margin: 0;
    padding: 25px 30px;
    width: 100%;
}

.accordion__button span {
    height: 30px;
    /*width: 60px;*/
    /* border-radius: 50%; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #000;
    font-size: 15px;
    white-space: nowrap;
}

.accordion__button:focus {
    outline: none;
}

.accordion__content {
    background: #fff;
    padding: 0 20px;
    opacity: 0;
    transition: all 0.5s;
    height: 0;
    margin: 0;
    margin-top: -10px;
    border: 1px solid #f1f1f1;
    border-top: 0;
    overflow: hidden;
}

.accordion__content.active {
    height: auto;
    opacity: 1;
    padding: 20px;
    display: block;
    background: #fff;
}

.section-header.form-models-list h2 {
    font-family: "Roboto", sans-serif !important;
    font-size: 18px;
    color: #000;
    letter-spacing: 0;
    text-align: center;
    padding-right: 15px;
    padding-left: 15px;
    font-weight: 700;
    margin: 30px 0;
}

/* .new-form-header-title {
  border-bottom: 4px solid #ffba00;
} */
.new-form-header-title {
    font-size: 14px;
    font-weight: 700;
    position: relative;
    top: -7px;
    border-bottom: 4px solid #bdbdbd;
    padding-bottom: 2px;
}

#essai-bg {
    background: url("https://www.jeep.fr/content/dam/jeep/crossmarket/hero/jeep-Ecommerce-1600X505-V4.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    max-height: 600px;
    min-height: 600px;
}

.subtitle {
    font-size: 35px !important;
    font-family: "proxima_nova_rgbold", sans-serif !important;
    font-weight: bold;
    line-height: 1.1;
    margin-bottom: 10px;
    text-align: center;
    color: #fff;
    padding-top: 5rem;
}

.new-form-vehicle {
    text-align: center;
    background: transparent !important;
    margin: 10px;
    cursor: pointer;
}

[class^="new-icon-"],
[class*=" new-icon-"] {
    font-family: 'icomoon-form' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.new-icon-fiat-professional_icon_quote:before {
    content: "\e953";
}

.new-icon-fiat-professional_icon_calendar:before {
    content: "\e94c";
}

.new-icon-fiat-professional_icon_testdrive:before {
    content: "\e954";
}

.new-icon-fiat-professional_icon_discoverdealer:before {
    content: "\e94e";
}

.new-icon-fiat-professional_icon_brochure:before {
    content: "\e94b";
}

.new-icon-fiat-professional_icon_discovermodels:before {
    content: "\e94f";
}

.new-icon-fiat-professional_icon_discoverpromotions:before {
    content: "\e950";
}

.rect-btn-red-sec,
.rect-btn-red {
    font-size: 14px !important;
    font-family: "Roboto", sans-serif !important;
    max-width: 250px !important;
    height: 40px !important;
    border-radius: 30px !important;
    text-align: left !important;
    padding: 11px 15px !important;
    margin-top: 40px;
    outline: none;
    box-shadow: 0 2px 25px -5px #333333;
    font-weight: 600 !important;
}

.rect-btn-red {
    background-color: #9C1019;
    color: #fff;
}

.rect-btn-red-sec {
    background-color: #fff !important;
    color: #333333 !important;
}

.rect-btn-red-sec:hover {
    background-color: #333333 !important;
    color: #FFFFFF !important;
    text-decoration: none !important;
}

.rect-btn-red:hover {
    background: #FFFFFF;
    color: #AD0C33;
    text-decoration: none !important;
}

.new-form-vehicle:hover {
    background: white;
    border: none;
}

.new-form-vehicle-image {
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    color: #222;
    text-align: center;
    box-sizing: border-box;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline !important;
    border: 0 none !important;
    margin: 0 0 30px !important;
    max-height: 130px !important;
    cursor: pointer;
}

.card {
    border: 0;
}

.color-yellow {
    margin-left: 10px;
    color: #ffba00;
    position: relative;
    font-size: 12px;
}

.new-form-vehicle-link,
.step-cars {
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    text-align: center;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    background-color: transparent;
    color: black;
    font-weight: 700;
    font-size: 16px;
    font-family: Roboto, serif;
    text-decoration: none;
    cursor: pointer;
}

.new-form-vehicle-link,
.step-cars:hover {
    padding: 0 !important;
    text-decoration: none !important;
    color: #000 !important;
}

.accordion__button svg {
    color: #ffba00 !important;
}

.accordion__content .active+.accordion__button {
    color: #ffba00 !important;
}

.new-icon-jeep_arrow_cta {
    margin-left: 10px;
    color: #ffba00;
    position: relative;
    font-size: 12px;
}

.new-form-step-content-list-flex {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    justify-content: left;
}

.new-form-step-content-list-item .wrap-car-model {
    flex-basis: 25%;
    align-self: stretch;
    padding-bottom: 40px;
    margin-bottom: 50px;
    position: relative;
}

.new-form-vehicle {
    padding: 0 10px;
    text-align: center;
}

.new-form-vehicle-title {
    /*margin: 0 0 10px 0;*/
    /*font-weight: 700;*/
    /*cursor: pointer;*/
    /*font-size: 16px;*/
    /*font-family: Roboto;*/
    position: absolute;
    top: 35px;
    left: 0;
    right: 0;
    color: #fff;
    font-family: 'Roboto', sans-serif !important;
    margin: 0 0 10px 0;
}

.new-form-step-content-list-item .wrap-car-model:after {
    content: " ";
    border-bottom: 1px solid #e7e7e7;
    width: calc(100% - 20px);
    position: absolute;
    height: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.new-form-step-content-list-flex {
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    color: #222;
    box-sizing: border-box;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    justify-content: left;
}

.new-form-step-content-list-item,
.wrap-car-model {
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    color: #222;
    box-sizing: border-box;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    flex-basis: 25%;
    align-self: stretch;
    padding: 0 0 40px;
    margin: 0 0 50px;
    position: relative;
}

.new-form-vehicle {
    padding: 0 10px;
    text-align: center;
}

.wrap-car-model::after {
    width: calc(100% - 50px);
    content: " ";
    border-bottom: 1px solid #e7e7e7;
    position: absolute;
    height: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.new-form-step-content-list {
    margin-top: 40px;
}

.new-form-vehicle-title {
    margin: 0 0 10px 0;
    font-weight: 800;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Roboto', sans-serif !important;
}

.contFormAnagraphic {
    height: auto;
    overflow: hidden;
    padding-bottom: 50px;
    padding-top: 15px;
}

.dropdownSelect {
    border: none;
    border-bottom: solid 1px #828282;
    background: none;
    height: 37px;
    display: block;
    width: 100%;
    -webkit-appearance: none;
    outline: 0;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
    font-size: 16px;
    font-family: inherit;
    line-height: 37px;
    padding: 0px 25px 0px 10px;
}

option:hover {
    background-color: #ffba00 !important;
}

@media only screen and (max-width: 999px) {
    .wrap-car-model {
        flex-basis: 50%;
    }
}

@media only screen and (max-width: 767px) {
    .wrap-car-model {
        flex-basis: 100%;
    }
}

/*FORM  */
.section-anagraphic,
.title-form {
    font-size: 16px;
    font-family: 'Roboto', sans-serif !important;
}

.section-anagraphic,
.new-form-img-title {
    font-family: 'Roboto', sans-serif !important;
    font-size: 25px;
    font-weight: bold;
    margin-top: 7px;
    margin-bottom: 20px;
    color: #000;
}

dropdownSelect>option:first-child {
    height: 0 !important;
}

/*.p-abs {*/
/*    position: absolute;*/
/*    bottom: 30px;*/
/*}*/

/*option:checked, .p-abs {*/
/*    bottom: 50px;*/
/*}*/
.new-select-form {
    position: relative;
}

.new-select-form:after {
    content: "\f078";
    font-family: "FontAwesome" !important;
    /*content: "\e902";*/
    /*font-family: icomoon-form;*/
    color: #828282;
    position: absolute;
    right: 7px;
    top: 22px;
    z-index: 1;
    width: 14px;
    height: 20px;
    pointer-events: none;
    font-size: 9px;
}

.new-form-link,
.new-form-link-change,
.model {
    margin-top: 15px;
    color: black;
    display: inline-block;
    font-family: Roboto, serif;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
}

.new-icon-jeep_arrow_changemodel {
    font-size: 13px;
    margin-right: 4px;
    color: #ffba01;
}

.formAnagrafic,
.mui-form {
    font-size: 14px;
}

.mui-textfield>input {
    padding-left: 10px;
    padding-right: 10px;
    height: 37px !important;
    margin-bottom: 0 !important;
}

.mui-textfield>input:focus+label,
.mui-textfield>select:focus+label,
.mui-textfield>input[value]:not([value=""])+label {
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transform: translate(0, 0);
}

.mui-textfield>input:focus {
    border-color: #828282;
    border-width: 1px;
}

.mui-textfield--float-label>label {
    -webkit-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    -o-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}

.mui-radio .mui-radio-inline .field label {
    display: inline-block;
    height: 20px;
    position: relative;
    padding: 0 30px;
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;
    white-space: nowrap;
    width: 140px;
}

.mui-radio label {
    padding: 0 15px !important;
}

.form-check-input {
    border: 1px solid #828282 !important;
    box-shadow: 0 0 0 1px #828282;
    appearance: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: #fff;
    transition: all ease-in 0.2s;
    cursor: pointer;
}

.form-check-input:checked {
    border-color: #ffba00 !important;
    background-color: #ffba00 !important;
}

.form-check-input:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: #828282;
}

.form-check,
.form-check-inline,
.form-check-label {
    margin-left: 10px;
}

.section-anagraphic,
.new-form-text {
    margin: 40px 0 10px 0;
    line-height: 1.42857;
}

.new-form-text a {
    color: black;
    text-decoration: underline;
    font-size: 14px;
}

.jeep-btn button {
    background-color: #ffba00;
    color: black;
    font-family: "Roboto", sans-serif;
    width: 144px;
    cursor: pointer;
    padding: 12px 20px;
    border-radius: 2px;
    font-weight: bold;
    font-size: 15px;
    display: inline-block;
    margin-right: 10px;
    text-transform: uppercase;
    border: 0;
    -webkit-appearance: button;
    white-space: pre-wrap;
}

.jeep-btn button svg {
    margin-left: 10px;
}

.accordion>.accordion__button>.accordion__content>.active {
    display: none;
}

.new-form-linktext {
    margin-left: 10px;
}

.new-form-link,
.new-form-link-change,
.model,
.new-form-link,
.new-form-link-change,
.model:hover {
    text-decoration: none !important;
    color: black;
}

.bb-yellow {
    border-bottom: 4px solid #ffba00;
}

.new-form-header-number {
    display: inline-block;
    height: 35px !important;
    width: 35px !important;
    background: #bdbdbd !important;
    font-size: 25px !important;
    font-weight: 700;
    color: #636363 !important;
    border-radius: 50% !important;
    padding-left: -3.5px !important;
    padding-top: 2px !important;
    margin-right: 15px !important;
    position: relative !important;
}


.rect-btn-yellow {
    background-color: #ffba00;
    color: black;
    text-decoration: none !important;
    padding: 8px 10px;
    margin-right: 5px;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 2px;
    font-weight: bold;
    font-size: 14px;
    display: inline-block;
    text-transform: uppercase;
    border: 0;
    white-space: nowrap;
}

/* .rect-btn-yellow::after {
    content: "\f054";
    font-family:"FontAwesome" ;
    margin-right: 20px;
    
} */
.showroom-card {
    max-width: 200px;
    min-width: 200px;
}

.rect-btn-yellow svg {
    margin-left: 20px;
}

.rect-btn-yellow:hover {
    color: #000 !important;
}

.showroom-title {
    font-weight: 800;
    min-height: 30px;
    font-size: 16px;
    text-transform: uppercase;
}


/*WIZARD FORM*/
.step-indicator {
    border-collapse: separate;
    display: table;
    margin-left: 0px;
    position: relative;
    table-layout: fixed;
    text-align: center;
    vertical-align: middle;
    padding-left: 0;
    padding-top: 20px;
}

.step-indicator li {
    display: table-cell;
    position: relative;
    float: none;
    padding: 0;
    width: 1%;
}


li.complete:after {
    background-color: #ccc;
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    width: 100%;
    top: 32px;
}

.step-indicator li:after {
    left: 50% !important;
}

.step-indicator li:last-child:after {
    display: none;
}

.step-indicator li.active .step {
    border-color: #AD0C32;
    color: #AD0C32;
}

.step-indicator li.active .caption {
    color: #AD0C32;
}

.step-indicator li.complete:after {
    background-color: #AD0C32;
    height: 3px;
}

.step-indicator li.complete .step {
    border-color: #AD0C32;
    color: #AD0C32;
}

.step-indicator li.complete .caption {
    color: #AD0C32;
}

.step-indicator .step {

    border-radius: 50%;
    border: 3px solid #D8D8D8;
    color: #ccc;
    font-size: 20px;
    height: 64px;
    line-height: 64px;
    margin: 0 auto;
    position: relative;
    width: 64px;
    z-index: 1;
    background-color: #D8D8D8;
    color: #fff;
}

.step-indicator .step:hover {
    cursor: pointer;
}

.step-indicator .caption {
    color: #ccc;
    padding: 11px 16px;
}


.complete>.step {
    background-color: #AD0C32;

}

#step1 {
    border: 4px solid #AD0C33;
}

.complete>.step>span {
    color: #fff;
}

.form-wizard * span {
    font-family: "Open Sans", sans-serif;
}

/*.complete + li > .step , .complete + li:nth-child(2) .step {*/
/*    background-color: #AD0C33 !important;*/
/*}*/

.complete {
    font-weight: bold;
}

.complete+li>.step {
    border: 3px solid #AD0C33;
    color: #AD0C33;

}

@media (max-width: 768px) {
    .new-form-step-content-list-flex {
        flex-direction: column !important;
    }
}

.fw-fiatpro {
    font-family: "Roboto", sans-serif !important;
}