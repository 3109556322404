/*form validation*/
.bb-red {
    border-bottom: 2px solid #FF0000 !important;
}

.border-red {
    border-color: #FF0000;
}

.border-green {
    border-color: #1EB100;
}

.bb-green {
    border-bottom: 2px solid #1EB100 !important;
}

.bb-green > input, .bb-red > input {
    border: 0 !important;
}

.alert {
    background: transparent !important;
    border: none !important;
}

.alert-danger, .alert-success {
    position: absolute;
    right: -15px;
    top: -10px;
    padding-right: 50px;
    font-size: 11px;
}

.alert-success {
    color: #1EB100 !important;
}

.alert-danger {
    color: #FF0000;
}

/*.alert {*/
/*    font-family: Roboto , sans-serif !important;*/
/*}*/