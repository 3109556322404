/* PRIVACY */

.boxMessage {
    color: #000;
    font-family: 'Roboto';
    font-size: 14px;
    line-height: 1.2em;
    margin-top: 3px;
    padding-bottom: 10px;
}

 .privacy-title {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: normal;
}

 .form-radios-group-infos-content p {
     margin-bottom: 1rem !important;
 }

 .form-radios-group-infos-content {
     width: 100%;
     max-height: 60px;
     height: 100px;
     overflow-y: scroll;
 }
 .privacyMask {
     /*background: linear-gradient(to bottom, rgba(255,255,255,0) 71%,rgba(255,255,255,0.14) 75%,rgba(255,255,255,1) 100%);*/
     width: 100%;
     height: 140px;
     position: absolute;
     pointer-events: none;
     bottom: 0;
 }